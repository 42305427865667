import React from 'react';
import ReactToPrint from "react-to-print";
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

const VoteDetails = props => {
    const { sessionData, voteMembers, billData, voteData } = props;
    const sessionHeader = sessionData ? sessionData.SessionYear + ' ' + sessionData.DisplayName : '';
    const memberYeas = voteMembers.filter(nObj => nObj.ResponseCode === 'Y');
    const memberNays = voteMembers.filter(nObj => nObj.ResponseCode === 'N');
    const memberAbstentions = voteMembers.filter(nObj => nObj.ResponseCode === 'A');
    const memberNoVote = voteMembers.filter(nObj => nObj.ResponseCode === 'X');
    const voteStatements = voteMembers.filter(member => member.VoteStatement);
    const voteActionDescription = voteData.ChamberCode === "S" ? " Senate: " + voteData.LegislationActionDescription : " House of Delegates: " + voteData.LegislationActionDescription
    // Abstentions appear as rule 36 on senate floor tally sheets
    let memberRule36 = [];
    if (voteData.ChamberCode === "S" && voteData.VoteType === "Floor") {
        memberRule36 = voteMembers.filter(nObj => nObj.ResponseCode === 'A');
    } else {
        memberRule36 = voteMembers.filter(nObj => nObj.ResponseCode === 'R');
    }

    let componentRef;
    let removeRefFromPrint;
    const displayTallySheet = props.voteFileUrl && !voteData.CommitteeID && voteData.ChamberCode !== "H";
    const displayCommitteeLink = voteData.CommitteeID;
    const linksGridColumns = displayTallySheet && displayCommitteeLink ? 'three' : (displayTallySheet || displayCommitteeLink) ? 'two' : 'one';
    const linksGridTemplateColumns = linksGridColumns === 'three' ? 'auto min-content min-content' : linksGridColumns === 'two' ? 'auto min-content' : 'min-content';
    const headerGridTemplateColumns = linksGridColumns === 'three' ? '7fr 3fr' : linksGridColumns === 'two' ? '7fr 2fr' : '7fr 1fr';
    const mobileLinksPosition = linksGridColumns === 'three' ? 'justify-center' : 'justify-left';

    return (
        <div ref={el => (componentRef = el)} id="vote-details" className="bill-theme public-details inner-grid">
            <div>
                <h2 style={{ marginBottom: '10px' }}>{sessionHeader}</h2>
            </div>
            <div className={props.isPublic ? 'details-header' : ''} style={props.isPublic && { gridTemplateColumns: headerGridTemplateColumns }}>
                {billData.length === 1 ?
                    <div style={{ marginBottom: "15px" }}>
                        <h3><span style={{ marginRight: '1em' }}>{billData[0].LegislationNumber}</span><span>{billData[0].Description}</span></h3>
                    </div>
                    :
                    <div>
                        <h3>Bills</h3>
                        <p>{billData.map((bill, index) =>
                            <React.Fragment key={index}>
                                {bill.LegislationNumber}{index !== billData.length - 1 && ","}&nbsp;
                            </React.Fragment>
                        )}</p>
                    </div>
                }
                {props.isPublic &&
                    <div className={`remove-from-print inner-grid ${linksGridColumns} list-links ${mobileLinksPosition}`} style={{ display: 'grid', justifyContent: 'end', gridTemplateColumns: linksGridTemplateColumns }}>
                        {displayTallySheet &&
                            <a href={props.voteFileUrl.FileURL}>Tally Sheet PDF</a>
                        }
                        {displayCommitteeLink &&
                            <Link to={`/session-details/${sessionData.SessionCode}/committee-information/${voteData.CommitteeNumber}/committee-details`}>{voteData.ParentCommitteeID ? 'Subcommittee' : 'Committee'}</Link>
                        }
                        <ReactToPrint
                            trigger={() => <a type="button" className="button print"> Print</a>}
                            content={() => {
                                let printedContent = componentRef.cloneNode(true);
                                //remove icons from printed document
                                const removedContent = printedContent.getElementsByClassName('remove-from-print');
                                for (const el of removedContent) {
                                    el.innerHTML = ''
                                }
                                //set header to block instead of grid so it can span the width of printed document rather than be cut off short
                                let header = printedContent.getElementsByClassName('details-header');
                                if (header && header[0]) {
                                    header = header[0];
                                    header.style.display = 'block';
                                    let billDescription = header.getElementsByTagName('div');
                                    if (billDescription && billDescription[0]) {
                                        billDescription[0].style['margin-bottom'] = '0px';
                                    }
                                }
                                return printedContent
                            }}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                }
            </div >
            <div>
                <p><strong>{moment(voteData.VoteDate).format("MM/DD/YYYY")} {voteActionDescription}</strong></p>
            </div>
            <div>
                <div className="details-section">
                    {voteData.CommitteeID &&
                        <p>{voteData.ParentCommitteeID ? "Subcommittee: " : "Committee: "} {voteData.ReferToCommitteeID !== undefined ? voteData.ReferToCommitteeName : voteData.CommitteeName}</p>
                    }
                    {voteData.ParentCommitteeID &&
                        <p>Committee: {voteData.ParentCommitteeName}</p>
                    }
                </div>
                <div className="details-section">
                    <h4>Votes</h4>
                    <p>
                        YEAS--
                        {memberYeas.map((item, index) => (
                            <span key={index}>{item.PatronDisplayName}{memberYeas.length === index + 1 ? '--' : ', '}</span>
                        ))}
                        {memberYeas.length}
                    </p>
                    <p>
                        NAYS--
                        {memberNays.map((item, index) => (
                            <span key={index}>{item.PatronDisplayName}{memberNays.length === index + 1 ? '--' : ', '}</span>
                        ))}
                        {memberNays.length}
                    </p>
                    <p>
                        {(voteData.VoteType === "Floor" && voteData.ChamberCode === "S") ? "RULE 36--" : "ABSTENTIONS--"}
                        {memberAbstentions.map((item, index) => (
                            <span key={index}>{item.PatronDisplayName}{memberAbstentions.length === index + 1 ? '--' : ', '}</span>
                        ))}
                        {memberAbstentions.length}
                    </p>
                    {voteData.VoteType === "Committee" && voteData.ChamberCode === "S" ? ""
                        : <p>
                            NOT VOTING--
                            {memberNoVote.map((item, index) => (
                                <span key={index}>{item.PatronDisplayName}{memberNoVote.length === index + 1 ? '--' : ', '}</span>
                            ))}
                            {memberNoVote.length}
                        </p>
                    }
                </div>
            </div>
            <div id="vote-statements">
                {voteStatements.map((statement, statementIndex) =>
                    <p key={statementIndex}>{statement.VoteStatement}</p>
                )}
            </div>
            {voteData.VoteComment &&
                <div id="vote-comments">
                    <p>{voteData.VoteComment}</p>
                </div>
            }
        </div>
    );
}

export default VoteDetails
