import React from "react";
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default'
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/table/plugin';
import '../../../../stylesheets/tinymce/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import renderHTML from "react-render-html";

class Misc extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newEntries: [],
            editing: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveChange = this.saveChange.bind(this);
    }

    handleChange(content) {
        //Update the local entry text without saving data to the parent
        let entries = [...this.state.newEntries];

        entries[0].EntryText = content;

        this.setState({
            newEntries: entries
        });
    }

    toggleEdit() {
        this.setState({
            editing: !this.state.editing,
        })
    }

    cancelEdit() {
        this.setState({
            editing: false,
            newEntries: this.props.formData.MinutesEntries
        })
    }

    saveChange() {
        this.props.handleSaveEntry(this.state.newEntries[0])
        this.toggleEdit();
    }

    componentDidMount() {
        this.setState({
            newEntries: this.props.formData ? this.props.formData.MinutesEntries : []
        })
    }

    componentDidUpdate(prevProps) {
        //If the entry has been saved or changed then swap out the entry with the latest
        if (prevProps.formData && this.props.formData && prevProps.formData.MinutesEntries[0].ModificationDate != this.props.formData.MinutesEntries[0].ModificationDate) {
            this.setState({
                newEntries: this.props.formData.MinutesEntries
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.newEntries.map((entry, i) => {
                    return (
                        <React.Fragment>
                            <div key={entry.MinutesEntryID}>
                                {this.state.editing
                                    ?
                                    <div className="grid-wrapper two-col-sidebar">
                                        <Editor
                                            value={entry.EntryText}
                                            init={{
                                                browser_spellcheck: true,
                                                plugins: 'link paste save lists preview searchreplace pagebreak code table',
                                                toolbar: 'undo redo | bold italic strikethrough | alignleft aligncenter alignright',
                                                skin: false,
                                                valid_children: '+body[style]',
                                                content_css: false,
                                                content_style: 'table { border-collapse: collapse; border: 1px solid #FFFFFF }' + 'td { padding: 12px 0px }',
                                            }}
                                            onEditorChange={content => this.handleChange(content)}
                                        />
                                        <div>
                                            <button onClick={() => this.saveChange()} type="button" className="icon save" style={{ marginRight: "10px" }}></button>
                                            <button onClick={() => this.cancelEdit()} type="button" className="icon delete"></button>
                                        </div>
                                    </div>
                                    :
                                    <div className="grid-wrapper two-col-sidebar">
                                        <p>{renderHTML(entry.EntryText)}</p>
                                        <button onClick={() => this.toggleEdit()} type="button" className="icon edit"></button>
                                    </div>
                                }

                            </div>
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        );
    }
}

export default Misc