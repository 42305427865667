import React from 'react';
import renderHTML from 'react-render-html';
import { Link, Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { billActionCreators } from '../../stores/lis-legislation-store';
import { authActionCreators } from '../../stores/lis-auth-store';
import { patronActionCreators } from '../../stores/lis-patron-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { cancelRequest } from '../../services/request.service';
import UpdatePatronageModal from '../../lis-admin/lis-update-patronage-modal';
import ReviewSection from '../../lis-admin/lis-version-management/components/lis-review';
import Mark from 'mark.js';
import queryString from 'query-string';
import moment from 'moment-timezone';
import getJsx from '../../services/json-to-jsx';
import getHtml from '../../services/json-to-html';
import { memberActionCreators } from '../../stores/lis-members-store';

const VERSION_AUTHOR = "LegislationText";
const PATRON_AUTHOR = "LegislationPatron";
const NEXT = "NEXT";
const PREVIOUS = "PREVIOUS";

const inlineStyles = [
    { class: "enactstm", value: 'font-family:times,TimesNewRoman,Times New Roman;font-weight:bold;text-align:justify;text-indent:15px;' },
    { class: "enactcls", value: 'font-family:times,TimesNewRoman,Times New Roman;font-weight:bold;text-align:justify;' },
    { class: "section", value: 'font-family:times,TimesNewRoman,Times New Roman;text-align:justify;margin-bottom:1px;text-indent:15px;' },
    { class: "ldtitle", value: 'font-family:times,TimesNewRoman,Times New Roman;font-style:italic;text-align:justify;text-indent:-15px;margin-left:15px;' },
    { class: "center", value: 'font-family:times,TimesNewRoman,Times New Roman;font-weight:bold;text-align:center;' }
]

function PatronDisplayInfo(patron) {
    var s = patron.PatronDisplayName.trim();
    if (patron.ByRequest) {
        s += " (By Request)";
    }
    return s;
}

class FullText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            isLoaded: false,
            isEditing: false,
            isSaving: false,
            billText: '',
            draftTextCopy: '',
            chamberOptions: [
                { label: "Senate", value: "S" },
                { label: "House", value: "H" }
            ],
            sponsorOptions: [],
            highlight: this.props.location.pathname.endsWith('/hilite'),
            amendmentSelections: {},
            memberOptions: [],
            houseMemberOptions: [],
            senateMemberOptions: [],
            committeeOptions: [],
            housePatronsList: [],
            senatePatronsList: [],
            patronList: [],
            originalPatronList: [],
            versionsList: [],
            chiefPatronsList: [],
            showPatronModal: false,
            showTooltip: false,
            isDirty: false,
            initialPass: false,
            patronTypes: [],
            newPatron: [{}],
            wasEmpty: false,
            replaced: false,
            markHelpIndex: null
        };

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.toggleEditContent = this.toggleEditContent.bind(this);
        this.createNewAmendment = this.createNewAmendment.bind(this);
        this.saveText = this.saveText.bind(this);
        this.handleBillDispositionChange = this.handleBillDispositionChange.bind(this);
        this.toggleHighlight = this.toggleHighlight.bind(this);
        this.highlightText = this.highlightText.bind(this);
        this.membersCallback = this.membersCallback.bind(this);
        this.committeesCallback = this.committeesCallback.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.handleIsPublicChange = this.handleIsPublicChange.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.getData = this.getData.bind(this);
        this.isLdIncluded = this.isLdIncluded.bind(this);
        this.getJsonHeadersRequestString = this.getJsonHeadersRequestString.bind(this);
        this.toggleReprint = this.toggleReprint.bind(this);
        this.downloadWordFile = this.downloadWordFile.bind(this);
        this.togglePatronModal = this.togglePatronModal.bind(this);
        this.updatePatrons = this.updatePatrons.bind(this);
        this.savePatrons = this.savePatrons.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.togglePatron = this.togglePatron.bind(this);
        this.handlePatronChange = this.handlePatronChange.bind(this);
        this.handleNewPatronChange = this.handleNewPatronChange.bind(this);
        this.addNewPatron = this.addNewPatron.bind(this);
        this.handleVersionDateChange = this.handleVersionDateChange.bind(this);
        this.applyPassageDates = this.applyPassageDates.bind(this);
        this.triggerDownload = this.triggerDownload.bind(this);
        this.passageCheck = this.passageCheck.bind(this);
        this.setWasEmpty = this.setWasEmpty.bind(this);
        this.setReplaced = this.setReplaced.bind(this);
        this.resetPatrons = this.resetPatrons.bind(this);
        this.navToKeywordHit = this.navToKeywordHit.bind(this);

        this.dispositionRef = React.createRef();
        this.sponsorRef = React.createRef();
        this.memberRef = React.createRef();
        this.committeeRef = React.createRef();
    }

    setWasEmpty(val, callback) {
        this.setState({ wasEmpty: val }, () => {
            if (callback) {
                callback();
            }
        })
    }

    setReplaced(val, callback) {
        this.setState({ replaced: val }, () => {
            if (callback) {
                callback();
            }
        })
    }

    handleEditorChange(value) {
        this.setState({
            draftTextCopy: value,
            initialPass: true,
            isDirty: this.state.billText.LegislationVersionID === 8 ? true : this.state.initialPass ? true : false
        });
    }

    toggleReprint() {
        let billText = { ...this.state.billText };
        billText.IsReprint = !billText.IsReprint;
        this.setState({ billText })
    }

    toggleEditContent(cb) {
        this.setState({ gettingEditData: !this.state.isEditing }, () => {
            let draftTextCopy, jsonPromise;
            let headerError = false;
            const billText = this.state.billText;
            if (!this.state.isEditing && ['Introduced', 'Engrossed', 'Enrolled', 'Chaptered', 'Reenrolled'].includes(billText.LegislationVersion)) {

                const jsonHeadersRequestString = this.getJsonHeadersRequestString(billText.LegislationTextID, billText.SessionID, this.state.selectedSession.SessionCode);

                jsonPromise = this.props.actions.getJsonHeaders(jsonHeadersRequestString)
                    .then(() => {
                        if (this.props.bills.jsonHeaders) {
                            let headers = getHtml(this.props.bills.jsonHeaders);

                            let newDraftText;
                            if (billText.LegislationVersion !== "Introduced") {
                                draftTextCopy = headers + billText.DraftText;

                                //Remove ldtitle from document if the header is present in the header and the header also contains the ldtitle
                                let doc = document.createElement('div');
                                doc.insertAdjacentHTML("afterbegin", draftTextCopy);
                                let header = doc.getElementsByTagName('div');

                                if (header[0] != undefined) {
                                    //Store the header somewhere while we find and iterate over paragraphs in the rest of the doc
                                    let headerContainer = header[0].innerHTML;
                                    let headerParagraphs = header[0].getElementsByTagName('p');

                                    for (let i = 0; i < headerParagraphs.length; i++) {
                                        if (['ldtitle', 'ld', 'patronLine', 'patctr'].includes(headerParagraphs[i].classList[0])) {
                                            //Temporarily remove header                              
                                            header[0].innerHTML = "";

                                            //We found the ldtitle or patronage in the header, so iterate over the paragraphs to find any duplicate ldtitles or patronages and eliminate them
                                            let docParagraphs = doc.getElementsByTagName('p');

                                            for (let j = 0; j < docParagraphs.length; j++) {
                                                if (['section', 'enactstm'].includes(docParagraphs[j].classList[0])) {
                                                    break; //reached the actal body of the text, don't remove any more
                                                } else if (['ldtitle', 'ld', 'patronLine', 'patctr', 'center'].includes(docParagraphs[j].classList[0])) {
                                                    doc.removeChild(docParagraphs[j])
                                                    j--
                                                }
                                            }
                                        }
                                    }
                                    //Reattach the header
                                    header[0].innerHTML = headerContainer;

                                    newDraftText = doc.innerHTML;
                                }
                            } else {
                                //if it's introduced, let's remove the existing headers if there are any before attaching the new headers
                                draftTextCopy = billText.DraftText;

                                let doc = document.createElement('div');
                                doc.insertAdjacentHTML("afterbegin", draftTextCopy);
                                let content = doc.getElementsByTagName('p');

                                //the below array defines where the actual draft text (i.e. not header information) begins, so that we know the point up to which we can remove elements (i.e. the point up to which we can assume the elements are headers)
                                //they need to be in this priority order since sometimes there can be more than one, and we need to know which one will be the actual beginning of the draft text
                                //e.g. hb1 in 2022 has the ldtitle class within the header itself as well as the enactstm class starting off the actual draft text, in which case the enactstm needs to be the point at which we stop removing elements, not the ldtitle element
                                const beginningOfTextParagraphClassesInPriorityOrder = ['enactstm', 'ldtitle', 'ld'];

                                //find the index of the element that marks the beginning of the draft text, and the point up to which we should assume everything is header information and remove all elements
                                let beginningOfTextParagraphIndex = -1;
                                let loopIdx = 0;
                                do {
                                    beginningOfTextParagraphIndex = [...content].findIndex(p => p.classList && p.classList[0] === beginningOfTextParagraphClassesInPriorityOrder[loopIdx]);
                                    loopIdx++;
                                } while (loopIdx < beginningOfTextParagraphClassesInPriorityOrder.length && beginningOfTextParagraphIndex === -1);

                                //if we didn't find any start per the logic above, the index will still be -1, and this loop will not run and hence no elements will be removed
                                //if we did find a starting index for the actual draft text, loop through the lines up to that point and remove the elements (i.e. header pieces)
                                for (let i = 0; i < beginningOfTextParagraphIndex; i++) {
                                    //remove existing header (index 0 below rather than the variable 'i' since we are removing indexes of variable 'content' as we loop) 
                                    doc.removeChild(content[0]);
                                }

                                //Sometimes we end up with stray ldtitles at the top of the text. Remove that.
                                if(content[0].className === "ldtitle") {
                                    doc.removeChild(content[0])
                                }

                                newDraftText = doc.innerHTML;
                            }

                            //update draftTextCopy and now attach the new headers for introduced versions
                            if (newDraftText != undefined) {
                                draftTextCopy = (billText.LegislationVersion === "Introduced" ? headers : "") + newDraftText
                            }
                        }
                    }).catch(e => {
                        console.log(e)
                        draftTextCopy = `<p class="message-error">${typeof e === 'object' && !Array.isArray ? JSON.parse(e).Name || "Unknown Error" : e}</p>`;
                        headerError = true;
                    })
            } else {
                draftTextCopy = billText.DraftText
            }

            Promise.all([jsonPromise])
                .finally(() => {
                    this.setState(state => ({
                        headerError,
                        gettingEditData: false,
                        isEditing: !state.isEditing,
                        draftTextCopy
                    }), () => {
                        if (cb) cb();
                    });
                })
        })
    }

    getJsonHeadersRequestString(legislationTextId, sessionID, sessionCode) {
        //note we pass in a continuedToSessionCode all the time, even if it's not a continued bill and the sessionID/continuedToSessionCode represent the same session - in this case, it still works as expected so it's fine to pass both in
        let jsonHeadersRequestString = "?legislationTextID=" + legislationTextId + "&sessionID=" + sessionID + "&continuedToSessionCode=" + sessionCode + "&onlyHeader=true";

        return jsonHeadersRequestString
    }

    createNewAmendment() {
        this.setState(state => ({
            billText: {
                ...state.billText,
                VersionDate: new Date(),
            }
        }), () => {
            this.saveText()
        });
    }

    saveText(cb) {
        if (!this.validateData()) {
            return;
        }
        this.setState({ isSaving: true });
        let billText = { ...this.state.billText };
        //Try to find the draft title in the text and set it
        let searchForLDTitleContainer = document.createElement('div');
        searchForLDTitleContainer.innerHTML = this.state.draftTextCopy;
        //The first element in the content with this class is the draft title
        let draftLDTitle = searchForLDTitleContainer.querySelector('.ldtitle');
        if (draftLDTitle && draftLDTitle.innerHTML !== '') {
            billText.DraftTitle = draftLDTitle.innerHTML;
            billText.EditTitle = draftLDTitle.innerHTML;
        }

        //Remove any tags TinyMCE may have added when pasting from Word (Borrow ldtitle container doc)
        searchForLDTitleContainer.childNodes.forEach(node => {
            if (node.nodeName !== "#text") {
                node.removeAttribute('data-mce-fragment');
                if (node.childNodes.length > 0) {
                    node.childNodes.forEach(childNode => {
                        if (childNode.nodeName !== "#text") {
                            childNode.removeAttribute('data-mce-fragment');
                        }
                    })
                }
            }
        });

        billText.DraftText = searchForLDTitleContainer.innerHTML;

        //Clear DocumentCode on amendment create. Setting this is handled in the DB
        if (window.location.pathname.includes('new-amendment')) {
            billText.DocumentCode = null;
        }

        if (["Chaptered", "Gov Recommendation", "Veto Explanation"].includes(billText.LegislationVersion)) {
            delete billText.ChamberCode;
        } else if (["Introduced", "Reengrossed", "Engrossed", "Reenrolled", "Enrolled"].includes(billText.LegislationVersion)) {
            billText.ChamberCode = this.props.match.params.billnumber[0].toUpperCase();
        } else if (billText.Sponsor && billText.Sponsor.toLowerCase() === "member" && billText.Patrons && billText.Patrons[0]) {
            billText.ChamberCode = this.state.memberOptions.find(x => x.value.MemberID === billText.Patrons[0].MemberID).value.ChamberCode
        } else if (billText.Sponsor && billText.Sponsor.toLowerCase() === "committee" && billText.CommitteeID) {
            billText.ChamberCode = this.state.committeeOptions.find(x => x.value.CommitteeID === billText.CommitteeID).value.ChamberCode;
        }

        let jsonPromise = Promise.resolve();
        if (JSON.stringify(this.state.patronList) !== JSON.stringify(this.state.originalPatronList)) {
            jsonPromise = this.props.actions.savePatrons({ patrons: this.state.patronList });
        }
        jsonPromise.then(() => {
            this.props.actions.saveBillText({ TextsList: [billText] })
                .then(() => {
                    if (window.location.pathname.includes('new-amendment')) {
                        if (this.state.billText.ChamberCode === "H" && this.state.selectedSponsor.value == "Member") {
                            this.props.actions.saveIhodAmendment('?jvisa=7C936b138d2b6c3766Eb1cD5F84C6lRhG3JwWgvUWW&'
                                + 'session=' + this.props.session.selectedSession.SessionCode
                                + '&bill_num=' + this.state.billText.LegislationNumber
                                + '&mem_key=' + this.state.selectedMember.value.MemberNumber
                                + '&file_name=' + this.state.billText.LegislationNumber + '-' + this.state.selectedMember.value.MemberNumber + '.htm'
                                + '&ld_num=' + this.props.bills.billTextSave[0].DocumentCode)
                        }

                        if (this.props.bills.billTextSave[0]) {
                            billText.LegislationTextID = this.props.bills.billTextSave[0].LegislationTextID;
                            billText.LegislationVersionID = this.props.bills.billTextSave[0].LegislationVersionID;
                        }
                    }

                    let documentCodeChanged = false;
                    if (this.props.bills.billTextSave[0]) {
                        //Use document code that was constructed and returned from database
                        documentCodeChanged = billText.DocumentCode !== this.props.bills.billTextSave[0].DocumentCode;
                        billText.DocumentCode = this.props.bills.billTextSave[0].DocumentCode;
                    }

                    //Create the new pdf version of the bill 
                    const legFileGenParams = "?legislationID=" + billText.LegislationID
                        + "&legislationTextID=" + billText.LegislationTextID
                        + "&legislationVersionID=" + billText.LegislationVersionID
                        + "&sessionID=" + this.state.selectedSession.SessionID;
                    this.props.actions.genBillFile(legFileGenParams)
                        .then(() => {
                            this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                            this.setState({
                                isEditing: false,
                                isSaving: false,
                                isDirty: false,
                                initialPass: 0
                            });
                            if (window.location.pathname.includes('new-amendment') || (billText.TextDisposition !== "Removed" && documentCodeChanged)) {
                                const sessionCode = this.props.match.params.sessionCode;
                                this.props.history.push(`/bill-details/${sessionCode}/${billText.LegislationNumber}/text/${billText.DocumentCode}`)
                            } else if (billText.TextDisposition === "Removed") {
                                const sessionCode = this.props.match.params.sessionCode;
                                this.props.history.push(`/bill-details/${sessionCode}/${billText.LegislationNumber}`);
                                return;
                            }
                            if (cb)
                                cb();
                            else
                                this.getData();
                        })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.error(err)
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false
                    });
                });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.error(err)
            this.props.actions.makeToast([{ message: "Failed to update patrons", type: "failure" }]);
            this.setState({
                isSaving: false
            });
        })
    }

    validateData() {
        let valid = true;
        const billText = this.state.billText;
        let focused = false;

        if (!billText.TextDisposition && !billText.TextDispositionID && billText.LegislationVersion !== "Introduced") {
            this.setState({ textDispositionError: "Please select a text disposition." });
            console.error("No text disposition provided.");
            this.dispositionRef?.current?.focus();
            focused = true;
            valid = false;
        }
        if (["Amendment", "Substitute"].includes(billText.LegislationVersion) && !billText.Sponsor) {
            this.setState({ sponsorError: "Please select a sponsor." });
            console.error("No sponsor provided.");
            if (!focused) { this.sponsorRef?.current?.focus(); focused = true; }
            valid = false;
        }
        if (billText.Sponsor && billText.Sponsor.toLowerCase() === "member" && !billText.Patrons) {
            this.setState({ memberError: "Please select a member." });
            console.error("No member provided.");
            if (!focused) { this.memberRef?.current?.focus(); focused = true; }
            valid = false;
        }
        if (billText.Sponsor && billText.Sponsor.toLowerCase() === "committee" && !billText.CommitteeID) {
            this.setState({ committeeError: "Please select a committee." });
            console.error("No committee provided.");
            if (!focused) { this.committeeRef?.current?.focus(); focused = true; }
            valid = false;
        }
        if (!this.state.draftTextCopy) {
            this.setState({ draftTextError: "Please provide draft text." });
            console.error("No draft text provided.");
            valid = false;
        }

        if (valid) {
            this.setState({
                textDispositionError: "",
                sponsorError: "",
                memberError: "",
                committeeError: "",
                draftTextError: ""
            })
        }

        return valid;
    }

    handleBillDispositionChange(val) {
        this.setState(state => ({
            billText: {
                ...state.billText,
                TextDisposition: val ? val.Name : null,
                TextDispositionID: val ? val.TextDispositionID : null
            }
        }));
    }

    toggleHighlight() {
        this.setState({
            highlight: !this.state.highlight
        })
    }

    highlightText(keywordsFromQueryString) {
        var markInstance = new Mark(document.querySelector(".mark-context"));
        if (this.props.location.state && this.props.location.state.selectedKeywords) {
            this.props.location.state.selectedKeywords.forEach(keyword => {
                const asteriskSearch = keyword.endsWith("*");
                const options = {
                    separateWordSearch: false, //If phrase searching, only mark instances of the entire phrase together; i.e. 'availability of funds' do not mark every individual instance of 'availability' 'of' and 'funds'
                    accuracy: {
                        ///"partially": When searching for "lor" only "lor" inside "lorem" will be marked
                        ///"complementary": When searching for "lor" the whole word "lorem" will be marked
                        ///"exactly": When searching for "lor" only those exact words with a word boundary will be marked. In this example nothing inside "lorem".
                        value: asteriskSearch ? "complementary" : "exactly", //When searching for "of" only those exact words with a word boundary will be marked. In this example nothing inside "offered".
                        limiters: [",", ".", "?", "!", "-", ";", "\"", "'", "`", "%", "$", "@", "*", "(", ")", "[", "]", "/"] //highlight also the above 'exact' words that are also followed by punctuation marks; without this 'availability of funds.' (ending with period) will not be marked when searching 'availability of funds' (no period)
                    }
                };
                const mark = asteriskSearch ? keyword.substring(0, keyword.length - 1) : keyword;
                markInstance.mark(mark, options);
                if (document.getElementsByTagName("mark").length) {
                    this.setState({ showMarkHelp: true, showNextMarkHelp: "Scroll to First" });
                }
            });
        }
        if (keywordsFromQueryString) {
            const selectedKeywords = keywordsFromQueryString.split(" ");
            selectedKeywords.forEach(keyword => markInstance.mark(keyword))
            // Remove keywords from url
            this.props.history.push(this.props.location.pathname);
        }
    };

    navToKeywordHit(dir) {
        const currentIdx = this.state.markHelpIndex ?? -1;
        const elements = [...document.getElementsByTagName("mark")];
        const numElements = elements.length;
        let scrollElement;
        if (numElements > 0) {
            if (dir === NEXT && currentIdx + 1 <= numElements) {
                scrollElement = elements[currentIdx + 1];
            } else if (dir === PREVIOUS && currentIdx >= 1) {
                scrollElement = elements[currentIdx - 1];
            }

            if (scrollElement) {
                scrollElement.scrollIntoView({ behavior: "smooth", block: "center" });
                const newIndex = dir === NEXT ? currentIdx + 1 : currentIdx - 1;
                this.setState({ markHelpIndex: newIndex, showNextMarkHelp: newIndex !== numElements - 1 ? "Next" : false });
            }
        }
    }

    membersCallback(memberList) {
        let members = [];
        let houseMembers = [];
        let senateMembers = [];

        memberList.map((member) => {
            members.push({ text: member.MemberDisplayName, value: member })

            if (member.ChamberCode === "H") {
                houseMembers.push({ text: member.MemberDisplayName, value: member })
            } else {
                senateMembers.push({ text: member.MemberDisplayName, value: member })
            }
        })

        this.setState({
            memberOptions: members,
            houseMemberOptions: houseMembers,
            senateMemberOptions: senateMembers,
            selectedMember: this.state.billText.Patrons && this.state.billText.Patrons.length > 0 ? members.find(x => x.value.MemberID === this.state.billText.Patrons[0].MemberID) : undefined
        })
    }

    committeesCallback(committeeList) {
        let committeeOptions = [];

        committeeList.map((committee, i) => {
            committeeOptions.push({ text: committee.Name + " (" + committee.ChamberCode + ")", value: committee })
        })

        this.setState({
            committeeOptions: committeeOptions,
            selectedCommittee: this.state.billText.CommitteeID ? committeeOptions.find(x => x.value.CommitteeID === this.state.billText.CommitteeID) : undefined
        })
    }

    handleParamChange(value, state, init) {
        //Always reset description if a description param is changed
        let billText = { ...this.state.billText };
        billText.Description = null;

        //If the sponsor is changed reset the selected member and committee in case those have been set previously
        if (state === "selectedSponsor") {
            billText.Sponsor = value.value;
            delete billText.SponsorTypeID;
            delete billText.Patrons;
            delete billText.CommitteeID;
            delete billText.CommitteeName;

            this.setState(state => ({
                selectedMember: undefined,
                selectedCommittee: undefined
            }));
        }

        if (state === "selectedMember") {
            billText.Patrons = [value.value];
        }

        if (state === "selectedCommittee") {
            billText.CommitteeName = value.value.Name
            billText.CommitteeID = value.value.CommitteeID
        }

        this.setState({
            [state]: value,
            billText: billText,
            isDirty: init ? false : true
        })
    }

    handleIsActiveChange() {
        let billText = { ...this.state.billText };
        billText.IsActive = !billText.IsActive;
        this.setState({ billText });
    }

    handleIsPublicChange() {
        let billText = { ...this.state.billText };
        billText.IsPublic = !billText.IsPublic;
        this.setState({ billText });
    }

    toggleTooltip() {
        this.setState({
            showTooltip: !this.state.showTooltip
        })
    }

    isLdIncluded(draftText, LDNumber) {
        //Discovers if the LD number is present in the file's DraftTextJSON or the text's DraftText, and sets a state value to hide displaying a duplicate LD Number if true
        if (typeof draftText === "string") {
            const ldRegex = new RegExp(`<p class=['"]?(ld|ldtitle)['"]?>${LDNumber}<\/p>`);
            if (ldRegex.test(draftText)) {
                this.setState({
                    hideLDNumber: true
                })
            }
        } else {
            for (let i = 0; i < draftText.Content.length; i++) {
                if (draftText.Content[i].Content && draftText.Content[i].Content.includes(LDNumber))
                    this.setState({
                        hideLDNumber: true
                    })
            }
        }
    }

    getData() {
        this.setState({
            isLoaded: false
        });
        const documentNumber = this.props.match.params.documentcode;
        const billNumber = this.props.match.params.billnumber;
        const sessionCode = parseInt(this.props.match.params.sessionCode, 10);
        if (sessionCode > 0 && documentNumber && billNumber) {
            let partner = false;
            //Only use the partner endpoint if the user looking at the bill text is authorized to edit it
            if (this.props.login.userClaims.resources.find(resource => resource === VERSION_AUTHOR)) {
                partner = true;
                this.props.actions.getLegislationDispositions()
                    .catch(err => {
                        if (err === 'Aborted') {
                            return
                        }
                    });
            }
            this.setState({
                sponsorOptions: this.props.login.userClaims.roles.find(x => x === "Admin") && !window.location.pathname.includes('new-amendment') ? [{ value: "Committee", label: "Committee" }, { value: "Member", label: "Member" }, { value: "Conference", label: "Conference" }, { value: "Governor", label: "Governor" }] : [{ value: "Committee", label: "Committee" }, { value: "Member", label: "Member" }]
            })
            //Get the session information for the header using the session in the url
            this.props.actions.getSessionByCode(sessionCode).then(() => {
                this.setState({ selectedSession: this.props.session.selectedSession })
                const params = {
                    SessionCode: sessionCode,
                    LegislationNumbers: [{ LegislationNumber: billNumber }],
                };
                if (window.location.pathname.includes('new-amendment')) {
                    // This is a new amendment
                    // Get the bill information for creation of a new amendment
                    this.props.actions.getBillList(params).then(() => {
                        if (this.props.bills.billList[0]) {
                            this.setState({
                                isLoaded: true,
                                isEditing: true,
                                billText: {
                                    LegislationVersionID: 8,
                                    LegislationVersion: "Amendment",
                                    LegislationID: this.props.bills.billList[0].LegislationID,
                                    LegislationNumber: this.props.bills.billList[0].LegislationNumber,
                                    ChamberCode: this.props.bills.billList[0].ChamberCode,
                                    Description: "Amendment",
                                    DLSPrepared: false,
                                    SessionCode: this.props.session.selectedSession.SessionCode,
                                    SessionYear: this.props.session.selectedSession.SessionYear,
                                    IsActive: true,
                                    IsPublic: false,
                                    TextDisposition: this.props.bills.legislationDispositions[this.props.bills.legislationDispositions.findIndex(dispo => dispo.Name === "Offered")].Name,
                                    TextDispositionID: this.props.bills.legislationDispositions[this.props.bills.legislationDispositions.findIndex(dispo => dispo.Name === "Offered")].TextDispositionID
                                },
                            });

                            //default to member as sponsor
                            this.handleParamChange(this.state.sponsorOptions.find(x => x.value === "Member"), "selectedSponsor", true);
                        } else {
                            throw "Bill data not found"
                        }
                    }).catch(err => {
                        if (err === "Aborted") {
                            return;
                        }
                        this.setState({
                            message: err.toString(),
                            isLoaded: true
                        })
                    });
                } else {
                    this.props.actions.getBillText(`?isPublic=${!partner}&sessionCode=${sessionCode}&documentNumber=${documentNumber}`, partner)
                        .then(() => {
                            //Make sure the text is actually there
                            if (this.props.bills.billText.length !== 1 || !this.props.bills.billText[0].DraftText) {
                                throw ("Legislation text not found")
                            }
                            let billText = this.props.bills.billText[0];
                            //Get the session information for the header dates in tinymce using the text's session
                            this.props.actions.getSessionById(billText.SessionID, false).then(() => {
                                this.setState({ versionSession: this.props.session.selectedSession })
                                const params = {
                                    SessionCode: sessionCode,
                                    LegislationNumbers: [{ LegislationNumber: billNumber }],
                                };

                                if (billText && ["Introduced", "Enrolled", "Chaptered", "Substitute"].includes(billText.LegislationVersion)) {
                                    if (this.props.login.userClaims.resources.includes("LegislationText")) {
                                        this.props.actions.getBillManagementList("?LegislationID=" + billText.LegislationID + "&LegislationVersionID=" + billText.LegislationVersionID + "&SessionID=" + billText.SessionID + "&IsComplete=true").then(() => {
                                            if (this.props.bills.billManagementList[0]) {
                                                this.setState({ billData: this.props.bills.billManagementList[0] }, () => {
                                                    this.getPatronTypes(this.state.billData.ChamberCode);
                                                    this.getMemberList(this.state.billData.SessionID);
                                                    this.getPatrons(this.state.billData.LegislationID, this.state.billData.ChamberCode);
                                                })
                                            } else {
                                                throw "Bill data not found"
                                            }
                                        }).catch(err => {
                                            if (err === "Aborted") {
                                                return;
                                            }
                                            this.setState({
                                                message: err.toString(),
                                                isLoaded: true
                                            })
                                        });
                                    } else {
                                        this.props.actions.getBillList(params).then(() => {
                                            if (this.props.bills.billList[0]) {
                                                this.setState({ billData: this.props.bills.billList[0] }, () => {
                                                    this.getPatronTypes(this.state.billData.ChamberCode);
                                                    this.getMemberList(this.state.billData.SessionID);
                                                    this.getPatrons(this.state.billData.LegislationID, this.state.billData.ChamberCode);
                                                })
                                            } else {
                                                throw "Bill data not found"
                                            }
                                        }).catch(err => {
                                            if (err === "Aborted") {
                                                return;
                                            }
                                            this.setState({
                                                message: err.toString(),
                                                isLoaded: true
                                            })
                                        });
                                    }
                                }
                                billText.originalTextDisposition = billText.TextDisposition;
                                // Try to get the json file. This contains some extra info like prefiled date
                                let jsonPromise;
                                if (billText.JSONFile && billText.JSONFile.length > 0) {
                                    jsonPromise = this.props.actions.getFile(billText.JSONFile[0].FileURL)
                                        .then(() => {
                                            const file = JSON.parse(this.props.nav.file);
                                            billText.OfferedDate = file.OfferedDate;
                                            billText.PrefileDate = file.PrefileDate;
                                            billText.ReferredCommittee = file.ReferredCommittee;
                                            billText.Chamber = file.Chamber.toUpperCase();
                                            billText.LegislationType = file.LegislationType.toUpperCase();
                                            if (file.DraftTextJSON) {
                                                billText.DraftTextFromJson = getJsx(file.DraftTextJSON);
                                                this.isLdIncluded(file.DraftTextJSON, billText.LDNumber);
                                            } else {
                                                this.isLdIncluded(billText.DraftText, billText.LDNumber)
                                            }
                                            billText.jsonFileFound = true;
                                        })
                                } else {
                                    this.isLdIncluded(billText.DraftText, billText.LDNumber)
                                }
                                Promise.all([jsonPromise])
                                    .finally(() => {
                                        this.setState({
                                            isLoaded: true,
                                            billText: billText,
                                            selectedSponsor: billText.Sponsor ? this.state.sponsorOptions.find(x => x.value === billText.Sponsor) : ""
                                        }, () => {
                                            const parsed = queryString.parse(this.props.location.search)
                                            if (this.props.location.state || parsed.keywords) {
                                                this.highlightText(parsed.keywords);
                                            }
                                            if (billText.LegislationVersion === "Introduced" && this.props.location.state && this.props.location.state.patronUpdate && this.props.login.userClaims.claims.find(claim => claim.RoleName === "Admin" || (claim.Resource === PATRON_AUTHOR && claim.Scope === (billNumber[0]?.toUpperCase() === "H" ? "House" : "Senate")))) {
                                                this.toggleEditContent(this.togglePatronModal)
                                            }

                                        })
                                    })
                            }).catch(err => {
                                if (err === "Aborted") {
                                    return;
                                }
                                this.setState({
                                    message: err.toString(),
                                    isLoaded: true
                                })
                            });
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            this.setState({
                                message: err.toString(),
                                isLoaded: true
                            })
                        });
                }
            }).catch(err => {
                if (err === "Aborted") {
                    return;
                }
                this.setState({
                    message: err.toString(),
                    isLoaded: true
                })
            });
        } else {
            this.setState({
                message: "Failed to get session or version"
            });
        }
    }

    applyPassageDates(housePassage, senatePassage) {
        let passageDates = "";
        //Add the passage dates if they exist in the data but aren't in the DraftText yet - add both chambers for joint resolutions, or just the originating chamber for normal resolutions
        if (this.state.billText.ChamberCode === "H") {
            passageDates = this.state.billData.HousePassageDate && this.state.billData.HousePassageDate != "" && !housePassage ? passageDates + "<p class='house-passage' style='text-align: center; margin: 0'>Agreed to by the House of Delegates, " + moment(this.state.billData.HousePassageDate).format("LL") + "</p>" : passageDates;
            if (this.state.billData.LegislationTypeCode === "J") {
                passageDates = this.state.billData.SenatePassageDate && this.state.billData.SenatePassageDate != "" && !senatePassage ? passageDates + "<p class='senate-passage' style='text-align: center; margin: 0'>Agreed to by the Senate, " + moment(this.state.billData.SenatePassageDate).format("LL") + "</p><p></p>" : passageDates;
            } else {
                passageDates = passageDates + "<p></p>";
            }
        } else {
            passageDates = this.state.billData.SenatePassageDate && this.state.billData.SenatePassageDate != "" && !senatePassage ? passageDates + "<p class='senate-passage' style='text-align: center; margin: 0'>Agreed to by the Senate, " + moment(this.state.billData.SenatePassageDate).format("LL") + "</p>" : passageDates;
            if (this.state.billData.LegislationTypeCode === "J") {
                passageDates = this.state.billData.HousePassageDate && this.state.billData.HousePassageDate != "" && !housePassage ? passageDates + "<p class='house-passage' style='text-align: center; margin: 0'>Agreed to by the House of Delegates, " + moment(this.state.billData.HousePassageDate).format("LL") + "</p><p></p>" : passageDates;
            } else {
                passageDates = passageDates + "<p></p>";
            }
        }

        return passageDates;
    }

    triggerDownload(filename, htmlDoc) {
        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(`<html>` + htmlDoc.innerHTML + `</html>`));
        downloadLink.setAttribute('download', filename);

        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
    }

    passageCheck(docParagraphs) {
        let housePassage = false;
        let senatePassage = false;

        for (let i = 0; i < docParagraphs.length; i++) {
            if (docParagraphs[i].innerText.includes("Agreed to by the House")) {
                housePassage = true;
            }

            if (docParagraphs[i].innerText.includes("Agreed to by the Senate")) {
                senatePassage = true;
            }
        }

        return { housePassage: housePassage, senatePassage: senatePassage };
    }

    downloadWordFile() {
        const filename = this.state.billText.DocumentCode + ".doc";
        let htmlDoc = document.createElement('div');
        let passageDates = '';

        //Substitute declarations        
        let doc = document.createElement('div');
        doc.insertAdjacentHTML("afterbegin", this.state.billText.DraftText);
        let docParagraphs = doc.getElementsByTagName('p');
        let prevClass = "";

        if (this.state.billText.LegislationVersion !== "Substitute") {
            let params = '?legislationTextID=' + this.state.billText.LegislationTextID +
                '&sessionID=' + this.state.billText.SessionID +
                '&LegislationVersionID=' + this.state.billText.LegislationVersionID +
                '&onlyHeader=' + false +
                "&isPresentationCopy=" + true;
            if (["Introduced"].includes(this.state.billText.LegislationVersion)) {
                params += '&overrideLegislationVersion=Enrolled';
            }
            this.props.actions.getHtmlFilePreview(params)
                .then(() => {
                    const html = `<html>
                    <head><meta charset="UTF-8"></head>
                    <body>${this.props.bills.htmlFilePreview}</body>
                </html>`;

                    htmlDoc.insertAdjacentHTML("afterbegin", html);
                    let passage = this.passageCheck(htmlDoc.getElementsByTagName("p"))
                    passageDates = this.applyPassageDates(passage.housePassage, passage.senatePassage);

                    let ldtitle = htmlDoc.querySelector(".ldtitle");
                    if (ldtitle) {
                        ldtitle.insertAdjacentHTML("afterend", passageDates);
                    }

                    this.triggerDownload(filename, htmlDoc);
                })
        } else if (this.state.billData.LegislationTypeCode === "B") {
            let passage = this.passageCheck(docParagraphs);
            passageDates = this.applyPassageDates(passage.housePassage, passage.senatePassage);

            for (let j = 0; j < docParagraphs.length; j++) {
                if (j == 2) {
                    let insertText = this.props.session.sessionList.find(x => x.SessionID === this.state.billData.SessionID).SessionType === "Regular"
                        ? this.props.session.sessionList.find(x => x.SessionID === this.state.billData.SessionID).SessionYear
                        + " "
                        + this.props.session.sessionList.find(x => x.SessionID === this.state.billData.SessionID).SessionType
                        + " Session"
                        : this.props.session.sessionList.find(x => x.SessionID === this.state.billData.SessionID).SessionYear + " " + this.props.session.sessionList.find(x => x.SessionID === this.state.billData.SessionID).DisplayName
                    htmlDoc.insertAdjacentHTML("beforeend", "<p style='" + inlineStyles.find(x => x.class == "center").value + "'>" + insertText + "</p>");
                    htmlDoc.insertAdjacentHTML("beforeend", "<p style='" + inlineStyles.find(x => x.class == "center").value + "'>Virginia Acts of Assembly - Chapter</p>");
                }
                if (!['pro', 'patronLine', 'patctr', 'ld', 'textctr', "center"].includes(docParagraphs[j].className)) {
                    //If we're after the session, billno, etc. and the next paragraph is the enacting statement then add ldtitle and passage dates then proceed
                    if (prevClass === "center" && docParagraphs[j].className === "enactstm") {
                        htmlDoc.insertAdjacentHTML("beforeend", "<p style='" + inlineStyles.find(x => x.class == "ldtitle").value + "'>" + this.state.billText.DraftTitle + "</p>");
                        htmlDoc.insertAdjacentHTML("beforeend", passageDates);
                    }

                    //Insert every remaining paragraph unless it's a center following a pro because that indicates a line wrap of the pro containing the introduced date
                    if (!(docParagraphs[j].className === "center" && docParagraphs[j - 1] && docParagraphs[j - 1].className === "pro") && !docParagraphs[j].innerText.includes("SUBSTITUTE")) {
                        docParagraphs[j].style = inlineStyles.find(x => x.class === docParagraphs[j].className) ? inlineStyles.find(x => x.class === docParagraphs[j].className).value : "";
                        htmlDoc.insertAdjacentHTML("beforeend", docParagraphs[j].outerHTML);
                    }

                    prevClass = docParagraphs[j].className;
                }
            }

            let ldtitle = htmlDoc.querySelector(".ldtitle");
            if (ldtitle) {
                ldtitle.insertAdjacentHTML("afterend", passageDates);
            }
            this.triggerDownload(filename, htmlDoc);
        } else {
            let passage = this.passageCheck(docParagraphs)
            passageDates = this.applyPassageDates(passage.housePassage, passage.senatePassage);

            for (let j = 0; j < docParagraphs.length; j++) {
                if (!['pro', 'patronLine', 'patctr', 'textbl', 'ld', 'textctr'].includes(docParagraphs[j].className)) {
                    //If we're after the session, billno, etc. and the next paragraph is the enacting statement then add ldtitle and passage dates then proceed
                    if (prevClass === "center" && docParagraphs[j].className === "enactstm") {
                        htmlDoc.insertAdjacentHTML("beforeend", "<p style='" + inlineStyles.find(x => x.class == "ldtitle").value + "'>" + this.state.billText.DraftTitle + "</p>");
                        htmlDoc.insertAdjacentHTML("beforeend", passageDates);
                    }

                    //Insert every remaining paragraph unless it's a center following a pro because that indicates a line wrap of the pro containing the introduced date
                    if (!(docParagraphs[j].className === "center" && docParagraphs[j - 1] && docParagraphs[j - 1].className === "pro") && !docParagraphs[j].innerText.includes("SUBSTITUTE")) {
                        docParagraphs[j].style = inlineStyles.find(x => x.class === docParagraphs[j].className) ? inlineStyles.find(x => x.class === docParagraphs[j].className).value : "";
                        htmlDoc.insertAdjacentHTML("beforeend", docParagraphs[j].outerHTML);
                    }

                    prevClass = docParagraphs[j].className;
                }
            }

            let ldtitle = htmlDoc.querySelector(".ldtitle");
            if (ldtitle) {
                ldtitle.insertAdjacentHTML("afterend", passageDates);
            }
            this.triggerDownload(filename, htmlDoc);
        }
    }

    // RETRIEVE PATRON TYPES
    getPatronTypes(chamberCode) {
        this.props.actions.getPatronRoles()
            .then(() => {
                let patronTypes = [...this.props.patrons.patronRoles];
                patronTypes = patronTypes.filter(pt => ['Chief Patron', 'Chief Co-Patron', 'Co-Patron'].includes(pt.Name) || (chamberCode === 'S' && pt.Name === 'Incorporated Chief Co-Patron'))
                patronTypes.sort((a, b) => a.PatronTypeID - b.PatronTypeID);
                this.setState({
                    patronTypes
                }, () => {
                    if (this.state.patronTypes.find(pt => pt.Name === "Co-Patron") && (!this.state.newPatron?.length || !this.state.newPatron[this.state.newPatron.length - 1].Name)) {
                        this.handleNewPatronChange('PatronType', this.state.patronTypes.find(pt => pt.Name === "Co-Patron"));
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    // RETRIEVE PATRONS FOR A BILL
    getPatrons(billID, chamberCode) {
        this.props.actions.getPatronList(billID).then(() => {
            let senatePatronsList = this.props.patrons.senatePatronsList;
            let housePatronsList = this.props.patrons.housePatronsList;
            let chiefPatronsList = this.props.patrons.chiefPatronsList;
            let patronList = this.props.patrons.patronList;
            let originalPatronList = this.props.patrons.patronList;
            if (chamberCode === 'H' && chiefPatronsList && chiefPatronsList.length > 1) {
                for (let i = 1; i < chiefPatronsList.length; i++) {
                    if (chiefPatronsList[i].ChamberCode === 'S') {
                        senatePatronsList.unshift(chiefPatronsList[i]);
                    } else {
                        housePatronsList.unshift(chiefPatronsList[i]);
                    }
                    chiefPatronsList.splice(i, 1);
                    i--;
                }
            }

            patronList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))
            housePatronsList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName));
            senatePatronsList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName));

            this.setState({
                senatePatronsList,
                housePatronsList,
                chiefPatronsList,
                patronList,
                originalPatronList,
                patronIsLoaded: true
            })
        })
    }

    // RETRIEVE MEMBER LIST FOR THE BILL'S SESSION
    getMemberList(sessionID) {
        let params = 'isPublic=false&sessionID=' + sessionID;
        this.props.actions.getMemberList(params)
            .then(() => {
                let memberList = [...this.props.members.memberList];
                memberList.forEach(patron => {
                    patron.label = patron.PatronDisplayName + ' ' + '(' + patron.ChamberCode + ')';
                    patron.value = patron.MemberID;
                });
                memberList.sort((a, b) => a.ChamberCode !== b.ChamberCode && a.ChamberCode === this.state.billData.ChamberCode ? -1 : 0)
                this.setState({
                    memberList
                });
            });
    }

    togglePatronModal() {
        this.setState({
            showPatronModal: !this.state.showPatronModal
        }, () => {
            if (this.state.showPatronModal) {
                //set the patrons prior to edit (different than originalPatronList in the event that they update multiple times) in order to reset in case they cancel
                this.setState({ patronListBeforeEdit: this.state.patronList })
            }
        })
    }

    updatePatrons() {
        this.setState({ savingPatrons: true }, () => {
            //Make call to save the patrons -- even if this is the same list as original, because they may have changed it and now are reverting
            this.savePatrons(() => {
                this.saveText(() => {
                    this.setState({ savingPatrons: false }, () => {
                        this.props.history.push({
                            pathname: `/bill-details/${this.state.billText.SessionCode}/${this.state.billText.LegislationNumber}`,
                            state: { expandPatrons: true }
                        })
                    })
                });
            });
        })
    }

    savePatrons(cb) {
        let draftTextCopy = this.state.draftTextCopy;
        let doc = document.createElement('div');
        doc.insertAdjacentHTML("afterbegin", draftTextCopy);
        let currentPatronLineElement = doc.querySelector('.patctr, .patalt, .patronLine, .billpatron');
        if (currentPatronLineElement) {
            const currChamber = this.state.billData.ChamberCode;
            let patronInfo = "";
            let patronsSameChamber = true;
            let multiplePatrons = false;
            const patrons = [...this.state.patronList].filter(p => !p.RemoveDate);
            patrons.forEach((p) => {
                if (patronsSameChamber && p.ChamberCode != currChamber) {
                    patronsSameChamber = false;
                }
            });
            let patronList = null;
            let i = 0;
            if (patronsSameChamber) {
                i = 0;
                patronList = patrons.sort((a, b) => a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))
                patronList.forEach(patron => {
                    //check and see if this patron is on the other chamber
                    if (patronsSameChamber && patron.ChamberCode != currChamber) {
                        patronsSameChamber = false;
                    }
                    if (i != 0 && i == patronList.length - 1) {
                        patronInfo += " and ";
                        multiplePatrons = true;
                    }
                    else if (i > 0) {
                        patronInfo += ", ";
                        multiplePatrons = true;
                    }
                    i++;
                    patronInfo += PatronDisplayInfo(patron);
                })
            }
            else {
                //current chamber patron info
                i = 0;
                patronList = patrons.filter(p => p.ChamberCode === currChamber).sort((a, b) => a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))
                patronList.forEach(patron => {
                    if (i != 0 && i == patronList.length - 1) {
                        patronInfo += " and ";
                        multiplePatrons = true;
                    }
                    else if (i > 0) {
                        patronInfo += ", ";
                        multiplePatrons = true;
                    }
                    i++;
                    patronInfo += PatronDisplayInfo(patron);
                })
                patronInfo += "; ";
                if (currChamber == "S") {
                    patronInfo += "Delegate";
                }
                else {
                    patronInfo += "Senator";
                }
                //other chamber patron info
                i = 0;
                patronList = patrons.filter(p => p.ChamberCode !== currChamber).sort((a, b) => a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))
                if (patronList.length > 1) {
                    patronInfo += "s: "; //multiple from the other chamber; append "s"
                } else {
                    patronInfo += ": "; //just one from the other chamber; don't append "s"
                }
                patronList.forEach(patron => {
                    if (i !== 0 && i === patronList.length - 1) {
                        patronInfo += " and ";
                        multiplePatrons = true;
                    }
                    else if (i > 0) {
                        patronInfo += ", ";
                        multiplePatrons = true;
                    }
                    i++;
                    patronInfo += PatronDisplayInfo(patron);
                })
            }
            const patronagePrefix = multiplePatrons ? "Patrons- " : "Patron- ";
            const newPatronLine = patronagePrefix + patronInfo;
            currentPatronLineElement.textContent = newPatronLine;
            this.setState({ draftTextCopy: doc.innerHTML }, () => {
                if (cb) cb();
            })
        } else {
            //There's no patron line in the introduced text, so we couldn't update it. But the patrons themselves will still be resequenced
            this.props.actions.makeToast([{ message: "Could not find patron line to update within draft text", type: "failure", long: true }]);
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        let patronList = JSON.parse(JSON.stringify(this.state.patronList));
        if (patronList[result.source.index].PatronTypeID !== patronList[result.destination.index].PatronTypeID) {
            this.props.actions.makeToast([{ message: "You may only reorder patrons of the same patron type", type: "warning", long: true }]);
            return;
        }
        if (patronList.findIndex(a => a.RemoveDate) > -1 && result.destination.index >= patronList.findIndex(a => a.RemoveDate)) {
            alert("You cannot set active patrons after removed patrons.");
            return;
        }
        let [removed] = patronList.splice(result.source.index, 1);

        patronList.splice(result.destination.index, 0, removed);
        patronList.forEach((x, index) => {
            x.Sequence = index + 1
        });

        patronList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))

        this.setState({
            patronList
        })
    }

    togglePatron(patron) {
        let patronList = JSON.parse(JSON.stringify(this.state.patronList));
        let associatedPatron = patronList.find(p => p.MemberID === patron.MemberID);
        if (associatedPatron) {
            if (associatedPatron.RemoveDate) {
                const sequence = this.calculatePatronSequence(associatedPatron);
                for (let i = 0; i < patronList.length; i++) {
                    const p = patronList[i];
                    if (p.PatronDisplayName === associatedPatron.PatronDisplayName) {
                        patronList.splice(i, 1);
                        i--;
                        continue;
                    }
                    if (!p.RemoveDate && p.Sequence >= sequence) {
                        p.Sequence = i + 2;
                    } else if (!p.RemoveDate && p.Sequence < sequence) {
                        p.Sequence = i + 1;
                    } else {
                        continue;
                    }
                };
                associatedPatron.Sequence = sequence;
                patronList.push(associatedPatron);
                associatedPatron.RemoveDate = null;
            } else {
                associatedPatron.RemoveDate = moment().format("L");
                associatedPatron.ByRequest = false;
                associatedPatron.IsIntroducing = false;
                patronList.forEach(p => {
                    if (!p.RemoveDate && p.Sequence > associatedPatron.Sequence) {
                        p.Sequence--;
                    }
                });
                associatedPatron.Sequence = null;
            }
        }
        patronList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))

        if (!patronList.find(x => !x.RemoveDate && x.PatronTypeID === 1)) {
            this.props.actions.makeToast([{ message: "There must be a Chief Patron.", type: "failure" }])
            return;
        }

        this.setState({ patronList });
    }

    handlePatronChange(patron, state, val) {
        let patronList = JSON.parse(JSON.stringify(this.state.patronList));
        let associatedPatron = patronList.find(p => p.MemberID === patron.MemberID);
        if (associatedPatron) {
            if (state === 'PatronType') {
                associatedPatron.PatronTypeID = val ? val.PatronTypeID : null;
                associatedPatron.DisplayName = val ? val.DisplayName : null;
                associatedPatron.Name = val ? val.Name : null;
            } else if (state === 'IsIntroducing' || state === 'ByRequest') {
                associatedPatron[state] = !associatedPatron[state];
            } else {
                associatedPatron[state] = val;
            }
        }

        if (!patronList.find(x => !x.RemoveDate && x.PatronTypeID === 1)) {
            this.props.actions.makeToast([{ message: "There must be a Chief Patron.", type: "failure" }])
            return;
        }

        if (associatedPatron) {
            const sequence = this.calculatePatronSequence(associatedPatron);
            for (let i = 0; i < patronList.length; i++) {
                const p = patronList[i];
                if (p.PatronDisplayName === associatedPatron.PatronDisplayName) {
                    patronList.splice(i, 1);
                    i--;
                    continue;
                }
                if (!p.RemoveDate && (p.Sequence > sequence || (sequence < associatedPatron.Sequence && p.Sequence === sequence))) {
                    p.Sequence = i + 2;
                } else if (!p.RemoveDate && (p.Sequence < sequence || (sequence > associatedPatron.Sequence && p.Sequence === sequence))) {
                    p.Sequence = i + 1;
                } else {
                    continue;
                }
            };
            associatedPatron.Sequence = sequence;
            patronList.push(associatedPatron);
        }

        patronList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))

        this.setState({ patronList });
    }

    handleNewPatronChange(state, val, behavior) {
        let newPatron = [...this.state.newPatron];
        if (state === 'Patron') {
            if (behavior && behavior.action) {
                switch (behavior.action) {
                    case 'select-option':
                        let addedPatron = behavior.option;
                        if (addedPatron.ChamberCode === 'H' && addedPatron.Name === "Incorporated Chief Co-Patron") {
                            addedPatron.Name = null;
                            addedPatron.DisplayName = null;
                            addedPatron.PatronTypeID = null;
                        }
                        newPatron.push({
                            ByRequest: false,
                            IsIntroducing: false,
                            PatronDisplayName: addedPatron.PatronDisplayName,
                            MemberID: addedPatron.MemberID,
                            ChamberCode: addedPatron.ChamberCode,
                            LegislationID: this.state.billData.LegislationID,
                            DisplayName: newPatron[0].DisplayName,
                            Name: newPatron[0].Name,
                            PatronTypeID: newPatron[0].PatronTypeID
                        })
                        newPatron = newPatron.filter(p => p.MemberID);
                        break;
                    case 'remove-value':
                        const removedPatron = behavior.removedValue;
                        const index = newPatron.findIndex(p => p.MemberID === removedPatron.MemberID);
                        newPatron.splice(index, 1);
                        if (!newPatron.length) {
                            this.resetPatrons(removedPatron.Name);
                            return;
                        }
                        break;
                    case 'clear':
                        this.resetPatrons(newPatron[0].Name);
                        return;
                }
            }
        } else if (state === 'PatronType') {
            newPatron.forEach(p => {
                p.PatronTypeID = val ? val.PatronTypeID : null;
                p.DisplayName = val ? val.DisplayName : null;
                p.Name = val ? val.Name : null;
            })
        }

        this.setState({ newPatron });
    }

    resetPatrons(keepPatronType) {
        this.setState({ newPatron: [{}] }, () => {
            if (this.state.patronTypes.find(pt => pt.Name === "Co-Patron")) {
                this.handleNewPatronChange('PatronType', this.state.patronTypes.find(pt => pt.Name === (keepPatronType || "Co-Patron")));
            }
        })
    }

    addNewPatron() {
        let newPatron = [...this.state.newPatron];
        let patronList = JSON.parse(JSON.stringify(this.state.patronList));

        newPatron.forEach(patron => {
            patron.PatronDate = moment().format("L");

            const sequence = this.calculatePatronSequence(patron);
            for (let i = 0; i < patronList.length; i++) {
                const p = patronList[i];
                if (!p.RemoveDate && p.Sequence >= sequence) {
                    p.Sequence = i + 2;
                } else if (!p.RemoveDate && p.Sequence < sequence) {
                    p.Sequence = i + 1;
                } else {
                    continue;
                }
            };
            patron.Sequence = sequence;
            patronList.push(patron);
        });
        patronList.sort((a, b) => Number(Boolean(a.RemoveDate)) - Number(Boolean(b.RemoveDate)) || a.PatronTypeID - b.PatronTypeID || a.Sequence - b.Sequence || a.PatronDisplayName.localeCompare(b.PatronDisplayName))

        this.setState({ patronList }, () => {
            this.resetPatrons();
        });
    }

    calculatePatronSequence(patron) {
        let patronList = JSON.parse(JSON.stringify(this.state.patronList));
        const currentSeq = patron.Sequence;

        //look first by chamber, if none found, then go out of chamber
        let existingPatronOfSameType = patronList.findLast(p => p.PatronDisplayName !== patron.PatronDisplayName && !p.RemoveDate && p.PatronTypeID === patron.PatronTypeID && p.ChamberCode === patron.ChamberCode);
        if (!existingPatronOfSameType) {
            existingPatronOfSameType = patronList.findLast(p => p.PatronDisplayName !== patron.PatronDisplayName && !p.RemoveDate && p.PatronTypeID === patron.PatronTypeID);
        }
        if (existingPatronOfSameType && existingPatronOfSameType.Sequence) {
            return currentSeq && existingPatronOfSameType.Sequence > currentSeq ? existingPatronOfSameType.Sequence : existingPatronOfSameType.Sequence + 1;
        } else {
            const existingPatronOfHigherPriority = patronList.findLast(p => p.PatronDisplayName !== patron.PatronDisplayName && !p.RemoveDate && p.PatronTypeID < patron.PatronTypeID);
            if (existingPatronOfHigherPriority && existingPatronOfHigherPriority.Sequence) {
                return currentSeq && existingPatronOfHigherPriority.Sequence > currentSeq ? existingPatronOfHigherPriority.Sequence : existingPatronOfHigherPriority.Sequence + 1;
            } else {
                return 1;
            }
        }
    }

    handleVersionDateChange(value) {
        let billText = { ...this.state.billText };
        billText.VersionDate = moment(value).startOf('day');

        let draftTextCopy = this.state.draftTextCopy;
        draftTextCopy = draftTextCopy.replace(/<p class="center">\s*on.+<\/p>/, '<p class="center">on ' + value.format("LL") + ')</p>');

        this.setState({ billText, draftTextCopy })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getData();
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { message, isLoaded, isEditing, draftTextCopy, billText, highlight, senatePatronsList, housePatronsList, chiefPatronsList, patronList, selectedSession, versionSession } = this.state;
        const session = selectedSession;
        const sessionHeader = session ? session.SessionYear + " " + session.DisplayName : '';
        const getPatronText = patronArray => {
            const senators = patronArray.filter(p => p.ChamberCode === "S");
            const delegates = patronArray.filter(p => p.ChamberCode === "H");
            let finishedText = "";
            if (billText.ChamberCode === "S") {
                finishedText = senators.map(m => m.PatronDisplayName).join();
                if (delegates.length > 0) {
                    finishedText += "; " + (delegates.length === 1 ? "Delegate: " : "Delegates: ");
                    finishedText += delegates.map(m => m.PatronDisplayName).join();
                }
            }
            if (billText.ChamberCode === "H") {
                finishedText = delegates.map(m => m.PatronDisplayName).join();
                if (senators.length > 0) {
                    finishedText += "; " + (senators.length === 1 ? "Senator: " : "Senators: ");
                    finishedText += senators.map(m => m.PatronDisplayName).join();

                }
            }
            return finishedText;

        }
        if (message) {
            return (<p>{message}</p>);
        }
        const fetchingPatronage = (this.props.location.state && this.props.location.state.patronUpdate && (!this.state.patronTypes || !this.state.memberList || !this.state.patronIsLoaded));
        if (!isLoaded || fetchingPatronage) {
            return (<div className="center-spinner spinner">{fetchingPatronage ? 'Loading...' : 'Loading Results...'}</div>)
        }
        if (isEditing || this.state.gettingEditData) {
            const disableSponsor = !this.props.login.userClaims.roles.find(x => x === "Admin") && !window.location.pathname.includes('new-amendment');

            return (
                this.state.gettingEditData ?
                    <div className="center-spinner spinner">{this.props.location.state && this.props.location.state.patronUpdate ? 'Loading...' : 'Loading Results...'}</div>
                    :
                    <div className="dlas-forms">
                        {this.state.showPatronModal &&
                            <UpdatePatronageModal history={this.props.history} billText={this.state.billText} savingPatrons={this.state.savingPatrons} newPatron={this.state.newPatron} onDragEnd={this.onDragEnd} patronTypes={this.state.patronTypes} handlePatronChange={this.handlePatronChange} handleNewPatronChange={this.handleNewPatronChange} addNewPatron={this.addNewPatron} togglePatron={this.togglePatron} patronList={patronList} memberList={this.state.memberList} updatePatrons={this.updatePatrons} />
                        }
                        <div className={this.state.showPatronModal ? 'd-none' : ''}>
                            <div>
                                <h2>{sessionHeader} {this.state.billText.OnReconvene && " - Reconvened"}</h2>
                                <h3><Link to={`/bill-details/${selectedSession.SessionCode}/${this.props.match.params.billnumber}`}>{this.props.match.params.billnumber}</Link></h3>
                                {!window.location.pathname.includes('new-amendment') &&
                                    <button type="button" className="button-link" onClick={() => this.toggleEditContent()}>Cancel</button>
                                }
                                <div className={`inner-grid ${billText.LegislationVersion === "Substitute" ? 'half-one-one-one-half' : 'four'}`}>
                                    {billText.LegislationVersion === "Substitute" &&
                                        <div>
                                            <label className="label small-text">Proposed On</label>
                                            <DatePicker
                                                name="proposed-on"
                                                id="version-date-datepicker"
                                                selected={billText.VersionDate ? moment(billText.VersionDate) : null}
                                                onChange={this.handleVersionDateChange}
                                            />
                                        </div>
                                    }
                                    <div>
                                        <label className="label small-text">Text Disposition</label>
                                        <Select
                                            options={this.props.bills.legislationDispositions ? this.props.bills.legislationDispositions.filter(d => !["Amendment", "Substitute"].includes(billText.LegislationVersion) || !["Offered", "Reported"].includes(billText.originalTextDisposition) ? d.Name !== "Removed" : true) : []}
                                            getOptionLabel={opt => opt.Name}
                                            getOptionValue={opt => opt.TextDispositionID}
                                            isClearable={true}
                                            onChange={this.handleBillDispositionChange}
                                            ref={this.dispositionRef}
                                            value={this.props.bills.legislationDispositions.find(dispo => dispo.Name === billText.TextDisposition)}
                                            isDisabled={!this.props.login.userClaims.roles.find(x => x === "Admin")}
                                        />
                                        {this.state.textDispositionError && !billText.TextDisposition && <span className="input-feedback" style={{ fontSize: '12px' }}>{this.state.textDispositionError}</span>}
                                    </div>
                                    {["Amendment", "Substitute"].includes(billText.LegislationVersion) &&
                                        <>
                                            <div>
                                                <label className="label small-text">Sponsor</label>
                                                <Select
                                                    isDisabled={disableSponsor}
                                                    options={this.state.sponsorOptions}
                                                    onChange={(e) => this.handleParamChange(e, "selectedSponsor")}
                                                    getOptionLabel={(option) => option.label}
                                                    value={this.state.selectedSponsor}
                                                    ref={this.sponsorRef}
                                                    placeholder={"Select..."}
                                                />
                                                {this.state.sponsorError && !billText.Sponsor && <span className="input-feedback" style={{ fontSize: '12px' }}>{this.state.sponsorError}</span>}
                                            </div>
                                            {this.state.selectedSponsor &&
                                                this.state.selectedSponsor.value &&
                                                this.state.selectedSponsor.value === "Member" &&
                                                <div>
                                                    <label className="label small-text">Member</label>
                                                    <Select
                                                        options={this.props.login.userClaims.roles.find(x => x === "Admin")
                                                            ? this.state.memberOptions
                                                            : this.props.login.userClaims.claims.find(x => x.RoleName === "HouseLegislationTextAuthor")
                                                                ? this.state.houseMemberOptions
                                                                : this.state.senateMemberOptions
                                                        }
                                                        isDisabled={disableSponsor}
                                                        onChange={(e) => this.handleParamChange(e, "selectedMember")}
                                                        getOptionLabel={(option) => option.text}
                                                        value={this.state.selectedMember}
                                                        ref={this.memberRef}
                                                        placeholder={"Select..."}
                                                    />
                                                    {this.state.memberError && !billText.Patrons && <span className="input-feedback" style={{ fontSize: '12px' }}>{this.state.memberError}</span>}
                                                </div>}
                                            {this.state.selectedSponsor &&
                                                this.state.selectedSponsor.value === "Committee" &&
                                                <div>
                                                    <label className="label small-text">Committee</label>
                                                    <Select
                                                        options={this.props.login.userClaims.roles.find(x => x === "Admin")
                                                            ? this.state.committeeOptions
                                                            : this.props.login.userClaims.claims.find(x => x.RoleName === "HouseLegislationTextAuthor")
                                                                ? this.state.committeeOptions.filter(x => x.value.ChamberCode === "H")
                                                                : this.state.committeeOptions.filter(x => x.value.ChamberCode === "S")
                                                        }
                                                        isDisabled={disableSponsor}
                                                        onChange={(e) => this.handleParamChange(e, "selectedCommittee")}
                                                        getOptionLabel={(option) => option.text}
                                                        value={this.state.selectedCommittee}
                                                        ref={this.committeeRef}
                                                        placeholder={"Select..."}
                                                    />
                                                    {this.state.committeeError && !billText.CommitteeName && <span className="input-feedback" style={{ fontSize: '12px' }}>{this.state.committeeError}</span>}
                                                </div>}
                                        </>
                                    }
                                    {(this.props.login.userClaims.roles.find(x => x === "Admin") || billText.LegislationVersion === "Amendment") && /*admin can change IsActive/IsPublic on any version, other users of this page (LegislationText resource) can only change this on amendments*/
                                        <div className="inner-grid two">
                                            <div>
                                                <label className="label small-text">Is Viable</label>
                                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip() }} className="button info">Info</a>
                                                {this.state.showTooltip &&
                                                    <div className="info-box red-edit-tooltip">
                                                        <p>Caution, only change this for reconsiderations.</p>
                                                    </div>
                                                }
                                                <div onClick={this.handleIsActiveChange} className="toggle-switch" style={{ display: 'block' }}>
                                                    <input id="sr-status-change" checked={billText.IsActive} type="checkbox" />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="label small-text">Is Public</label>
                                                <div onClick={this.handleIsPublicChange} className="toggle-switch" style={{ display: 'block' }}>
                                                    <input id="sr-status-change" checked={billText.IsPublic} type="checkbox" />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <br />
                            <Prompt
                                when={this.state.isDirty}
                                message={`You have unsaved changes. Are you sure you would like to leave?`}
                            />
                            <ReviewSection
                                editorContent={draftTextCopy}
                                handleEditorChange={this.handleEditorChange}
                                sessionId={session.SessionID}
                                height={1000}
                                membersCallback={this.membersCallback}
                                committeesCallback={this.committeesCallback}
                                allowAmendmentActions={!["Amendment", "Gov Recommendation"].includes(this.state.billText.LegislationVersion) && !window.location.pathname.includes('new-amendment') ? false : true}
                                overrideRemoveCreateAmendmentButton={this.state.billText.LegislationVersion === "Gov Recommendation"}
                                userChamber={this.props.login.userClaims.roles.find((x) => x === "Admin") ? "Admin" : this.props.login.userClaims.roles.find((x) => x === "HouseLegislationAuthor") ? "H" : "S"}
                                billNumber={this.props.match.params.billnumber}
                                selectedSponsor={this.state.selectedSponsor}
                                selectedMember={this.state.selectedMember}
                                selectedCommittee={this.state.selectedCommittee}
                                setWasEmpty={this.setWasEmpty}
                                wasEmpty={this.state.wasEmpty}
                                setReplaced={this.setReplaced}
                                replaced={this.state.replaced}
                            />
                            {this.state.draftTextError && !draftTextCopy && <span className="input-feedback" style={{ fontSize: '15px' }}>{this.state.draftTextError}</span>}
                            <div className="button-bar">
                                <div>
                                    {!window.location.pathname.includes('new-amendment') &&
                                        <button disabled={this.state.isSaving} type="button" onClick={() => this.toggleEditContent()} className="button secondary">Cancel</button>
                                    }
                                </div>
                                <div className="align-right">
                                    {window.location.pathname.includes('new-amendment') ?
                                        <button disabled={this.state.isSaving} type="button" onClick={this.createNewAmendment} className="button">{this.state.isSaving ? "Creating..." : "Create"}</button>
                                        :
                                        <div className="flex-row flex-vertical-center flex-end">
                                            {!["Amendment", "Gov Recommendation", "Veto Explanation", "Conference Report"].includes(billText.LegislationVersion) &&
                                                <React.Fragment>
                                                    <div className="toggle-switch" onClick={this.toggleReprint}>
                                                        <input id="official-reprint-button" checked={this.state.billText.IsReprint} type="checkbox" style={{ width: "auto" }} />
                                                        <span className="slider"></span>
                                                    </div>
                                                    <label htmlFor="official-reprint-button" className="checkbox-label no-background" style={{ whiteSpace: "no-wrap", marginTop: '0px', fontSize: '14px' }}>Mark As Official Reprint</label>
                                                </React.Fragment>
                                            }
                                            <button disabled={this.state.isSaving || this.state.headerError} type="button" onClick={() => this.saveText()} className="button">{this.state.isSaving ? "Saving..." : this.props.bills.billText && this.props.bills.billText.length && this.props.bills.billText[0].IsPublic && billText.IsPublic ? "Republish" : billText.IsPublic ? "Publish" : "Save"}</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            );
        }
        const MarkHelpBar = (
            <div className="floating-button-bar inline-list mark-help">
                {this.state.markHelpIndex > 0 && <button className="button" type="button" onClick={() => this.navToKeywordHit(PREVIOUS)}>Previous Keyword Hit</button>}
                {this.state.showNextMarkHelp && <button className="button" type="button" onClick={() => this.navToKeywordHit(NEXT)}>{this.state.showNextMarkHelp} Keyword Hit</button>}
            </div>
        )

        return (
            <div>
                <div className="flex-row">
                    <div>
                        <h2>{sessionHeader} {this.state.billText.OnReconvene && " - Reconvened"}</h2>
                        <h3><Link to={`/bill-details/${selectedSession.SessionCode}/${billText.LegislationNumber}`}>{billText.LegislationNumber}</Link></h3>
                        {billText.CommitteeID && <h3><Link to={`/session-details/${selectedSession.SessionCode}/committee-information/${billText.CommitteeNumber}/committee-details`}>{billText.CommitteeName}</Link></h3>}
                        {this.props.login.userClaims.resources.find(resource => resource === VERSION_AUTHOR) &&
                            <button type="button" className="button-link red-edit" onClick={() => this.toggleEditContent()}>Edit</button>
                        }
                        <br />
                        {billText && billText.LegislationNumber && !["Conference Report", "Amendment", "Gov Recommendation"].includes(billText.LegislationVersion) && (billText.LegislationNumber.includes("HB") || billText.LegislationNumber.includes("SB")) ?
                            <div className="flex-row flex-vertical-center flex-start">
                                <div className="toggle-switch" onClick={() => this.toggleHighlight()}>
                                    <input id="highlight-checkbox" checked={highlight} type="checkbox" style={{ width: "auto" }} />
                                    <span className="slider"></span>
                                </div>
                                <label htmlFor="highlight-checkbox" className="checkbox-label no-background">Highlight Proposed Changes</label>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div>
                        {this.props.login.userClaims.resources.includes("LegislationText")
                            && ((["Introduced", "Enrolled"].includes(this.state.billText.LegislationVersion) && this.state.billData && ["J", "R"].includes(this.state.billData.LegislationTypeCode))
                                || (this.state.billText.LegislationVersion === "Chaptered" && this.state.billData && this.state.billData.LegislationTypeCode === "B")
                                || (this.state.billText.LegislationVersion === "Substitute" && this.state.billData)) &&
                            <button type="button" onClick={this.downloadWordFile} className="button-link with-icon"><span className="icon word"></span>Presentation Copy</button>
                        }
                        {billText && billText.PDFFile && billText.PDFFile[0].FileURL &&
                            <a href={billText.PDFFile[0].FileURL} target="_blank" rel="noreferrer" className="button pdf"> PDF</a>
                        }
                        <ReactToPrint
                            trigger={() => <button type="button" className="button print"> Print</button>}
                            content={() => this.componentRef}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                </div>
                <br />
                <div ref={el => (this.componentRef = el)}>
                    {!this.state.hideLDNumber && ["Introduced", "Engrossed", "Reengrossed"].includes(billText.LegislationVersion) &&
                        <div>
                            {billText.LDNumber}
                        </div>
                    }
                    {/* The draft text from json contains everything. Including the header. So just show that if it exists */}
                    {billText.DraftTextFromJson ?
                        <div className={highlight === true ? "highlight mark-context" : "mark-context"}>
                            {this.state.showMarkHelp && MarkHelpBar}
                            {billText.DraftTextFromJson}
                        </div>
                        :
                        <>
                            {billText.jsonFileFound &&
                                <div className="center">
                                    <strong>{billText.Chamber} {billText.LegislationType} NO. {billText.LegislationNumber.replace(/\D/g, '')}</strong>
                                    <div>{billText.PrefileDate && <>Offered {moment(billText.OfferedDate).format("MMMM D, YYYY")}</>}</div>
                                    <div>{billText.OfferedDate && <>Prefiled {moment(billText.PrefileDate).format("MMMM D, YYYY")}</>}</div>
                                    <div>{billText.DraftTitle ? renderHTML(billText.DraftTitle) : ''}</div>
                                    <hr />
                                    <div>Patron&mdash;{getPatronText(billText.Patrons)}</div>
                                    <hr />
                                    <div>{billText.ReferredCommittee
                                        && billText.ReferredCommittee === "Judiciary"
                                        ? <>Referred to Committee on the {billText.ReferredCommittee}</>
                                        : billText.ReferredCommittee === "Courts of Justice"
                                            ? <>Referred to Committee for {billText.ReferredCommittee}</>
                                            : <>Referred to Committee on {billText.ReferredCommittee}</>
                                    }</div>
                                    <hr />
                                </div>
                            }
                            <div className={highlight === true ? "highlight mark-context" : "mark-context"}>
                                {this.state.showMarkHelp && MarkHelpBar}
                                {billText ? renderHTML(billText.DraftText || '') : ''}
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { bills, session, login, patrons, members, nav } = state;
        return {
            bills,
            session,
            login,
            patrons,
            members,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, patronActionCreators, sessionActionCreators, authActionCreators, memberActionCreators, navActionCreators), dispatch)
        }
    }
)(FullText)
