import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { scheduleActionCreators } from '../../../stores/lis-schedule-store';
import Search from './lis-public-home-search';
import Resources from './lis-public-home-resources';
import SessionInfo from './lis-public-home-session-info';
import Schedule from './lis-public-home-schedule';
import moment from 'moment-timezone';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { cancelRequest } from '../../../services/request.service';
import { authActionCreators } from '../../../stores/lis-auth-store';
import MessageOfTheDay from './lis-public-message-of-the-day';
import { collectionActionCreators } from '../../../stores/lis-collection-store';

class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchIsLoaded: true,
            sessionInfoIsLoaded: this.props.nav.session && !isNaN(this.props.nav.session),
            scheduleIsLoaded: false,
            schedule: [],
            gettingMessages: true,
            gettingAdminMessages: true
        };
        this.setupPage = this.setupPage.bind(this);
        this.getMessages = this.getMessages.bind(this);
        this.getAdminMessages = this.getAdminMessages.bind(this);
    }

    setupPage(sessionCode) {
        this.setState({
            searchIsLoaded: false,
            sessionInfoIsLoaded: false
        });

        //Get the session information
        this.props.actions.getSessionByCode(sessionCode)
            .then(() => {
                this.setState({
                    sessionInfoIsLoaded: true
                });
            })

        // Get a list of members for the search
        const getMembers = this.props.actions.getMemberList('sessionCode=' + sessionCode)
            .then(() => {
                return Promise.resolve();
            });
        const getSubjects = this.props.actions.getSubjectList('?sessionCode=' + sessionCode)
            .then(() => {
                return Promise.resolve();
            })
        const getDates = this.props.actions.getIntroDateList('?sessionCode=' + sessionCode)
            .then(() => {
                return Promise.resolve;
            })
        const getStatusCategories = this.props.actions.getBillStatusCategoryReferences()
            .then(() => {
                return Promise.resolve;
            });
        Promise.all([
            getMembers,
            getSubjects,
            getDates,
            getStatusCategories
        ]).then(() => {
            this.setState({
                searchIsLoaded: true
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
        });
    }

    organizeSchedule(scheduleList) {
        // Turn the list of schedule items into a list of dates each containing a list of schedule items
        // This formatting is done so the schedule can be listed in the table with a date as a container
        scheduleList.sort((a, b) => {
            const dateComp = new Date(a.ScheduleDate) - new Date(b.ScheduleDate);
            if (dateComp !== 0) {
                return dateComp;
            }

            const timeA = moment(a.TimeDescription, "h:mm A");
            const timeB = moment(b.TimeDescription, "h:mm A");
            if (timeA.isValid() && timeB.isValid()) {
                if (timeA.isBefore(timeB)) return -1;
                if (timeA.isAfter(timeB)) return 1;
            }
            return 0;
        });
        let organizedSchedule = [];
        let scheduleArray = [];
        let previousSchedule = scheduleList[0];
        scheduleList.forEach(schedule => {
            const scheduleDateTime = this.getScheduleDateTime(schedule);
            const previousScheduleDateTime = this.getScheduleDateTime(previousSchedule);

            if(!schedule.DisplaySequence) {
                schedule.DisplaySequence = 999;
            }

            if (!previousScheduleDateTime.isSame(scheduleDateTime, 'day')) {
                //Add the list of legislation to the category
                //Organize the times so that the hour/minute times are in their seperate containers, this is done to help with formatting
                let newDateContainer = {
                    Times: this.organizeTimes(scheduleArray),
                    Date: previousScheduleDateTime.format("L"),
                };
                organizedSchedule.push(newDateContainer);
                //Empty out the leg array so we can use it for another category
                scheduleArray = [];
                //Add the first item to the next category
                scheduleArray.push(schedule);
            } else {
                scheduleArray.push(schedule);
            }
            //Set this variable so the next iteration will know what the schedule date of the previous iteration was
            previousSchedule = schedule;
        });
        //If the schedule array still has elements then that means not every schedule item in the results has been added to a container
        //So, check to see if the schedule array has elements and if so create another container
        if (scheduleArray.length > 0) {
            let newDateContainer = {
                Times: this.organizeTimes(scheduleArray),
                Date: this.getScheduleDateTime(scheduleArray[0]).format("L")
            };
            organizedSchedule.push(newDateContainer);
        }

        return organizedSchedule;
    }

    organizeTimes(itemsList) {
        itemsList.sort((a, b) => {
            return a.DisplaySequence < b.DisplaySequence ? -1 : a.DisplaySequence > b.DisplaySequence ? 1 : 0
        })

        let organizedTimes = [];
        let items = [];
        let time = "";

        for (let i = 0; i < itemsList.length; i++) {
            //If a ScheduleTime has been specified then use that, otherwise get the time from the ScheduleDate but do not display it in the list
            let currentTime = "";

            //If we've entered a new time then push the current time and sorted items into organizedTimes and reset variables
            if (currentTime !== time) {
                if (items.length > 0) {
                    items.sort((a, b) => a.DisplaySequence - b.DisplaySequence);
                    organizedTimes.push({
                        Items: items,
                        Time: time
                    });
                }

                time = currentTime;
                items = [itemsList[i]];
            } else {
                items.push(itemsList[i]);
            }
        }

        //Sort and push the last time and sorted items
        items.sort((a, b) => a.DisplaySequence - b.DisplaySequence);
        organizedTimes.push({
            Items: items,
            Time: time
        })

        return organizedTimes;
    }

    getScheduleDateTime(schedule) {
        const scheduleDate = moment(schedule.ScheduleDate);
        const scheduleTime = moment(schedule.ScheduleTime, "h:mm A").isValid() && moment(schedule.ScheduleTime, "h:mm A");
        return scheduleDate.hour(scheduleTime ? scheduleTime.hour() : scheduleDate.hour()).minute(scheduleTime ? scheduleTime.minute() : scheduleDate.minute()).second(scheduleTime ? scheduleTime.second() : scheduleDate.second()).tz('America/New_York', true).local();
    }

    componentDidMount() {
        //if another portal has directed the user to this page with a session code url param, persist that session as the selected session
        let session, getSessionPromise;
        const sessionCodeParam = this.props.match.params.sessionCode
        if (parseInt(sessionCodeParam) > 0) {
            //if the sessionlist is already obtained, use that - otherwise, perform a GET
            if (this.props.session.sessionList && this.props.session.sessionList.length) {
                session = this.props.session.sessionList.find(session => session.SessionCode === sessionCodeParam);
            } else {
                getSessionPromise = this.props.actions.getSessionByCode(sessionCodeParam)
                    .then(() => {
                        session = this.props.session.selectedSession;
                    })
            }
            Promise.all([getSessionPromise]).finally(() => {
                if (session) {
                    this.props.actions.changeSession(session.SessionCode)
                    this.setupPage(session.SessionCode);
                }
            })
        }

        //otherwise, setup the page if another page has changed the value of the selected session
        if (!session && this.props.session.selectedSession.SessionCode !== this.props.nav.session) {
            const sessionCode = parseInt(this.props.nav.session, 10);
            if (!isNaN(sessionCode)) {
                this.setupPage(sessionCode);
            }
        }

        //Get the schedule for the day
        const startDate = moment().tz('America/New_York').format('MM/DD/YYYY')
        let params = "startDate=" + startDate;
        this.props.actions.getScheduleList(params)
            .then(() => {
                // Remove all the schedules that happened before the current hour - 2
                const currentDay = moment();
                const currentHour = currentDay.subtract(2, "hours");
                //filter schedule to those that are after today, or are today but after the current hour
                const schedule = this.props.schedule.scheduleList.filter(item => (item.ScheduleDate && moment(item.ScheduleDate).isValid() && moment(item.ScheduleDate).isAfter(moment(), 'day')) || (item.ScheduleTime && moment(item.ScheduleTime, "h:mm A").isValid() && moment(item.ScheduleTime, "h:mm A").tz('America/New_York', true).local().isSameOrAfter(moment().subtract(2, "hours"), 'hour')) || ((!item.ScheduleTime || !moment(item.ScheduleTime, "h:mm A").isValid()) && new Date(item.ScheduleDate).getTime() > currentHour))
                //organize the schedule (set meetings in proper order), and then filter to display the next 3 meetings
                let organizedSchedule = this.organizeSchedule(schedule);
                let filteredOrganizedEvents = [];
                organizedSchedule.forEach(day => {
                    let times = day.Times;
                    let thisDayMeetings = { Date: day.Date, Times: [] };
                    times.forEach(time => {
                        let timeObj = { Time: time.Time, Items: time.Items };
                        thisDayMeetings.Times.push(timeObj);
                    })
                    filteredOrganizedEvents.push(thisDayMeetings);
                })
                this.setState({
                    scheduleIsLoaded: true,
                    schedule: filteredOrganizedEvents
                });
            });

        this.getMessages();
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            const sessionCode = parseInt(this.props.nav.session, 10);
            if (!isNaN(sessionCode)) {
                this.setupPage(sessionCode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    getMessages() {
        this.setState({ gettingMessages: true }, () => {
            this.props.actions.getMessages().then(() => {
                this.setState({ gettingMessages: false })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({ gettingMessages: false })
            })
        })
    }

    getAdminMessages() {
        this.setState({ gettingAdminMessages: true }, () => {
            this.props.actions.getAdminMessages().then(() => {
                this.setState({ gettingAdminMessages: false })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.props.actions.makeToast([{ message: "Failed to get admin messages", type: "failure" }]);
                this.setState({ gettingAdminMessages: false })
            })
        })
    }

    render() {
        return (
            <div className="homepage-wrapper">
                <MessageOfTheDay messages={[...this.props.collection.messages].reverse()} adminMessages={[...this.props.collection.adminMessages].reverse()} gettingMessages={this.state.gettingMessages} gettingAdminMessages={this.state.gettingAdminMessages} getMessages={this.getMessages} getAdminMessages={this.getAdminMessages} />
                <Search
                    memberList={this.props.members.memberList}
                    subjectList={this.props.bills.subjectList}
                    datesList={this.props.bills.introDateList}
                    statusCategoriesList={this.props.bills.billStatusCategoryReferences}
                    isLoaded={this.state.searchIsLoaded && this.state.sessionInfoIsLoaded}
                    session={this.props.nav.session}
                    selectedSession={this.props.session.selectedSession}
                    sessionList={this.props.session.sessionList}
                    history={this.props.history}
                />
                <div className="session-info grid-wrapper three" style={{ minHeight: '422px', overflowWrap: 'anywhere' }}>
                    <Resources
                        isLoaded={this.state.sessionInfoIsLoaded}
                        session={this.props.nav.session}
                        selectedSession={this.props.session.selectedSession}
                        isAuthenticated={this.props.login.isAuthenticated}
                    />
                    <div className="center-column">
                        <SessionInfo
                            isLoaded={this.state.sessionInfoIsLoaded}
                            selectedSession={this.props.session.selectedSession}
                            sessionList={this.props.session.sessionList}
                        />
                    </div>
                    <Schedule
                        schedule={this.state.schedule}
                        isLoaded={this.state.scheduleIsLoaded}
                    />
                </div>
            </div>

        )
    }
}

export default connect(
    (state) => {
        const { bills, session, nav, schedule, members, login, collection } = state;
        return {
            bills,
            session,
            nav,
            schedule,
            members,
            login,
            collection
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, sessionActionCreators, navActionCreators, scheduleActionCreators, memberActionCreators, authActionCreators, collectionActionCreators), dispatch)
        }
    }
)(HomeComponent)