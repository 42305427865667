import { partnerRequest, publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const minutesListRequest = 'MINUTES_LIST_REQUEST';
const minutesListSuccess = 'MINUTES_LIST_SUCCESS';
const minutesListFailure = 'MINUTES_LIST_FAILURE';

const minutesCreateRequest = 'MINUTES_CREATE_REQUEST';
const minutesCreateSuccess = 'MINUTES_CREATE_SUCCESS';
const minutesCreateFailure = 'MINUTES_CREATE_FAILURE';

const categoryCreateSuccess = 'CATEGORY_CREATE_SUCCESS';
const categoryCreateFailure = 'CATEGORY_CREATE_FAILURE';

const minutesBookSuccess = 'MINUTES_BOOK_SUCCESS';
const minutesBookFailure = 'MINUTES_BOOK_FAILURE';

const minutesBookForCalendarSuccess = 'MINUTES_BOOK_FOR_CALENDAR_SUCCESS';
const minutesBookForCalendarFailure = 'MINUTES_BOOK_FOR_CALENDAR_FAILURE';

const minutesBookSaveSuccess = 'MINUTES_BOOK_SAVE_SUCCESS';
const minutesBookSaveFailure = 'MINUTES_BOOK_SAVE_FAILURE';

const entriesSaveSuccess = 'ENTRIES_SAVE_SUCCESS';
const entriesSaveFailure = 'ENTRIES_SAVE_FAILURE';

const entriesCreateSuccess = 'ENTRIES_CREATE_SUCCESS';
const entriesCreateFailure = 'ENTRIES_CREATE_FAILURE';

const entriesCommunicationSaveSuccess = 'ENTRY_COMMUNICATION_SAVE_SUCCESS';
const entriesCommunicationSaveFailure = 'ENTRY_COMMUNICATION_SAVE_FAILURE';

const actionTypesSuccess = "ACTION_TYPE_LIST_SUCCESS";
const actionTypesFailure = "ACTION_TYPE_LIST_FAILURE";

const chairReportSuccess = "CHAIR_REPORT_SUCCESS";
const chairReportFailure = "CHAIR_REPORT_FAILURE";

const committeeMinutesSummarySuccess = "COMMITTEE_MINUTES_SUMMARY_SUCCESS";
const committeeMinutesSummaryFailure = "COMMITTEE_MINUTES_SUMMARY_FAILURE";

const initialState = {
    minutesList: [],
    minutesMessage: '',
    minutesCreateMessage: '',
    minutesBook: '',
    minutesBookError: '',
    minutesBookForCalendar: '',
    minutesBookErrorForCalendarError: '',
    minutesBookSave: [],
    minutesBookSaveError: '',
    categoryCreate: '',
    categoryCreateError: '',
    entriesSave: [],
    entriesSaveError: '',
    entriesCreate: [],
    entriesCreateError: '',
    entriesCommunicationSave: '',
    entriesCommunicationSaveError: '',
    minutesGen: '',
    minutesGenError: '',
    actionTypes: [],
    actionTypesError: '',
    chairReport: '',
    chairReportError: '',
    committeeMinutesSummary: '',
    committeeMinutesSummaryError: ''
};

export const minutesActionCreators = {
    // GET MINUTES LIST FOR MINUTES MGMT GRID
    requestMinutesList: () => ({
        type: minutesListRequest,
        minutesList: [],
        isFetching: true
    }),

    receiveMinutesList: (list, message) => ({
        type: minutesListSuccess,
        isFetching: false,
        minutesList: list,
        minutesMessage: message
    }),

    minutesListError: (message) => ({
        type: minutesListFailure,
        isFetching: false,
        minutesList: [],
        minutesMessage: message
    }),

    getMinutesList: (params, partner) => {
        return dispatch => {
            dispatch(minutesActionCreators.requestMinutesList());

            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetPartnerMinutesBookListAsync?', 'GET', headers, '', params);
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetPublishedMinutesBookListAsync?', 'GET', headers, '', params);
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.minutesListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(minutesActionCreators.receiveMinutesList(result[1].Minutes))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.receiveMinutesList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // MINUTES CREATE
    requestMinutesCreate: () => ({
        type: minutesCreateRequest,
        isFetching: true
    }),

    receiveMinutesCreate: (message) => ({
        type: minutesCreateSuccess,
        isFetching: false,
        minutesCreateMessage: message
    }),

    minutesCreateError: (message) => ({
        type: minutesCreateFailure,
        isFetching: false,
        minutesCreateMessage: message
    }),

    createMinutes: (body, callback) => {
        return dispatch => {
            dispatch(minutesActionCreators.requestMinutesCreate());
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/CreateMinuteBookAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.createMinutesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(minutesActionCreators.receiveMinutesCreate(result[1]))
                        if (callback) callback()
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.minutesCreateError('No Content'))
                        return Promise.reject('No Content')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMinutesBook: (resObj) => ({
        type: minutesBookSuccess,
        minutesBook: resObj,
    }),

    minutesBookError: (message) => ({
        type: minutesBookFailure,
        minutesBook: '',
        minutesBookError: message
    }),

    getMinutesBook: (params, partner) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            let request = '';
            if (partner) {
                request = partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetPartnerMinutesBookAsync/', 'GET', headers, '', params);
            } else {
                request = publicRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetMinutesBookAsync/', 'GET', headers, '', params)
            }
            return request
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.minutesBookError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(minutesActionCreators.receiveMinutesBook(result[1].MinutesBooks[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.minutesBookError('Minutes Data not available'))
                        return Promise.reject('No Minutes Data')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMinutesBookForCalendar: (resObj) => ({
        type: minutesBookForCalendarSuccess,
        minutesBookForCalendar: resObj,
    }),

    minutesBookForCalendarError: (message) => ({
        type: minutesBookForCalendarFailure,
        minutesBookForCalendar: '',
        minutesBookForCalendarError: message
    }),

    getMinutesBookForCalendar: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetMinutesbookInteractiveCalendarAsync/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.minutesBookForCalendarError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(minutesActionCreators.receiveMinutesBookForCalendar(result[1].MinutesBooks[0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.minutesBookForCalendarError('Minutes Data not available'))
                        return Promise.reject('No Minutes Data')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveMinutesBookSave: (list) => ({
        type: minutesBookSaveSuccess,
        minutesBookSave: list,
    }),

    minutesBookSaveError: (message) => ({
        type: minutesBookSaveFailure,
        minutesBookSave: [],
        minutesBookSaveError: message
    }),

    saveMinutesBook: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/SaveMinuteBookAsync/?', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.minutesBookSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[1]) {
                        // Dispatch the success action
                        dispatch(minutesActionCreators.receiveMinutesBookSave(result[1][0]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.minutesBookSaveError('Minutes Data not available'))
                        return Promise.reject('No Minutes Data')
                    }
                }).catch(err => console.log(err))
        }
    },

    receiveCategoryCreate: (result) => ({
        type: categoryCreateSuccess,
        categoryCreate: result
    }),

    categoryCreateError: (message) => ({
        type: categoryCreateFailure,
        categoryCreate: '',
        categoryCreateError: message
    }),

    createCategory: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/CreateMinuteCategoryAsync/?', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.calendarCreateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(minutesActionCreators.receiveCategoryCreate(result[1][0]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.calendarCreateError('Minutes Data not available'))
                        return Promise.reject('No Minutes Data')
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveEntriesSave: (list) => ({
        type: entriesSaveSuccess,
        entriesSave: list[0]
    }),

    entriesSaveError: (message) => ({
        type: entriesSaveFailure,
        entriesSave: [],
        entriesSaveError: message
    }),

    saveEntries: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_ENTRY_API_URL) + '/MinuteEntries/api/SaveMinutesEntryAsync/?', 'PUT', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.entriesSaveError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(minutesActionCreators.receiveEntriesSave(result[1]))
                        //result returns an array with one element
                        return (result[1][0]);
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.entriesSaveError('Minutes Data not available'))
                        return Promise.reject('No Minutes Data')
                    }
                }).catch(err => console.log(err))
        }
    },

    receiveEntriesCreate: (list) => ({
        type: entriesCreateSuccess,
        entriesCreate: list[0]
    }),

    entriesCreateError: (message) => ({
        type: entriesCreateFailure,
        entriesCreate: [],
        entriesCreateError: message
    }),

    createEntries: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_ENTRY_API_URL) + '/MinuteEntries/api/CreateMinutesEntryAsync/?', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.entriesCreateError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(minutesActionCreators.receiveEntriesCreate(result[1]));
                        return (result[1][0]);
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.entriesCreateError('Minutes Data not available'));
                        return Promise.reject('No Minutes Data');
                    }
                }).catch(err => console.log(err));
        }
    },

    receiveEntriesCommunicationSave: (res) => ({
        type: entriesCommunicationSaveSuccess,
        entriesCreate: res
    }),

    entriesCommunicationSaveError: (message) => ({
        type: entriesCommunicationSaveFailure,
        entriesCommunicationSaveError: message
    }),

    saveEntriesCommunication: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_ENTRY_API_URL) + '/MinuteEntries/api/SaveMinuteEntryCommunicationAsync/', 'PUT', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.entriesCommunicationSaveError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(minutesActionCreators.receiveEntriesCommunicationSave(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.entriesCommunicationSaveError('No Content'));
                        return Promise.reject('No Content');
                    }
                }).catch(err => console.log(err));
        }
    },

    receiveActionTypes: (res) => ({
        type: actionTypesSuccess,
        actionTypes: res
    }),

    actionTypesError: (message) => ({
        type: actionTypesFailure,
        actionTypesError: message
    }),

    getActionTypes: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetActionReferenceTypeListAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.actionTypesError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(minutesActionCreators.receiveActionTypes(result[1].ActionReferenceTypeList));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.actionTypesError('No Content'));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveChairReport: (res) => ({
        type: chairReportSuccess,
        chairReport: res
    }),

    chairReportError: (message) => ({
        type: chairReportFailure,
        chairReportError: message
    }),

    getChairReport: (minutesBookID) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetChairReportAsync/', 'GET', headers, '', minutesBookID)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.chairReportError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(minutesActionCreators.receiveChairReport(result[1].ChairReports[0]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.chairReportError('No Content'));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveCommitteeMinutesSummary: (res) => ({
        type: committeeMinutesSummarySuccess,
        committeeMinutesSummary: res
    }),

    committeeMinutesSummaryError: (message) => ({
        type: committeeMinutesSummaryFailure,
        committeeMinutesSummaryError: message
    }),

    getCommitteeMinutesSummary: (minutesBookIDs) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/api/GetCommitteeMinutesSummaryAsync/', 'GET', headers, '', minutesBookIDs)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(minutesActionCreators.committeeMinutesSummaryError(result[1]));
                        return Promise.reject(result[1]);
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(minutesActionCreators.receiveCommitteeMinutesSummary(result[1]));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(minutesActionCreators.committeeMinutesSummaryError('No Content'));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    }

}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case minutesListRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case minutesListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                minutesList: action.minutesList,
                minutesMessage: ''
            })
        case minutesListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                minutesList: action.minutesList,
                minutesMessage: action.minutesMessage
            })
        case minutesCreateRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case minutesCreateSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                minutesCreateMessage: ''
            })
        case minutesCreateFailure:
            return Object.assign({}, state, {
                isFetching: false,
                minutesCreateMessage: action.minutesCreateMessage
            })
        case minutesBookSuccess:
            return Object.assign({}, state, {
                minutesBook: action.minutesBook,
                minutesBookError: ''
            })
        case minutesBookFailure:
            return Object.assign({}, state, {
                minutesBookError: action.minutesBookError
            })
        case minutesBookForCalendarSuccess:
            return Object.assign({}, state, {
                minutesBookForCalendar: action.minutesBookForCalendar,
                minutesBookForCalendarError: ''
            })
        case minutesBookForCalendarFailure:
            return Object.assign({}, state, {
                minutesBookForCalendarError: action.minutesBookForCalendarError
            })
        case minutesBookSaveSuccess:
            return Object.assign({}, state, {
                minutesBookSave: action.minutesBookSave,
                minutesBookSaveError: ''
            })
        case minutesBookSaveFailure:
            return Object.assign({}, state, {
                minutesBookSaveError: action.minutesBookSaveError
            })
        case categoryCreateFailure:
            return Object.assign({}, state, {
                isFetching: false,
                categoryCreateMessage: action.categoryCreateMessage
            })
        case categoryCreateSuccess:
            return Object.assign({}, state, {
                categoryCreate: action.categoryCreate,
                categoryCreateMessage: ''
            })
        case entriesSaveFailure:
            return Object.assign({}, state, {
                isFetching: false,
                entriesSaveMessage: action.entriesSaveMessage
            })
        case entriesSaveSuccess:
            return Object.assign({}, state, {
                entriesSave: action.entriesSave,
                entriesSaveMessage: ''
            })
        case entriesCreateFailure:
            return Object.assign({}, state, {
                isFetching: false,
                entriesCreateMessage: action.entriesCreateMessage
            })
        case entriesCreateSuccess:
            return Object.assign({}, state, {
                entriesCreate: action.entriesCreate,
                entriesCreateMessage: ''
            })
        case entriesCommunicationSaveFailure:
            return Object.assign({}, state, {
                entriesCommunicationSaveError: action.entriesCommunicationSaveError
            })
        case entriesCommunicationSaveSuccess:
            return Object.assign({}, state, {
                entriesCommunicationSave: action.entriesCommunicationSave,
                entriesCommunicationSaveError: ''
            })
        case actionTypesFailure:
            return Object.assign({}, state, {
                actionTypesError: action.actionTypesError
            })
        case actionTypesSuccess:
            return Object.assign({}, state, {
                actionTypes: action.actionTypes,
                actionTypesError: ''
            })
        case chairReportFailure:
            return Object.assign({}, state, {
                chairReportError: action.chairReportError
            })
        case chairReportSuccess:
            return Object.assign({}, state, {
                chairReport: action.chairReport,
                chairReportError: ''
            })
        case committeeMinutesSummaryFailure:
            return Object.assign({}, state, {
                committeeMinutesSummaryError: action.committeeMinutesSummaryError
            })
        case committeeMinutesSummarySuccess:
            return Object.assign({}, state, {
                committeeMinutesSummary: action.committeeMinutesSummary,
                committeeMinutesSummaryError: ''
            })
        default:
            return state
    }
};