import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { reportActionCreators } from "../../../stores/lis-report-store";
import { navActionCreators } from '../../../stores/lis-nav-store';

const BILL_COLLECTION_AUTHOR = "LegislationCollections";

class ReportMaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creating: false,
            showCatchline: false,
            showCurrentStatus: false,
            showTitle: false,
            showChiefPatron: false,
            showAllPatrons: false,
            showSummary: false,
            showNotes: false,
            selectedNotes: props.selectedCollection && this.props.selectedCollection.Name && this.props.selectedCollection.WatchListID ? 'current' : 'all',
            showHistory: false,
            showHouseAmendments: false,
            showSenateAmendments: false,
            showConferenceAmendments: false,
            showGovernorsRecommendations: false,
            personalMessage: '',
            showStatus: {},
            showCommitteeEvent: {},
            showAllBills: false,
            showClerkNames: false,
            showDlsStaffNames: false,
            showLdNumber: false,
            startRange: '',
            endRange: '',
            rangeError: false,
            DLS_STAFF: this.props.login && this.props.login.userClaims && this.props.login.userClaims.claims && this.props.login.userClaims.claims.find(c => c.RoleName === 'BDSDrafter')
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getReportUrl = this.getReportUrl.bind(this);
        this.toggleShowBills = this.toggleShowBills.bind(this);
        this.checkParams = this.checkParams.bind(this);
        this.generateBillList = this.generateBillList.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }

    handleInputChange(key, val) {
        if (key === 'showHistory' && val && !val.all && (!val.recent || val.mostRecentAmount === '' || val.mostRecentAmount < 2)) {
            val.showVotes = false;
        }
        if (['startRange', 'endRange'].includes(key)) {
            val = val.replace(/[^a-zA-Z0-9]/, "");
        }
        this.setState({
            [key]: val,
            rangeError: false
        });
    }

    getReportUrl(newWindow) {
        const billList = this.generateBillList();
        if (billList && billList.length === 0) {
            this.setState({
                rangeError: true
            });
        } else {
            this.setState({ creating: true }, () => {
                const jsonData = JSON.stringify({
                    showCatchline: this.state.showCatchline,
                    showCurrentStatus: this.state.showCurrentStatus,
                    showTitle: this.state.showTitle,
                    showChiefPatron: this.state.showChiefPatron,
                    showAllPatrons: this.state.showAllPatrons,
                    showSummary: this.state.showSummary,
                    showNotes: this.state.showNotes,
                    userCreated: this.props.login.userProfile.email || '',
                    selectedNotes: this.state.selectedNotes,
                    selectedCollection: this.props.selectedCollection && this.props.selectedCollection.Name && this.props.selectedCollection.WatchListID ? this.props.selectedCollection.WatchListID : '',
                    reportTitle: this.props.reportTitle || '',
                    showHistory: this.state.showHistory,
                    showHouseAmendments: this.state.showHouseAmendments,
                    showSenateAmendments: this.state.showSenateAmendments,
                    showConferenceAmendments: this.state.showConferenceAmendments,
                    showGovernorsRecommendations: this.state.showGovernorsRecommendations,
                    showStatus: this.state.showStatus,
                    showCommitteeEvent: this.state.showCommitteeEvent,
                    showLdNumber: this.state.showLdNumber,
                    showClerkNames: this.state.showClerkNames,
                    showDlsStaffNames: this.state.showDlsStaffNames,
                    personalMessage: this.state.personalMessage,
                    billList: billList
                });
                this.props.actions.saveBillReportInformation(window.btoa(jsonData)).then(() => {
                    const billReportInformation = this.props.report.billReportInformation;
                    if (billReportInformation && billReportInformation.ReportNumber) {
                        newWindow.location = '/bill-search-report/' + billReportInformation.ReportNumber;
                    } else {
                        this.props.actions.makeToast([{ message: "Report Failed", type: "failure" }]);
                        newWindow?.close();
                    }
                    this.setState({ creating: false })
                }).catch(err => {
                    newWindow?.close();
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                    this.props.actions.makeToast([{ message: "Report Failed", type: "failure" }]);
                    this.setState({
                        creating: false
                    });
                })
            })
        }
    }

    toggleShowBills() {
        this.setState({
            showAllBills: !this.state.showAllBills
        })
    }

    checkParams() {
        for (const x in this.state) {
            if (typeof this.state[x] === "object") {
                for (const y in this.state[x]) {
                    if (this.state[x][y] === true)
                        return false;
                }
            } else if (this.state[x] === true && x !== "showAllBills") {
                return false;
            }
        }

        return true;
    }

    //Report bill range differs slightly from the bill search range functionality as it allows more advanced cross-chamber/cross-type searching.
    //Order is HB, HJ, HR, SB, SJ, SR.
    //If only one field is entered, it is ignored.
    //If list is empty, it displays an error.
    generateBillList() {
        const startType = this.state.startRange.substring(0, 2).toUpperCase(), endType = this.state.endRange.substring(0, 2).toUpperCase();
        const typeFilled = startType === '' || endType === '';
        const startRange = parseInt(this.state.startRange.substring(2), 10);
        const endRange = parseInt(this.state.endRange.substring(2), 10);

        //return empty array if startType is alphabetically greater than endType (e.g. startType === SB, endType === HB)
        if (startType.localeCompare(endType) === 1) {
            return [];
        }

        return this.props.billList ? this.props.billList.filter(bill => {
            const number = parseInt(bill.LegislationNumber.substring(2), 10), type = bill.LegislationNumber.substring(0, 2);
            return typeFilled ? true :
                //return true IF
                //startType (e.g. HB) === type (e.g. HB) && number (e.g. 123) >= startRange (e.g. 123) 
                //OR type (e.g. SB) is alphabetically greater than startType (e.g. HB) && either endType (e.g. SR) !== type (e.g. SB) or number (e.g 123) <= endRange (e.g. 123)
                ((startType === type && number >= startRange) || (type.localeCompare(startType) === 1 && type.localeCompare(endType) < 1 && (endType !== type || number <= endRange)))
                //AND either endType (e.g. SR) !== type (e.g. HB) or number (e.g. 123) <= endRange (e.g. 123)
                && (endType !== type || number <= endRange)
        }).map(bill => ({
            LegislationNumber: bill.LegislationNumber,
            SessionID: bill.SessionID
        })) : []
    }

    selectAll() {
        this.setState({
            showCatchline: true,
            showCurrentStatus: true,
            showTitle: true,
            showChiefPatron: true,
            showAllPatrons: true,
            showSummary: true,
            showNotes: true,
            showHistory: {
                all: true,
                recent: false,
                mostRecentAmount: 5,
                showVotes: true,
                Committee: false,
                Subcommittee: false
            },
            showHouseAmendments: true,
            showSenateAmendments: true,
            showConferenceAmendments: true,
            showGovernorsRecommendations: true,
            showStatus: {
                showPassedHouse: true,
                showPassedSenate: true,
                showPassed: true,
                showApproved: true,
                showVetoed: true,
                showContinued: true,
                showFailed: true,
                showEmergency: true
            },
            showCommitteeEvent: {
                showFirstHouseCommittee: true,
                showFirstSenateCommittee: true,
                showLastHouseSubcommittee: true,
                showLastSenateSubcommittee: true
            },
            showAllBills: true,
            selectedNotes: "all"
        })
    }

    render() {
        const { showCatchline, showCurrentStatus, showTitle, showChiefPatron, showAllPatrons, showSummary, showNotes, selectedNotes, showHistory, showHouseAmendments, showSenateAmendments, showConferenceAmendments, showGovernorsRecommendations, showStatus, showCommitteeEvent, rangeError, showLdNumber, showClerkNames, showDlsStaffNames, DLS_STAFF } = this.state;
        return (<div onClick={() => this.props.toggleReportMaker(this.props.autoReport)} className="overlay center-content">
            <div className="full" style={{ maxHeight: '100%' }}>
                <div onClick={e => e.stopPropagation()} className="report-maker dlas-forms user-forms advanced-search overlay-content animated-fade-in-up">
                    <div onClick={() => this.props.toggleReportMaker(this.props.autoReport)} className="close-cross">
                        <span>x</span>
                    </div>
                    <div>
                        <h3><b>Create Report</b>{this.props.selectedCollection.Name ? <span> | {this.props.selectedCollection.Name}</span> : ""}</h3>
                    </div>
                    <div className="inner-grid two-col-sidebar-tiny">
                        {this.props.billList && this.props.billList.length > 25
                            ?
                            <React.Fragment>
                                {this.state.showAllBills
                                    ?
                                    <React.Fragment>
                                        <p className="small-text">{this.props.billList && this.props.billList.map((bill, billIndex) =>
                                            <React.Fragment key={billIndex}>{billIndex !== 0 && `, `}{bill.LegislationNumber}</React.Fragment>
                                        )}</p>
                                        <button className="arrow-up" onClick={this.toggleShowBills} />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <p className="small-text">{this.props.billList && this.props.billList.map((bill, billIndex) => {
                                            if (billIndex < 24) {
                                                return (
                                                    <React.Fragment key={billIndex}>{billIndex !== 0 && `, `}{bill.LegislationNumber}</React.Fragment>
                                                )
                                            } else if (billIndex == 25) {
                                                return (
                                                    <React.Fragment key={billIndex}>, {bill.LegislationNumber}...</React.Fragment>
                                                )
                                            }
                                        })}</p>
                                        <button className="arrow-down" onClick={this.toggleShowBills} />
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <p className="small-text">{this.props.billList && this.props.billList.map((bill, billIndex) =>
                                <React.Fragment key={billIndex}>{billIndex !== 0 && `, `}{bill.LegislationNumber}</React.Fragment>
                            )}</p>
                        }
                    </div>
                    <div className={`inner-grid ${DLS_STAFF ? 'four' : 'three'}`}>
                        <div>
                            <div className="checkbox-container">
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showCatchline", !showCatchline)}>
                                        <input id="report-catchline" checked={showCatchline} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-catchline" className="checkbox-label no-background">Catch line</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showCurrentStatus", !showCurrentStatus)}>
                                        <input id="report-current-status" checked={showCurrentStatus} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-current-status" className="checkbox-label no-background">Current Status</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showSummary", !showSummary)}>
                                        <input id="report-summary" checked={showSummary} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-summary" className="checkbox-label no-background">Summary</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showTitle", !showTitle)}>
                                        <input id="report-title" checked={showTitle} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-title" className="checkbox-label no-background">Title</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showChiefPatron", !showChiefPatron)}>
                                        <input id="report-patrons" checked={showChiefPatron} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-patrons" className="checkbox-label no-background">Chief Patron</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showAllPatrons", !showAllPatrons)}>
                                        <input id="report-patrons" checked={showAllPatrons} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-patrons" className="checkbox-label no-background">All Patrons</label>
                                </div>
                            </div>
                            <div className="checkbox-container">
                                <div style={{ padding: "10px", display: "inline-flex", width: "100%" }}>
                                    <label htmlFor="report-personal-message" className="label">Personal Message</label>
                                    <textarea id="report-personal-message" onChange={e => this.handleInputChange("personalMessage", e.target.value)} type="text" />
                                </div>
                            </div>
                            <div className='checkbox-container' style={{ padding: "10px" }}>
                                <div className="inner-grid two" style={{ gridGap: "10px" }}>
                                    <div className="inner-grid two" style={{ gridGap: "0" }}>
                                        <label style={{ padding: "0", marginRight: "15px" }} htmlFor="report-start-range" className="label report-range-start-label">Select From Bill #</label>
                                        <input id="report-start-range" onChange={e => this.handleInputChange("startRange", e.target.value)} type="text" value={this.state.startRange} className="report-range-input" />
                                    </div>
                                    <div className="inner-grid two" style={{ gridGap: "0" }}>
                                        <label style={{ padding: "0" }} htmlFor="report-end-range" className="label report-range-end-label">&nbsp;Through Bill #</label>
                                        <input id="report-end-range" onChange={e => this.handleInputChange("endRange", e.target.value)} type="text" value={this.state.endRange} className="report-range-input" />
                                    </div>
                                </div>
                                {rangeError && <span className="input-feedback">No bills within this range</span>}
                            </div>
                        </div>
                        <div>
                            <div className="checkbox-container">
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange("showHistory", showHistory === false ? { all: false, recent: false, mostRecentAmount: 5, showVotes: false, Committee: false, Subcommittee: false } : false)}>
                                        <input id="report-history" checked={showHistory} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-history" className="checkbox-label no-background">Bill History</label>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    <div className="checkbox checkbox-group">
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" disabled={!showHistory} onClick={() => this.handleInputChange("showHistory", { ...showHistory, all: !showHistory.all, recent: false })}>
                                                <input id="report-history-type-all" checked={showHistory.all} type="checkbox" style={{ width: "auto" }} />
                                                <span className="slider"></span>
                                            </div>
                                            <label htmlFor="report-history-type-all" className={`checkbox-label no-background${showHistory ? "" : " txt-greyed"}`}>All Events{showHistory.all && "*"}</label>
                                        </div>
                                    </div>
                                    <div className="checkbox checkbox-group">
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" disabled={!showHistory} onClick={() => this.handleInputChange("showHistory", { ...showHistory, recent: !showHistory.recent, all: false })}>
                                                <input id="report-history-type-recent" checked={showHistory.recent} type="checkbox" style={{ width: "auto" }} />
                                                <span className="slider"></span>
                                            </div>
                                            <label className="screen-reader-only" htmlFor="report-history-type-recent-amount">Most recent amount</label>
                                            <label htmlFor="report-history-type-recent" className={`checkbox-label no-background${showHistory ? "" : " txt-greyed"}`}>                                        <input
                                                id="report-history-type-recent-amount"
                                                type="text"
                                                className="label-input"
                                                disabled={!showHistory.recent}
                                                value={showHistory.mostRecentAmount}
                                                onChange={(e) => {
                                                    const val = e.target.value;
                                                    if (val === '' || /[^0-9]/.test(val) === false) {
                                                        this.handleInputChange("showHistory", { ...showHistory, mostRecentAmount: val });
                                                    }
                                                }} />
                                                Last Events{showHistory.recent && showHistory.mostRecentAmount > 1 && "*"}</label>
                                        </div>
                                    </div>
                                    <div className="checkbox checkbox-group">
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" disabled={!showHistory} onClick={() => this.handleInputChange("showHistory", { ...showHistory, Committee: !showHistory.Committee })}>
                                                <input id="report-history-type-committee" checked={showHistory.Committee} type="checkbox" style={{ width: "auto" }} />
                                                <span className="slider"></span>
                                            </div>
                                            <label htmlFor="report-history-type-committee" className={`checkbox-label no-background${showHistory ? "" : " txt-greyed"}`}>Last Committee Event</label>
                                        </div>
                                    </div>
                                    <div className="checkbox checkbox-group">
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" disabled={!showHistory} onClick={() => this.handleInputChange("showHistory", { ...showHistory, Subcommittee: !showHistory.Subcommittee })}>
                                                <input id="report-history-type-sub" checked={showHistory.Subcommittee} type="checkbox" style={{ width: "auto" }} />
                                                <span className="slider"></span>
                                            </div>
                                            <label htmlFor="report-history-type-sub" className={`checkbox-label no-background${showHistory ? "" : " txt-greyed"}`}>Last Subcommittee Event</label>
                                        </div>
                                    </div>
                                </div>
                                {(showHistory.all || (showHistory.recent && showHistory.mostRecentAmount > 1)) &&
                                    <div className="checkbox checkbox-group">
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" disabled={!showHistory} onClick={() => this.handleInputChange("showHistory", { ...showHistory, showVotes: !showHistory.showVotes })}>
                                                <input id="report-votes" checked={showHistory.showVotes} type="checkbox" style={{ width: "auto" }} />
                                                <span className="slider"></span>
                                            </div>
                                            <label htmlFor="report-votes" className={`checkbox-label no-background${showHistory ? "" : " txt-greyed"}`}>Include Votes for '{showHistory.all ? 'All Events' : showHistory.mostRecentAmount.toString() + ' Last Events'}'</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="checkbox-container">
                                <div style={{ margin: '5px' }} className="small-text">Include this detail:</div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showCommitteeEvent', { ...showCommitteeEvent, showFirstHouseCommittee: !showCommitteeEvent.showFirstHouseCommittee })}>
                                        <input id="report-committee-house-referred" checked={showCommitteeEvent.showFirstHouseCommittee || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-committee-house-referred" className="checkbox-label no-background">First House Committee Referred</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showCommitteeEvent', { ...showCommitteeEvent, showFirstSenateCommittee: !showCommitteeEvent.showFirstSenateCommittee })}>
                                        <input id="report-committee-senate-referred" checked={showCommitteeEvent.showFirstSenateCommittee || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-committee-senate-referred" className="checkbox-label no-background">First Senate Committee Referred</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showCommitteeEvent', { ...showCommitteeEvent, showLastHouseSubcommittee: !showCommitteeEvent.showLastHouseSubcommittee })}>
                                        <input id="report-committee-house-assigned" checked={showCommitteeEvent.showLastHouseSubcommittee || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-committee-house-assigned" className="checkbox-label no-background">Last House Subcommittee Assigned</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showCommitteeEvent', { ...showCommitteeEvent, showLastSenateSubcommittee: !showCommitteeEvent.showLastSenateSubcommittee })}>
                                        <input id="report-committee-senate-assigned" checked={showCommitteeEvent.showLastSenateSubcommittee || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-committee-senate-assigned" className="checkbox-label no-background">Last Senate Subcommittee Assigned</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="checkbox-container">
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showHouseAmendments', !showHouseAmendments)}>
                                        <input id="report-house-amendments" checked={showHouseAmendments} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-house-amendments" className="checkbox-label no-background">House Amendments</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showSenateAmendments', !showSenateAmendments)}>
                                        <input id="report-senate-amendments" checked={showSenateAmendments} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-senate-amendments" className="checkbox-label no-background">Senate Amendments</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showConferenceAmendments', !showConferenceAmendments)}>
                                        <input id="report-conference-amendments" checked={showConferenceAmendments} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-conference-amendments" className="checkbox-label no-background">Conference Amendments</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showGovernorsRecommendations', !showGovernorsRecommendations)}>
                                        <input id="report-governors-recommendations" checked={showGovernorsRecommendations} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-governors-recommendations" className="checkbox-label no-background">Governor's Recommendations</label>
                                </div>
                            </div>
                            <div className="checkbox-container">
                                <div style={{ margin: '5px' }} className="small-text">Include whether the bill has ever been in this status:</div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showPassedHouse: !showStatus.showPassedHouse })}>
                                        <input id="report-passed-house" checked={showStatus.showPassedHouse || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-passed-house" className="checkbox-label no-background">Passed House</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showPassedSenate: !showStatus.showPassedSenate })}>
                                        <input id="report-passed-senate" checked={showStatus.showPassedSenate || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-passed-senate" className="checkbox-label no-background">Passed Senate</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showPassed: !showStatus.showPassed })}>
                                        <input id="report-passed" checked={showStatus.showPassed || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-passed" className="checkbox-label no-background">Passed</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showApproved: !showStatus.showApproved })}>
                                        <input id="report-approved" checked={showStatus.showApproved || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-approved" className="checkbox-label no-background">Approved</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showVetoed: !showStatus.showVetoed })}>
                                        <input id="report-vetoed" checked={showStatus.showVetoed || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-vetoed" className="checkbox-label no-background">Vetoed</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showContinued: !showStatus.showContinued })}>
                                        <input id="report-continued" checked={showStatus.showContinued || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-continued" className="checkbox-label no-background">Continued</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showFailed: !showStatus.showFailed })}>
                                        <input id="report-failed" checked={showStatus.showFailed || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-failed" className="checkbox-label no-background">Failed</label>
                                </div>
                                <div className="checkbox flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleInputChange('showStatus', { ...showStatus, showEmergency: !showStatus.showEmergency })}>
                                        <input id="report-emergency" checked={showStatus.showEmergency || ""} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="report-emergency" className="checkbox-label no-background">Emergency</label>
                                </div>
                            </div>
                            {this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR) &&
                                <div className="checkbox-container">
                                    <div className="checkbox flex-row flex-vertical-center flex-start">
                                        <div className="toggle-switch" onClick={() => this.handleInputChange('showNotes', !showNotes)}>
                                            <input id="report-notes" checked={showNotes} type="checkbox" style={{ width: "auto" }} />
                                            <span className="slider"></span>
                                        </div>
                                        <label htmlFor="report-notes" className="checkbox-label no-background">Notes</label>
                                    </div>
                                    {this.props.selectedCollection && this.props.selectedCollection.Name && this.props.selectedCollection.WatchListID &&
                                        <div className="checkbox">
                                            <label htmlFor="report-notes-from-collection" className={`label radio-label ${showNotes ? "" : "txt-greyed"}`}>
                                                <input id="report-notes-from-collection" disabled={!showNotes} checked={selectedNotes === 'current'} onChange={() => this.handleInputChange("selectedNotes", 'current')} type="radio" />
                                                Notes from {this.props.selectedCollection.Name}
                                            </label>
                                            <label htmlFor="report-notes-from-all" className={`label radio-label ${showNotes ? "" : "txt-greyed"}`}>
                                                <input id="report-notes-from-all" disabled={!showNotes} checked={selectedNotes === 'all'} onChange={() => this.handleInputChange("selectedNotes", 'all')} type="radio" />
                                                All Notes
                                            </label>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {DLS_STAFF &&
                            <div>
                                <div className="checkbox-container">
                                    <div className="checkbox flex-row flex-vertical-center flex-start">
                                        <div className="toggle-switch" onClick={() => this.handleInputChange('showLdNumber', !showLdNumber)}>
                                            <input id="report-ld-number" checked={showLdNumber} type="checkbox" style={{ width: "inherit" }} />
                                            <span className="slider"></span>
                                        </div>
                                        <label htmlFor="report-ld-number" className="checkbox-label no-background">LD Number of current version</label>
                                    </div>
                                    <div className="checkbox flex-row flex-vertical-center flex-start">
                                        <div className="toggle-switch" onClick={() => this.handleInputChange('showClerkNames', !showClerkNames)}>
                                            <input id="report-clerk-names" checked={showClerkNames} type="checkbox" style={{ width: "inherit" }} />
                                            <span className="slider"></span>
                                        </div>
                                        <label htmlFor="report-clerk-names" className="checkbox-label no-background">Show clerk name(s) of current committee</label>
                                    </div>
                                    <div className="checkbox flex-row flex-vertical-center flex-start">
                                        <div className="toggle-switch" onClick={() => this.handleInputChange('showDlsStaffNames', !showDlsStaffNames)}>
                                            <input id="report-staff-names" checked={showDlsStaffNames} type="checkbox" style={{ width: "inherit" }} />
                                            <span className="slider"></span>
                                        </div>
                                        <label htmlFor="report-staff-names" className="checkbox-label no-background">Show DLS staff name(s) of current committee</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {(showHistory.all || (showHistory.recent && showHistory.mostRecentAmount > 1)) && <small>* If you include '{showHistory.all ? 'All Events' : showHistory.mostRecentAmount.toString() + ' Last Events'}' for each bill, and decide to generate a CSV on the resulting report, 2 CSVs will be generated: one CSV containing '{showHistory.all ? 'All Events' : showHistory.mostRecentAmount.toString() + ' Last Events'}' on that bill, and another CSV containing all other selected options (e.g. Catch line, Summary, etc.).</small>}
                    <div className="inline-list">
                        <button className='button primary' onClick={() => this.selectAll()}>Select All</button>
                        <button style={{ marginRight: "10px", marginLeft: "0px", float: "right" }} type="button" className="button right" disabled={this.state.creating || this.checkParams()} onClick={() => { const newWindow = window.open(); this.getReportUrl(newWindow) }}>{this.state.creating ? 'Creating' : 'Create'}</button>
                        <button style={{ float: "right" }} type="button" className="button secondary right" onClick={() => this.props.toggleReportMaker(this.props.autoReport)}>Close</button>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect(
    (state) => {
        const { login, nav, report } = state;
        return {
            login,
            nav,
            report
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, navActionCreators, reportActionCreators), dispatch)
        }
    }
)(ReportMaker);