import React from 'react';
import { Prompt } from 'react-router';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Button } from '../lis-custom-components/lis-custom-components';
import { scheduleActionCreators } from '../../stores/lis-schedule-store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navActionCreators } from '../../stores/lis-nav-store';

const hyperlinkInstructions = "INSERT HYPERLINK TEXT";

const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        height: "32px",
        minHeight: "32px",
        fontSize: '0.8em',
    }),
    singleValue: (base, state) => {
        return { ...base, };
    },
    dropdownIndicator: base => ({
        ...base,
        padding: '0px 8px'
    })
}

const chamberList = [
    { label: "House", value: 0 },
    { label: "Senate", value: 1 }
]

class MeetingsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displaySequence: 0,
            committee: { label: "Select..." },
            scheduleID: "",
            scheduleType: { label: "Select..." },
            meetingDateTime: "",
            timeDescription: "",
            location: { label: "Select..." },
            description: "",
            ownerName: "",
            chamber: { label: "Select..." },
            isCancelled: false,
            isPublic: true,
            onCalendar: false,
            scheduleTypeOptions: [],
            remarks: "",
            scheduleFiles: []
        }

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.highlightFileAnchorTag = this.highlightFileAnchorTag.bind(this);
        this.saveSchedule = this.saveSchedule.bind(this);
    }

    componentDidMount() {
        let scheduleTypeOptions = [...this.props.scheduleTypeList];
        scheduleTypeOptions.splice(5, 1);
        this.setState({
            scheduleTypeOptions: scheduleTypeOptions
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.meeting.ScheduleID !== prevState.scheduleID) {
            return {
                displaySequence: nextProps.meeting.DisplaySequence ? nextProps.meeting.DisplaySequence : nextProps.organizedSchedule.find(x => moment(x.Date).isSame(nextProps.meeting.meetingDateTime, "day")) ? (Math.max(...nextProps.organizedSchedule.find(x => moment(x.Date).isSame(nextProps.meeting.meetingDateTime, "day")).Times[0].Items.map(x => x.DisplaySequence)) + 1) : 1,
                committee: nextProps.meeting.OwnerID ? nextProps.committeeList.find(x => x.CommitteeID === nextProps.meeting.OwnerID) : { label: "Select..." },
                ownerName: nextProps.meeting.OwnerName ? nextProps.meeting.OwnerName : "",
                scheduleID: nextProps.meeting.ScheduleID ? nextProps.meeting.ScheduleID : 0,
                scheduleType: nextProps.meeting.ScheduleTypeID ? nextProps.scheduleTypeList.find(x => x.ScheduleTypeID === nextProps.meeting.ScheduleTypeID) : { label: "Select..." },
                location: nextProps.meeting.VoteRoomID ? nextProps.locationList.find(x => x.VoteRoomID === nextProps.meeting.VoteRoomID) : { label: "Select..." },
                meetingDateTime: nextProps.meeting.ScheduleDate ? moment(nextProps.meeting.ScheduleDate).format("MM-DD-YYYY HH:mm") : moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).format("MM-DD-YYYY hh:mm"),
                timeDescription: nextProps.meeting.ScheduleTime ? nextProps.meeting.ScheduleTime : "",
                description: nextProps.meeting.Description ? nextProps.meeting.Description : "",
                textHeight: (Math.round((nextProps.meeting.Description ? nextProps.meeting.Description : "").length / 95) * 20 + 40).toString() + 'px',
                chamber: nextProps.meeting.ScheduleTypeID === 2 ? chamberList.find(x => x.label === nextProps.meeting.OwnerName) : { label: "Select..." },
                isCancelled: nextProps.meeting.IsCancelled ? nextProps.meeting.IsCancelled : false,
                isPublic: nextProps.meeting.IsPublic === true ? true : false,
                onCalendar: nextProps.meeting.OnCalendar !== undefined ? nextProps.meeting.OnCalendar : false,
                remarks: nextProps.meeting.Comments ? nextProps.meeting.Comments : "",
                scheduleFiles: nextProps.meeting.ScheduleFiles || []
            }
        } else { return null; }
    }

    handleChange(state, value, callback) {
        if (state === 'timeDescription') {
            value = moment(value, "H:mm").tz('America/New_York').format("h:mm A");
        } else if (state === 'meetingDateTime') {
            value = moment(value).tz('America/New_York');
        }
        this.setState({
            [state]: value
        }, () => {
            this.props.setFormIsDirty(true);
            if (value && value.ParentCommitteeID && value.ChamberCode === "S") {
                this.props.getSubcommitteeMembers("?CommitteeID=" + this.state.committee.CommitteeID + "&EffectiveDate=" + this.state.meetingDateTime)
                    .then(() => {
                        this.setState({
                            remarks: this.props.subcommitteeMembers
                        })
                    })
            } else {
                if (state === 'description') {
                    let textHeight = (Math.round((this.state.description ?? '').length / 95) * 20 + 40).toString() + 'px';
                    this.setState({ textHeight });
                }
                if (callback) callback();
            }
        })
    }

    highlightFileAnchorTag() {
        const field = document.getElementById('description');
        if (field) {
            const start = this.state.description.lastIndexOf(hyperlinkInstructions);
            if (start > -1) {
                const end = start + hyperlinkInstructions.length;
                if (field.createTextRange) {
                    var selRange = field.createTextRange();
                    selRange.collapse(true);
                    selRange.moveStart('character', start);
                    selRange.moveEnd('character', end);
                    selRange.select();
                } else if (field.setSelectionRange) {
                    field.setSelectionRange(start, end);
                } else if (field.selectionStart) {
                    field.selectionStart = start;
                    field.selectionEnd = end;
                }
                field.focus();
            }
        }
    }

    saveSchedule() {
        let params = {
            DisplaySequence: this.state.displaySequence,
            OwnerID: this.state.scheduleType.value !== 6 ? this.state.committee ? this.state.committee.CommitteeID : null : this.props.meeting.OwnerID, //If the meeting is a docket pass back the original OwnerID
            OwnerName: this.state.scheduleType.value === 1 ? this.state.committee ? this.state.committee.Name : null : this.state.scheduleType.value === 2 ? this.state.chamber ? this.state.chamber.label : null : this.state.scheduleType.value === 6 ? this.props.meeting ? this.props.meeting.OwnerName : null : this.state.ownerName, //Pass back the original OwnerName if it's a docket meeting
            ScheduleID: this.state.scheduleID,
            ScheduleTypeID: this.state.scheduleType.value,
            VoteRoomID: this.state.location ? this.state.location.VoteRoomID : undefined,
            RoomDescription: this.state.location ? this.state.location.Description : undefined,
            ScheduleDate: moment(this.state.meetingDateTime).format("MM/DD/YYYY HH:mm"),
            ScheduleTime: this.state.timeDescription !== "Invalid date" ? this.state.timeDescription : "",
            Description: this.state.description,
            IsCancelled: this.state.isCancelled,
            IsPublic: this.state.isPublic,
            OnCalendar: this.state.onCalendar,
            ModificationDate: this.props.meeting.ModificationDate ? this.props.meeting.ModificationDate : '',
            Comments: this.state.remarks
        }

        if (this.state.scheduleID === 0) {
            this.props.createMeeting(params)
        } else {
            params = {
                Schedules: [
                    params
                ]
            }
            this.props.saveMeetings(params)
        }
    }

    handleFileChange(e, originalFile, isDelete) {
        this.props.handleFileChange(e, originalFile, isDelete, (scheduleFiles, update, originalFileIdx, fileURL) => {
            if (!isDelete && !update && (!originalFileIdx || originalFileIdx === -1)) {
                this.handleChange("description", this.state.description + `<a href='${fileURL}' target='_blank' rel='noopener noreferrer'>${hyperlinkInstructions}</a>`, () => {
                    this.highlightFileAnchorTag();
                })
            } else if (update && originalFileIdx > -1) {
                scheduleFiles[originalFileIdx]['change-success'] = true;
                this.setState({ scheduleFiles });
            }
        })
    }

    getScheduleDateTime() {
        const scheduleDate = moment(this.state.meetingDateTime);
        const scheduleTime = moment(this.state.timeDescription, "h:mm A").isValid() && moment(this.state.timeDescription, "h:mm A");
        return scheduleDate.hour(scheduleTime ? scheduleTime.hour() : scheduleDate.hour()).minute(scheduleTime ? scheduleTime.minute() : scheduleDate.minute()).second(scheduleTime ? scheduleTime.second() : scheduleDate.second()).tz('America/New_York', true).local();
    }

    render() {
        const { committeeDropDownOptions, locationList, scheduleTypeList, isSaving, toggleEditMeeting } = this.props;
        //custom filter for the committee dropdown so that subcommittees will be displayed if you filter by the parent committee name
        const filterOption = (option, inputValue) => {
            inputValue = inputValue.toUpperCase();
            // check if this option label includes the inputValue, or if this option is a subcommittee whose parent committee's label contains the inputValue
            const match = option.label.toUpperCase().includes(inputValue) || (option.data.ParentCommitteeID && committeeDropDownOptions.find(cmte => cmte.CommitteeID === option.data.ParentCommitteeID && cmte.label.toUpperCase().includes(inputValue)));
            return match;
        };

        const formatURL = (url) => {
            url = url.replace("https://", "");
            if (url.length >= 40) {
                return url.substring(0, 11) + "..." + url.substring(28);
            }
            return url;
        }

        return (
            <div className="dlas-forms meetings-form">
                <div>
                    <Prompt
                        when={this.props.isDirty}
                        message={`You have unsaved changes. Are you sure you would like to leave?`}
                    />
                </div>
                <div className="inner-grid seven-and-one">
                    {this.state.scheduleID === 0 ? <h3>Create an Event</h3> : <h3>Edit Event</h3>}
                    <div className="checkbox flex-row flex-vertical-center flex-start" style={{ paddingTop: "5%" }}>
                        {this.props.userClaims && this.props.userClaims.roles.find(x => x === "SenateScheduleAuthor") &&
                            <React.Fragment>
                                <div className="toggle-switch" onClick={() => this.handleChange("onCalendar", !this.state.onCalendar)}>
                                    <input id="is-public-button" checked={this.state.onCalendar} type="checkbox" style={{ width: "inherit" }} />
                                    <span className="slider"></span>
                                </div>
                                <label htmlFor="is-public-button" className="checkbox-label no-background" style={{ whiteSpace: "nowrap" }}>On Calendar?</label>
                            </React.Fragment>
                        }
                        <div className="toggle-switch" onClick={() => this.handleChange("isPublic", !this.state.isPublic)}>
                            <input id="is-public-button" checked={this.state.isPublic} type="checkbox" style={{ width: "inherit" }} />
                            <span className="slider"></span>
                        </div>
                        <label htmlFor="is-public-button" className="checkbox-label no-background" style={{ whiteSpace: "nowrap" }}>Is Public?</label>
                    </div>
                </div>
                <div className="grid-wrapper third">
                    <div>
                        <label htmlFor="meeting-datetime">Date</label>
                        <DatePicker
                            id="meeting-datetime"
                            className="width-100"
                            selected={this.getScheduleDateTime()}
                            onChange={(value => { this.handleChange("meetingDateTime", value) })}
                            dateFormat="MM/DD/YYYY"
                        />
                    </div>
                    <div>
                        <label htmlFor="time-description">Time</label>
                        <div>
                            <input
                                type='time'
                                id="time-description"
                                autoComplete='off'
                                value={!this.state.timeDescription ? '' : moment(this.state.timeDescription, "h:mm A").tz('America/New_York', true).local().format("HH:mm")}
                                style={{ minWidth: '125px', maxHeight: '32px', fontFamily: 'arial' }}
                                onChange={(e) => { this.handleChange("timeDescription", e ? e.target.value : "") }}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="meeting-location">Location</label>
                        <Select
                            id="meeting-location"
                            options={locationList}
                            value={this.state.location}
                            onChange={(value) => { this.handleChange("location", value) }}
                            styles={customStyles}
                            isClearable
                        />
                    </div>
                </div>
                <div className="grid-wrapper meetings-form-two-and-one">
                    <React.Fragment>
                        {this.state.scheduleType && this.state.scheduleType.value === 1 || this.state.scheduleType && this.state.scheduleType.value === 6
                            ?
                            <div>
                                <label htmlFor="committee-subcommittee">Committee/Subcommittee</label>
                                {this.state.scheduleType.value === 6
                                    ?
                                    <div style={{ display: "block" }}>
                                        <label>{this.state.ownerName}</label>
                                    </div>
                                    :
                                    <Select
                                        id="committee-subcommittee"
                                        options={committeeDropDownOptions}
                                        value={this.state.committee}
                                        onChange={(value) => { this.handleChange("committee", value) }}
                                        filterOption={filterOption}
                                        styles={customStyles}
                                    />
                                }
                            </div>
                            : this.state.scheduleType && this.state.scheduleType.value === 2
                                ?
                                <div>
                                    <label htmlFor="meeting-chamber">Chamber</label>
                                    <Select
                                        id="meeting-chamber"
                                        value={this.state.chamber}
                                        options={this.props.userClaims.claims.find(x => x.RoleName === "Admin")
                                            ? chamberList
                                            : this.props.userClaims.claims.find(x => x.RoleName === "HouseScheduleEditor")
                                                ? chamberList.filter(x => x.label === "House")
                                                : chamberList.filter(x => x.label === "Senate")
                                        }
                                        onChange={(value) => { this.handleChange("chamber", value) }}
                                        styles={customStyles}
                                    />
                                </div>
                                :
                                <div>
                                    <label htmlFor="meeting-owner">Meeting Owner</label>
                                    <input
                                        id="meeting-owner"
                                        className="width-100"
                                        type="text"
                                        onChange={(e) => { this.handleChange("ownerName", e.target.value) }}
                                        value={this.state.ownerName}
                                    />
                                </div>
                        }
                    </React.Fragment>
                    <div>
                        <label htmlFor="schedule-Type">Meeting Type</label>
                        <Select
                            id="schedule-type"
                            options={this.state.scheduleTypeOptions}
                            value={this.state.scheduleType}
                            onChange={(value) => { this.handleChange("scheduleType", value) }}
                            styles={customStyles}
                            isDisabled={this.state.scheduleType.value === 6}
                        />
                    </div>
                </div>
                <div className="grid-wrapper">
                    <label htmlFor="description">Description{this.state.scheduleID ? <Button type="button" className="button file-button slide" style={{ marginLeft: '5px' }} icons={[{ name: 'add' }]} input={<input type="file" accept=".pdf" onChange={this.handleFileChange} />}>
                        {"  Add PDF"}
                    </Button> : null}
                        {this.state.scheduleFiles?.map((f, i) =>
                            <Button key={i} type="button" className={`button file-button secondary${f['change-success'] ? ' change-success' : ''}`}
                                style={{ marginLeft: '5px', cursor: 'default' }}
                                icons={[
                                    { name: 'delete', props: { style: { cursor: 'pointer' }, onClick: (e) => this.handleFileChange(e, f, true) } },
                                    { name: 'replace', children: <input type="file" accept=".pdf" style={{ position: 'relative', cursor: 'pointer' }} onChange={(e) => this.handleFileChange(e, f)} /> }
                                ]}>
                                <a href={f.FileURL} target='_blank' rel='noopener noreferrer'>{formatURL(f.FileURL)}</a>
                            </Button>)}
                    </label>
                    <textarea
                        id="description"
                        style={{ height: this.state.textHeight || '40px' }}
                        value={this.state.description}
                        onChange={(e) => { this.handleChange("description", e.target.value) }}
                        type="text"
                    />
                </div>
                {((this.state.committee && this.state.committee.ChamberCode === "S") || (this.state.chamber && this.state.chamber.value === 1) || this.state.scheduleType.ScheduleTypeID === 6) &&
                    <div className="grid-wrapper">
                        <label htmlFor="remarks">Remarks</label>
                        <textarea
                            id="remarks"
                            value={this.state.remarks}
                            onChange={(e) => { this.handleChange("remarks", e.target.value) }}
                            type="text"
                        />
                    </div>
                }
                <button className="button float-right row-spacer-margin" type="button" onClick={() => { this.saveSchedule() }} disabled={isSaving}>{!isSaving ? "Save" : "Saving..."}</button>
                <button style={{ marginRight: "10px" }} className="button float-right row-spacer-margin secondary" type="button" onClick={() => toggleEditMeeting()}>Cancel</button>
            </div>
        )
    }
}

export default MeetingsForm;