import React from "react";
import ReactToPrint from "react-to-print";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cancelRequest } from '../../../services/request.service';
import { reportActionCreators } from "../../../stores/lis-report-store";
import { sessionActionCreators } from "../../../stores/lis-session-store";
import renderHTML from "react-render-html";
import { Link } from 'react-router-dom';
import { billActionCreators } from "../../../stores/lis-legislation-store";

class ReportManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            sessionList: [],
            reportTypes: [],
            selectedSession: '',
            selectedReport: '',
            selectedDate: null,
            reportData: []
        };
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    };

    handleFormChange(key, val) {
        this.setState({
            [key]: val,
            reportData: []
        }, () => {
            if (key === "selectedSession") {
                this.props.actions.getIntroDateList('?sessionCode=' + val.SessionCode);
                this.setState({
                    selectedDate: null
                })
            }
        });
    }

    handleReportTypeChange(val) {
        this.setState({
            selectedReport: val,
            selectedDate: null,
            reportData: []
        });
    }

    getReport() {
        const { selectedDate, selectedSession, selectedReport } = this.state;
        this.setState({
            isSearching: true
        });
        let params = `?sessionID=${selectedSession.SessionID}&reportID=${selectedReport.ReportID}`;
        if (selectedDate) {
            params += `&reportDate=${selectedDate.format('YYYY-MM-DD')}`
        }
        this.props.actions.getReport(params)
            .then(() => {
                let reportDataChamber = '';
                let reportDataName = '';
                if (selectedReport.ReportName.includes('Senate')) {
                    reportDataChamber = 'SENATE OF VIRGINIA'
                    reportDataName = selectedReport.ReportName.replace('Senate ', '');
                }
                if (selectedReport.ReportName.includes('House')) {
                    reportDataChamber = 'HOUSE OF DELEGATES'
                    reportDataName = selectedReport.ReportName.replace('House ', '');
                }
                const sessionStartObj = selectedSession.SessionEvents && selectedSession.SessionEvents.length && selectedSession.SessionEvents.find(date => date.DisplayName === "Session Start");
                const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate).format("MM/DD/YYYY") : '';
                let reportData = this.props.report.report;
                reportData.forEach((rpt, idx) => {
                    rpt.showItem = idx === 0;
                });
                this.setState({
                    isSearching: false,
                    reportData: reportData,
                    reportDataChamber: reportDataChamber,
                    reportDataDate: startDate,
                    reportDataName: reportDataName
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isSearching: false
                });
            })
    }

    handleRadioChange(item) {
        let filteredReportData = [...this.state.reportData];
        filteredReportData.forEach(reportItem => {
            if (reportItem.Heading === item.Heading) {
                reportItem.showItem = true;
            } else {
                reportItem.showItem = false;
            }
        });
        this.setState({
            reportData: filteredReportData
        });
    }

    componentDidMount() {
        this.props.actions.getSessionList()
            .then(() => {
                const sessionList = [...this.props.session.sessionList];
                let selectedSession = '';
                sessionList.forEach(session => {
                    session.label = session.SessionYear + " " + session.DisplayName
                    session.value = session.SessionID;
                    if (session.IsDefault) {
                        selectedSession = session;
                    }
                });
                this.setState({
                    sessionList: sessionList,
                    selectedSession: selectedSession,
                    sessionisLoaded: true
                }, () => {
                    this.props.actions.getIntroDateList('?sessionCode=' + this.state.selectedSession.SessionCode);
                });
            });
        this.props.actions.getReportTypes()
            .then(() => {
                let reportTypes = [...this.props.report.reportTypes];

                reportTypes.forEach(type => {
                    type.label = type.ReportName;
                    type.value = type.ReportID;
                });
                this.setState({
                    reportTypes: reportTypes.filter(x => {
                        if (this.props.login.userClaims.claims.find(x => x.RoleName === "Admin"))
                            return true;

                        if (this.props.login.userClaims.claims.find(x => x.RoleName === "SenateReportFileGenerationEditor"))
                            if (x.ReportID == 2 || x.ReportID == 4)
                                return true;

                        if (this.props.login.userClaims.claims.find(x => x.RoleName === "HouseReportFileGenerationEditor"))
                            if (x.ReportID == 1 || x.ReportID == 3)
                                return true;
                    }),
                    reportTypesAreLoaded: true
                }, () => {
                    if (this.props.login.userClaims.claims.find(x => x.RoleName === "HouseReportFileGenerationEditor") && this.state.reportTypes.find(rt => rt.label === "House Daily Rule Report")) {
                        this.handleReportTypeChange(this.state.reportTypes.find(rt => rt.label === "House Daily Rule Report"));
                    } else if (this.props.login.userClaims.claims.find(x => x.RoleName === "SenateReportFileGenerationEditor") && this.state.reportTypes.find(rt => rt.label === "Senate Daily Rule Report")) {
                        this.handleReportTypeChange(this.state.reportTypes.find(rt => rt.label === "Senate Daily Rule Report"));
                    }
                });
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { reportTypes, sessionList, isSearching, selectedSession, selectedReport, selectedDate, reportData } = this.state;
        if (!this.state.sessionisLoaded || !this.state.reportTypesAreLoaded) {
            return (<div className="center-spinner spinner">Loading...</div>)
        }
        // Get a list of distinct headers for the filter section.
        let flags = new Set();
        let uniqueHeaderItems = reportData.filter(item => {
            if (flags.has(item.Heading)) {
                return false;
            }
            flags.add(item.Heading);
            return true;
        })
        const dateLabel = this.state.selectedReport.value === 3 && this.state.selectedSession.SessionCode === "20251"
            ? "January 13, 2025" /* Date we started after the water crisis, Google it. --JGB */
            : moment(this.state.selectedReport.ReportID === 1 || this.state.selectedReport.ReportID === 2
                ? this.state.selectedDate
                : this.state.reportDataDate).format('MMMM D, YYYY')

        let dateList = [];
        this.props.bills.introDateList?.forEach(x => dateList.push(moment(x.IntroductionDate)));

        return (<div>
            <div className="header-half">
                <h1>Rule Reports</h1>
            </div>
            <div className="user-forms dlas-forms">
                <fieldset className="fieldset-collapse">
                    <div>
                        <div className="inner-grid five">
                            <div>
                                <label>Report Type</label>
                                <Select
                                    options={reportTypes}
                                    value={selectedReport}
                                    styles={customStyles}
                                    onChange={this.handleReportTypeChange}
                                />
                            </div>
                            <div></div>
                        </div>
                        <div className="inner-grid five">
                            <div>
                                <label>Session</label>
                                <Select
                                    options={sessionList}
                                    value={selectedSession}
                                    styles={customStyles}
                                    onChange={(val) => this.handleFormChange('selectedSession', val)}
                                />
                            </div>
                            {(selectedReport && selectedReport.ReportName.toLowerCase().includes('daily')) || (selectedReport && selectedReport.ReportName.toLowerCase().includes('prefile') && selectedReport.ReportName.toLowerCase().includes('house')) ?
                                <div>
                                    <label>Date</label>
                                    {(selectedReport && selectedReport.ReportName.toLowerCase().includes('prefile') && selectedReport.ReportName.toLowerCase().includes('house'))
                                        ?
                                        <DatePicker
                                            selected={selectedDate}
                                            isClearable
                                            placeholderText="Date"
                                            onChange={(val) => this.handleFormChange('selectedDate', val)}
                                            includeDates={dateList || []}
                                        />
                                        :
                                        <DatePicker
                                            selected={selectedDate}
                                            isClearable
                                            placeholderText="Date"
                                            onChange={(val) => this.handleFormChange('selectedDate', val)}
                                        />
                                    }
                                </div> : null
                            }
                        </div>
                        <br />
                    </div>
                    <div className="inner-grid six">
                        <div>
                            <label></label>
                            <button
                                type="button"
                                onClick={this.getReport}
                                disabled={!selectedSession || !selectedReport || selectedReport.ReportID === 1 && !selectedDate || selectedReport.ReportID === 2 && !selectedDate}
                                className="button">
                                Get Report</button>
                        </div>
                        <div></div>
                    </div>
                </fieldset>
                <div>
                    {isSearching ?
                        <div className="center-spinner spinner">Getting Records...</div>
                        :
                        <React.Fragment>
                            <div className="center">{this.props.report.reportError}</div>
                            {reportData.length > 0 &&
                                <React.Fragment>
                                    <hr className='faded-line' />
                                    <div className="advanced-search checkbox-container dlas-forms">
                                        <span>Include Section</span>
                                        <br />
                                        {uniqueHeaderItems.map((item, idx) =>
                                            <div key={item.Heading} className="label-and-radio">
                                                <label htmlFor={idx}><input id={idx} checked={this.state.reportData.find(rpt => rpt.Heading === item.Heading).showItem} onChange={() => this.handleRadioChange(item)} type="radio" /> {item.Heading}</label>
                                            </div>
                                        )}
                                    </div>
                                    <hr className='faded-line' />
                                    <div className="align-right">
                                        <ReactToPrint
                                            trigger={() => <a type="button" className="button print">Print</a>}
                                            content={() => this.printRef}
                                            pageStyle={"break-inside: avoid"}
                                        />
                                    </div>
                                    <div ref={el => this.printRef = el} className="public-details details-section section-content">
                                        <p className="center">{this.state.reportDataName !== "Daily Rule Report" && this.state.reportDataName}</p>
                                        <p className="center">
                                            <strong>
                                                {this.state.reportDataChamber}
                                            </strong>
                                        </p>
                                        <p className="align-right">{"Printed " + moment().format("MMMM D, YYYY")}</p>
                                        <p className="align-right">{dateLabel}</p>
                                        {reportData.map((reportItem, reportItemIndex) =>
                                            <div key={reportItemIndex}>
                                                {reportItem.showItem &&
                                                    <React.Fragment>
                                                        <p>{reportItem.Heading}</p>
                                                        {reportItem.Legislation.filter(x => {
                                                            if (selectedDate && selectedReport.ReportName.toLowerCase().includes('prefile') && selectedReport.ReportName.toLowerCase().includes('house')) {
                                                                return moment(selectedDate).isSame(moment(x.IntroductionDate), "day")
                                                            } else {
                                                                return true;
                                                            }
                                                        }).map((bill, billIndex) =>
                                                            <div key={billIndex}>
                                                                <p><b><Link to={`/bill-details/${selectedSession.SessionCode}/${bill.LegislationNumber}`} target="_blank" rel="noreferrer">{bill.LegislationNumber.substring(0, 1) + '.' + (bill.LegislationNumber.substring(1, 2) === 'J' ? 'J.R. ' : bill.LegislationNumber.substring(1, 2) + '. ') + bill.LegislationNumber.substring(2)}</Link></b> {bill.DraftTitle && renderHTML(bill.DraftTitle.replace("<p class=ldtitle>", "<span>").replace("</p>", "</span>"))}</p>
                                                                <p className="align-right">{bill.LDNumber}</p>
                                                                {bill.EffectiveType === "Emergency" && <p>EMERGENCY</p>}
                                                                {this.state.reportDataName !== "Daily Rule Report" && <p>(Prefiled {moment(bill.IntroductionDate).format('MMMM D, YYYY')})</p>}
                                                                <p>{bill.Patrons.length > 1 ? "Patrons--" : "Patron--"}
                                                                    {bill.Patrons.map((patron, patronIndex) => {
                                                                        let patronString = '';
                                                                        if (bill.Patrons[patronIndex + 1] && bill.Patrons[patronIndex + 1].ChamberCode !== bill.ChamberCode && bill.Patrons[patronIndex].ChamberCode === bill.ChamberCode) {
                                                                            patronString = (patronIndex !== 0 ? " and " : "") + patron.PatronDisplayName + (bill.ChamberCode === "H" ? patronIndex + 2 === bill.Patrons.length ? "; Senator: " : "; Senators: " : patronIndex + 2 === bill.Patrons.length ? "; Delegate: " : "; Delegates: ");
                                                                        } else if (patronIndex + 1 === bill.Patrons.length && bill.Patrons.length > 1 && bill.Patrons[patronIndex].ChamberCode === bill.Patrons[patronIndex - 1].ChamberCode) {
                                                                            patronString = " and " + patron.PatronDisplayName;
                                                                        } else if (patronIndex != 0 && bill.Patrons[patronIndex - 1].ChamberCode === bill.Patrons[patronIndex].ChamberCode) {
                                                                            patronString = ", " + patron.PatronDisplayName;
                                                                        } else {
                                                                            patronString = patron.PatronDisplayName
                                                                        }
                                                                        return (<React.Fragment key={patronIndex}>{patronString}{patron.ByRequest ? ' (By Request)' : null}</React.Fragment>)
                                                                    })}</p>
                                                                {bill.ConsentRequired &&
                                                                    <p>Unanimous consent to introduce</p>
                                                                }
                                                                {bill.GovernorsRequest &&
                                                                    <p>Introduced at the request of Governor.</p>
                                                                }
                                                                {bill.CommitteeName
                                                                    ? bill.CommitteeName === "Judiciary"
                                                                        ? <p>Referred to Committee on the {bill.CommitteeName}</p>
                                                                        : bill.CommitteeName === "Courts of Justice"
                                                                            ? <p>Referred to Committee for {bill.CommitteeName}</p>
                                                                            : <p>Referred to Committee on {bill.CommitteeName}</p>
                                                                    :
                                                                    !reportItem.Heading.includes(' laid on ')
                                                                        ? <p>Committee Referral Pending</p>
                                                                        : null
                                                                }
                                                                <br />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>)
    }
}

const customStyles = {
    container: base => ({
        ...base,
        lineHeight: 'normal',
    }),
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: '0px 8px'
    })
}

export default connect(
    (state) => {
        const { session, report, login, bills } = state;
        return {
            session,
            report,
            login,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, reportActionCreators, billActionCreators), dispatch)
        }
    }
)(ReportManagement);