import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import moment from 'moment-timezone';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { connect } from 'react-redux';
import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component'
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { cancelRequest } from '../../../services/request.service';
import { voteActionCreators } from '../../../stores/lis-votes-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import striptags from 'striptags';

const MEETING = 1;
const CHAIRMANS_REPORT = 2;
const VOTE_SHEET = 3;

const Entry = props => {
    return (
        <React.Fragment>
            <div className="inner-grid two-col-sidebar-backwards">
                <div>
                    <Link to={`/bill-details/${props.session.SessionCode}/${props.entry.LegislationNumber}`}>{props.entry.LegislationNumber}</Link>
                </div>
                <div>
                    <b>{props.entry.LegislationDescription}</b>
                    {props.entry.MinutesActivities && props.entry.MinutesActivities.filter(ma => !ma.DeletionDate).map((activity, index) =>
                        <ul key={index} style={{ marginLeft: '1em' }}>
                            <li>{activity.VoteID && activity.VoteIsPublic ?
                                <Link to={`/vote-details/${props.entry.LegislationNumber}/${props.session.SessionCode}/${activity.VoteNumber}`}>{activity.Description}</Link>
                                :
                                <span className="off-color-text">{activity.Description}</span>
                            }</li>
                        </ul>
                    )}
                </div>
            </div>
            <br />
        </React.Fragment>
    );
}

class PublicMeetingDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            session: '',
            minutesBook: '',
            committee: '',
            isLoaded: false,
            subformIsLoaded: true,
            view: parseInt(this.props.match.params.view),
            chairmanReport: [],
            voteSheet: []
        }
        this.setView = this.setView.bind(this);
        this.toggleMeetingStatus = this.toggleMeetingStatus.bind(this);
    }

    setView(view) {
        this.setState({ view, subformIsLoaded: false }, () => {
            if (this.state.view === CHAIRMANS_REPORT && this.state.chairmanReport.length === 0) {
                let chairmanReport = [...this.state.chairmanReport];
                this.props.actions.getChairReport(this.state.minutesBook.MinutesBookID).then(() => {
                    chairmanReport = this.props.minutes.chairReport.ChairmansCategory;
                    this.setState({ chairmanReport, subformIsLoaded: true })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.error(err);
                    this.setState({ subformIsLoaded: true })
                });
            } else if (this.state.view === VOTE_SHEET && this.state.voteSheet.length === 0) {
                let voteSheet = [...this.state.voteSheet];
                this.state.minutesBook.MinutesCategories.forEach(category => {
                    category.MinutesEntries.forEach(entry => {
                        entry.MinutesActivities.filter(ma => !ma.DeletionDate).forEach(activity => {
                            if (activity.VoteID) {
                                voteSheet.push({
                                    LegislationNumber: entry.LegislationNumber,
                                    LegislationID: entry.LegislationID,
                                    VoteID: activity.VoteID
                                });
                            }
                        });
                    });
                });

                if (voteSheet.length) {
                    this.getVoteSheetData(voteSheet).then((res) => {
                        this.setState({ voteSheet: res || [], subformIsLoaded: true })
                    });
                } else {
                    this.setState({ voteSheet: [], subformIsLoaded: true })
                }
            } else {
                this.setState({ subformIsLoaded: true })
            }
        });
    }

    getBillDetailData(voteSheet) {
        return this.props.actions.getBillListByIds({ "LegislationIds": voteSheet.map(v => ({ LegislationID: v.LegislationID, SessionID: this.state.minutesBook.SessionID })) });
    }

    getVoteDetailData(voteSheet) {
        return this.props.actions.getVoteDetails('?' + voteSheet.map((v, i) => 'voteIDs=' + v.VoteID + (i !== voteSheet.length - 1 ? '&' : '')).join(''), true);
    }

    async getVoteSheetData(voteSheet) {
        return await Promise.all([this.getBillDetailData(voteSheet), this.getVoteDetailData(voteSheet)]).then(() => {
            const voteData = this.props.votes.voteDetailsResponse;
            voteData.forEach(vote => {
                const voteIndex = voteSheet.findIndex(v => v.VoteID === vote.VoteID);
                let voteDetails = voteSheet[voteIndex];
                voteDetails = { ...vote, ...voteDetails };
                voteDetails.memberYeas = vote.VoteMember.filter(nObj => nObj.ResponseCode === 'Y');
                voteDetails.memberNays = vote.VoteMember.filter(nObj => nObj.ResponseCode === 'N');
                voteDetails.memberAbstentions = vote.VoteMember.filter(nObj => nObj.ResponseCode === 'A');
                voteDetails.memberNoVote = vote.VoteMember.filter(nObj => nObj.ResponseCode === 'X');
                voteDetails.voteStatements = vote.VoteMember.filter(member => member.VoteStatement);
                // Abstentions appear as rule 36 on senate floor tally sheets
                if (vote.ChamberCode === "S" && vote.VoteType === "Floor") {
                    voteDetails.memberRule36 = vote.VoteMember.filter(nObj => nObj.ResponseCode === 'A');
                } else {
                    voteDetails.memberRule36 = vote.VoteMember.filter(nObj => nObj.ResponseCode === 'R');
                }
                voteSheet[voteIndex] = voteDetails;
            });

            //if a vote didn't get returned in the request, fill out some of the data to prevent NREs
            voteSheet.filter(v => !voteData.find(vote => vote.VoteID === v.VoteID)).forEach(voteDetails => {
                voteDetails.memberYeas = [];
                voteDetails.memberNays = [];
                voteDetails.memberAbstentions = [];
                voteDetails.memberNoVote = [];
                voteDetails.voteStatements = [];
                voteDetails.memberRule36 = [];
            });

            const billData = [...this.props.bills.billListByIds];
            billData.forEach(bill => {
                voteSheet.forEach((v, i) => {
                    if (v.LegislationID === bill.LegislationID) {
                        voteSheet[i] = { ...v, ...bill };
                    }
                });
            });
            return voteSheet;
        }).catch(err => {
            console.error(err)
        });
    }

    toggleMeetingStatus() {
        let minutes = { ...this.state.minutesBook }
        if (minutes.MinutesStatusID === 4) {
            minutes.MinutesStatusID = 2;
        } else {
            minutes.MinutesStatusID = 4
        }

        this.props.actions.saveMinutesBook(minutes)
            .then(() => {
                const minutesBookId = this.props.match.params.meetingid;
                const committee = this.props.match.params.committeenumber;
                this.getMinutesBook(minutesBookId, committee);
            })
    }

    getMinutesBook(minutesBookId, committee) {
        this.props.actions.getMinutesBook('?minutesbookID=' + minutesBookId, (this.props.login.userClaims.roles.find(role => role === "SenateCommitteeReportReader") ? true : false))
            .then(() => {
                if (this.props.minutes.minutesBook) {
                    const minutesBook = { ...this.props.minutes.minutesBook }
                    this.setState({
                        minutesBook: minutesBook,
                    }, () => {
                        this.setView(this.state.view);
                        this.props.actions.getSessionById(this.state.minutesBook.SessionID)
                            .then(() => {
                                this.setState({
                                    session: this.props.session.selectedSession
                                }, () => {
                                    this.props.actions.getCommitteeByNumber("?committeeNumber=" + committee + "&effectiveDate=" + moment(this.state.minutesBook.MinutesDate).format("MM/DD/YYYY") + "&sessionId=" + this.props.session.selectedSession.SessionID)
                                        .then(() => {
                                            this.setState({
                                                committee: this.props.committee.committeeByNumberData,
                                                isLoaded: true
                                            }, () => {
                                                if (this.state.committee.ParentCommitteeID) {
                                                    const sessionStartObj = this.props.session.selectedSession.SessionEvents && this.props.session.selectedSession.SessionEvents.length && this.props.session.selectedSession.SessionEvents.find(date => date.DisplayName === "Session Start");
                                                    const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate).format("MM/DD/YYYY") : '';
                                                    this.props.actions.getCommitteeById("?id=" + this.state.committee.ParentCommitteeID + "&effectiveDate=" + moment(startDate).format("MM/DD/YYYY") + "&sessionId=" + this.props.session.selectedSession.SessionID)
                                                        .then(() => {
                                                            this.setState({
                                                                parentCommittee: this.props.committee.committeeByIdData
                                                            })
                                                        })
                                                }
                                            });
                                        });
                                });
                            });
                    });
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
            });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const minutesBookId = this.props.match.params.meetingid;
        const committee = this.props.match.params.committeenumber;
        this.getMinutesBook(minutesBookId, committee)
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        if (this.state.isLoaded) {
            const { chairmanReport, voteSheet } = this.state;
            // Chairman reports are a formatted minutes book that only contains entries that have been completed.
            // The organization is how they were completed (e.g. Reported Without Amendment) followed by the list of bills that share the same completion type
            // An example:
            /* 
            REPORTED WITHOUT AMENDMENT:
            SB44, SB112, SB186, SB238 
            */
            const sessionHeader = this.state.session.SessionYear + " " + this.state.session.DisplayName;
            //Find the minutes calendar if it exists
            let calendar = '';
            if (this.state.minutesBook.MinutesCalendars && this.state.minutesBook.MinutesCalendars.length > 0) {
                calendar = this.state.minutesBook.MinutesCalendars[0].CalendarID;
            }
            return (<div className='generic-details-page meetings-details-page'>
                <div className="flex-row ">
                    <br />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr 1fr 1fr 1fr 1fr", marginTop: "10px" }}>
                        {this.props.login.userClaims.roles.find(role => role === "SenateCommitteeReportReader") ?
                            <React.Fragment>
                                <button type="button" className="button primary" onClick={() => this.toggleMeetingStatus()}>{(this.state.minutesBook.MinutesStatusID === 4 ? "Open" : "Close") + " Meeting"}</button>
                                <label style={{ marginLeft: "10px" }}>Meeting Status: <span className={this.state.minutesBook.MinutesStatusID === 4 ? "fail" : "txt-green"}>{this.state.minutesBook.MinutesStatusID === 4 ? "Closed" : "Open"}</span></label>
                                <button type="button" className="button meetings-page-toggle" aria-current={this.state.view === MEETING} onClick={() => this.setView(MEETING)}>Meeting View</button>
                                <button type="button" className="button meetings-page-toggle" aria-current={this.state.view === CHAIRMANS_REPORT} onClick={() => this.setView(CHAIRMANS_REPORT)}>Chair's Report</button>
                                {this.state.committee.ChamberCode === "S" && <button type="button" className="button meetings-page-toggle" aria-current={this.state.view === VOTE_SHEET} onClick={() => this.setView(VOTE_SHEET)}>Vote Sheet</button>}
                            </React.Fragment>
                            : <>
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                            </>}
                        <ReactToPrint
                            trigger={() => <button type="button" className="button print"> Print</button>}
                            content={() => this.componentRef}
                        />
                    </div>
                </div>
                {this.state.subformIsLoaded ?
                    <div className="bill-theme public-details inner-grid" ref={el => (this.componentRef = el)}>
                        {this.state.view === MEETING &&
                            <div>
                                <div>
                                    <h2>{sessionHeader}</h2>
                                </div>
                                <div>
                                    <h3>{(
                                        this.state.committee.ChamberCode === "H"
                                            ? "House Committee "
                                            : "Senate Committee ") +
                                        (this.state.parentCommittee
                                            ? this.state.parentCommittee.Name === "Judiciary"
                                                ? "on the " + this.state.parentCommittee.Name
                                                : this.state.parentCommittee.Name === "Courts of Justice"
                                                    ? "for " + this.state.parentCommittee.Name
                                                    : "on " + this.state.parentCommittee.Name
                                            : this.state.committee.Name
                                                ? this.state.committee.Name === "Judiciary"
                                                    ? "on the " + this.state.committee.Name
                                                    : this.state.committee.Name === "Courts of Justice"
                                                        ? "for " + this.state.committee.Name
                                                        : "on " + this.state.committee.Name
                                                : this.state.minutesBook.CommitteeName === "Judiciary"
                                                    ? "on the " + this.state.minutesBook.CommitteeName
                                                    : this.state.minutesBook.CommitteeName === "Courts of Justice"
                                                        ? "for " + this.state.minutesBook.CommitteeName
                                                        : "on " + this.state.minutesBook.CommitteeName
                                        )
                                    }</h3>
                                    {this.state.parentCommittee &&
                                        <h3>{"Subcommittee on " + this.state.committee.Name}</h3>
                                    }
                                </div>
                                <hr />
                                <div>
                                    <div className="parent-li">
                                        <h4>
                                            {moment(this.state.minutesBook.MinutesDate).format("MMMM D[,] YYYY")}
                                            {calendar && <Link to={`/session-details/${this.props.session.selectedSession.SessionCode}/committee-information/${this.state.committee.CommitteeNumber}/dockets/${calendar}`} style={{ marginLeft: "1em" }}>View Docket</Link>}
                                        </h4>
                                    </div>
                                </div>
                                <br />
                                {this.state.minutesBook.MinutesCategories.map((category, catIndex) =>
                                    <div key={catIndex}>
                                        {category.MinutesEntries && category.MinutesEntries.map((entry, entIndex) =>
                                            <Entry key={entIndex} entry={entry} session={this.state.session} />
                                        )}
                                    </div>
                                )}
                            </div>
                        }
                        {this.state.view === CHAIRMANS_REPORT &&
                            <React.Fragment>
                                <br />
                                <div>
                                    <span style={{ float: "right", fontSize: '20px' }}>{moment(this.state.minutesBook.MinutesDate).format("MMMM D[,] YYYY")}</span>
                                </div>
                                <div className="center">
                                    <span className="chair-report-header">Chair's Report</span>
                                </div>
                                <div style={{ margin: "15px 0 25px", fontSize: '20px' }}>
                                    <span>The committee on </span> <b>{this.state.committee ? this.state.committee.Name : this.state.minutesBook.CommitteeName}</b> <span>reports the following:</span>
                                </div>
                                <div>
                                    {chairmanReport.sort((a, b) => a.Sequence - b.Sequence).map((action, actionIndex) =>
                                        <div key={actionIndex}>
                                            <div>
                                                <p className="chair-report-category">{action.CategoryDescription && action.CategoryDescription.endsWith(':') ? action.CategoryDescription.toUpperCase() : action.CategoryDescription && action.CategoryDescription.toUpperCase() + ":"}</p>
                                            </div>
                                            <p className="chair-report-bills">
                                                {action.ChairmansLegislation.map((bill, billIndex) => {
                                                    let formattedBill = !bill.ReferToCommitteeName && billIndex != action.ChairmansLegislation.length - 1 ? bill.LegislationNumber + ", " : bill.LegislationNumber
                                                    return (
                                                        <React.Fragment>
                                                            <b key={billIndex}>{formattedBill}{bill.ReferToCommitteeName && (" to Committee " + (bill.ReferToCommitteeName === "Judiciary" ? "on the " : bill.ReferToCommitteeName === "Courts of Justice" ? "for " : "on ") + bill.ReferToCommitteeName)}</b>
                                                            {bill.ReferToCommitteeName && <br />}
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </p>
                                            <br />
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        }
                        {this.state.view === VOTE_SHEET &&
                            <div id="vote-sheet-container">
                                {voteSheet.map((action, actionIndex) => {
                                    let legNumber = action.LegislationNumber;
                                    //Add dots in between the characters because that is how they want it displayed
                                    const billType = legNumber.slice(0, 2);
                                    const numbers = legNumber.slice(2);
                                    legNumber = billType.split('').map(char => {
                                        char += '.'
                                        // Senate Joint and House Joint Resolutions are displayed as 'H.J.R.' and 'S.J.R.' on this page rather than 'HJ' and 'SJ'
                                        // If the bill has a 'J' in the bill number then it is a joint resolution
                                        if (char === 'J.') {
                                            char += 'R.'
                                        }
                                        return char;
                                    }).join('');
                                    legNumber += ' ' + numbers;

                                    return (
                                        <div key={actionIndex}>
                                            <br />
                                            <div>
                                                <p className="vote-sheet-header">
                                                    <span>{this.state.session.SessionYear} - {this.state.session.SessionCode.substring(4)}</span>
                                                    <span>Senate of Virginia  - Committee Voting System</span>
                                                    <span>{moment().format("MM/DD/YY HH:mm")}</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p>Committee on {this.state.committee ? this.state.committee.Name : this.state.minutesBook.CommitteeName}</p>
                                            </div>
                                            <div>
                                                <p><b>{legNumber.toUpperCase()}</b><span className="ldtitle">{striptags(action.LegislationTitle)}</span></p>
                                            </div>
                                            {action.Patrons &&
                                                <p>{action.Patrons.length > 1 ? "Patrons--" : "Patron--"}
                                                    {action.Patrons.map((patron, patronIndex) => {
                                                        let patronString = '';
                                                        if (action.Patrons[patronIndex + 1] && action.Patrons[patronIndex + 1].ChamberCode !== action.ChamberCode && action.Patrons[patronIndex].ChamberCode === action.ChamberCode) {
                                                            patronString = (patronIndex !== 0 ? " and " : "") + patron.PatronDisplayName + (action.ChamberCode === "H" ? patronIndex + 2 === action.Patrons.length ? "; Senator: " : "; Senators: " : patronIndex + 2 === action.Patrons.length ? "; Delegate: " : "; Delegates: ");
                                                        } else if (patronIndex + 1 === action.Patrons.length && action.Patrons.length > 1 && action.Patrons[patronIndex].ChamberCode === action.Patrons[patronIndex - 1].ChamberCode) {
                                                            patronString = " and " + patron.PatronDisplayName;
                                                        } else if (patronIndex != 0 && action.Patrons[patronIndex - 1].ChamberCode === action.Patrons[patronIndex].ChamberCode) {
                                                            patronString = ", " + patron.PatronDisplayName;
                                                        } else {
                                                            patronString = patron.PatronDisplayName
                                                        }
                                                        return (<React.Fragment key={patronIndex}>{patronString}{patron.ByRequest ? ' (By Request)' : null}</React.Fragment>)
                                                    })}
                                                </p>
                                            }
                                            <p><b>{action.LegislationActionDescription}</b></p>
                                            <p>
                                                YEAS--
                                                {action.memberYeas && action.memberYeas.map((item, index) => (
                                                    <span key={index}>{item.PatronDisplayName}{action.memberYeas.length === index + 1 ? '--' : ', '}</span>
                                                ))}
                                                {action.memberYeas && action.memberYeas.length}.
                                            </p>
                                            <p>
                                                NAYS--
                                                {action.memberNays && action.memberNays.map((item, index) => (
                                                    <span key={index}>{item.PatronDisplayName}{action.memberNays.length === index + 1 ? '--' : ', '}</span>
                                                ))}
                                                {action.memberNays && action.memberNays.length}.
                                            </p>
                                            <p>
                                                {(action.VoteType === "Floor" && action.ChamberCode === "S") ? "RULE 36--" : "ABSTENTIONS--"}
                                                {action.memberAbstentions && action.memberAbstentions.map((item, index) => (
                                                    <span key={index}>{item.PatronDisplayName}{action.memberAbstentions.length === index + 1 ? '--' : ', '}</span>
                                                ))}
                                                {action.memberAbstentions && action.memberAbstentions.length}.
                                            </p>
                                            <p>(vote sequence: {action.VoteNumber}, {moment(action.VoteDate).format("MM/DD/YY HH.mm")})</p>
                                            <br />
                                            <footer />
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    :
                    <LoaderComponent data={this.state.subformIsLoaded} />
                }
            </div>
            );
        }
        else {
            return (<LoaderComponent data={this.state.isLoaded} />);
        }
    }
}

export default connect(
    (state) => {
        const { bills, committee, session, minutes, login, votes } = state;
        return {
            bills,
            committee,
            session,
            minutes,
            login,
            votes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, committeeActionCreators, sessionActionCreators, minutesActionCreators, authActionCreators, voteActionCreators), dispatch)
        }
    }
)(PublicMeetingDetails)