import React from 'react';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const RESET = 'RESET';

const getItemStyle = (isDragging, draggableStyle, isHeader) => ({
    userSelect: "none",
    padding: '5px',
    border: '1px solid #CCC',
    margin: '0',
    fontSize: isHeader ? '.95em' : '.9em',
    background: isDragging ? "#CCC" : "#FFF",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f5f5" : "#f5f5f5",
    padding: '0'
});

const selectStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: '0px 8px'
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: '0px 8px'
    }),
    singleValue: (base, state) => {
        return { ...base, };
    },
    menu: (base) => ({
        ...base,
        boxShadow: 'none'
    }),
    menuList: (base) => ({
        ...base,
        background: 'white',
        border: '1px solid gray',
        borderRadius: '5px'
    })
};

export default class UpdatePatronageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPatronTypeChangeWarning: {}
        }

        this.handlePatronTypeChange = this.handlePatronTypeChange.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    handlePatronTypeChange(row, val, confirm) {
        const patronDisplayName = row.PatronDisplayName;
        if (val && val.Name === "Chief Co-Patron" && this.props.patronList.filter(p => !p.RemoveDate && p.Name === "Chief Co-Patron").length === 4) {
            this.setState({
                showPatronTypeChangeWarning: {
                    ...this.state.showPatronTypeChangeWarning,
                    [patronDisplayName]: !confirm
                }
            })
            if (!confirm) {
                return;
            }
        } else if (val === RESET) {
            this.setState({
                showPatronTypeChangeWarning: {
                    ...this.state.showPatronTypeChangeWarning,
                    [patronDisplayName]: false
                }
            })
            return;
        }
        this.props.handlePatronChange(row, 'PatronType', val)
    }

    cancel() {
        this.props.history.goBack();
    }

    render() {
        const { billText, patronList, memberList, patronTypes, newPatron } = this.props;
        const { showPatronTypeChangeWarning } = this.state;
        const showConfirmButton = newPatron?.length && !newPatron.find(p => !p.MemberID) && !newPatron.find(p => !memberList.find(m => m.MemberID === p.MemberID)) && !newPatron.find(p => !patronTypes.find(pt => pt.Name === p.Name)) && [...newPatron].filter(p => p.Name === "Chief Co-Patron").concat(patronList.filter(p => !p.RemoveDate && p.Name === "Chief Co-Patron")).length > 4;
        return (
            <div>
                <div style={{ margin: '10px' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <h2 style={{ marginBottom: '0px' }}>Update Patrons ({billText.LegislationNumber})</h2>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'max-content 350px 180px 100px', alignItems: 'center' }}>
                            <span style={{ fontSize: '.85em' }}>New Patron(s): </span>
                            <Select
                                isMulti
                                options={memberList ? memberList.filter(m => !patronList.map(p => p.MemberID).includes(m.MemberID)) : []}
                                onChange={(val, behavior) => this.props.handleNewPatronChange('Patron', val, behavior)}
                                getOptionValue={(opt) => opt.MemberID}
                                getOptionLabel={(opt) => memberList.find(m => m.MemberID === opt.MemberID)?.label}
                                value={[...newPatron].filter(p => p.MemberID)}
                                styles={selectStyles}
                            />
                            <Select
                                options={patronTypes ? patronTypes.filter(pt => pt.Name !== "Incorporated Chief Co-Patron" || !newPatron.filter(p => p.ChamberCode !== "S")?.length) : []}
                                getOptionLabel={opt => opt.Name}
                                getOptionValue={opt => opt.PatronTypeID}
                                onChange={(val) => this.props.handleNewPatronChange('PatronType', val)}
                                value={newPatron[0].Name ? patronTypes.find(pt => pt.Name === newPatron[0].Name) : null}
                                styles={selectStyles}
                            />
                            {!showConfirmButton &&
                                <button disabled={!newPatron?.length || !newPatron[0].Name || !newPatron[0].MemberID || newPatron.find(p => !memberList.find(m => m.MemberID === p.MemberID)) || newPatron.find(p => !patronTypes.find(pt => pt.Name === p.Name))} className="button primary" onClick={this.props.addNewPatron}>Add</button>
                            }
                        </div>
                        {showConfirmButton &&
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <span className="input-feedback-warning" style={{ fontSize: '13px', margin: 'auto 0px' }}>Caution: There can only be a maximum of 4 Chief Co-Patrons, which this legislation will now exceed. Continue anyway?</span>
                                <button className="button primary" onClick={this.props.addNewPatron}>Yes</button>
                                <button className="button secondary" onClick={() => this.props.handleNewPatronChange('PatronType', null)}>No</button>
                            </div>
                        }
                    </div>
                    <div style={getItemStyle(false, null, true)} className='inner-grid toggle-and-small-one-med-half-half-small'>
                        <div />
                        <div>#</div>
                        <div>Display Name</div>
                        <div style={{ margin: 'auto' }}>Patron Type</div>
                        <div style={{ margin: 'auto' }}>By Request</div>
                        <div style={{ margin: 'auto' }}>Is Introducing</div>
                        <div style={{ margin: 'auto' }}>Actions</div>
                    </div>
                    <DragDropContext onDragEnd={result => this.props.onDragEnd(result)}>
                        <Droppable droppableId={"hp"} index={0}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {patronList.map((row, j) => (
                                        <Draggable isDragDisabled={Boolean(row.RemoveDate)} key={row.MemberID + "-" + row.LegislationID} draggableId={row.MemberID.toString()} index={j}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div style={{ alignItems: 'center', color: row.RemoveDate ? '#8b8b8b' : '' }} className='inner-grid toggle-and-small-one-med-half-half-small'>
                                                        <div><button className="button draggable">Drag and drop</button></div>
                                                        <div>{!row.RemoveDate ? row.Sequence : ""}</div>
                                                        <div>{row.PatronDisplayName + " (" + row.ChamberCode + ")"}</div>
                                                        <div>
                                                            <Select
                                                                options={patronTypes ? patronTypes.filter(pt => pt.Name !== "Incorporated Chief Co-Patron" || row.Name === "Incorporated Chief Co-Patron" || row.ChamberCode === "S") : []}
                                                                getOptionLabel={opt => opt.Name}
                                                                getOptionValue={opt => opt.PatronTypeID}
                                                                onChange={(val) => this.handlePatronTypeChange(row, val)}
                                                                value={patronTypes.find(p => p.Name === row.Name)}
                                                                isDisabled={row.RemoveDate}
                                                                styles={selectStyles}
                                                            />
                                                        </div>
                                                        <div style={{ margin: 'auto' }} disabled={row.RemoveDate} onClick={(val) => this.props.handlePatronChange(row, 'ByRequest')} className="toggle-switch">
                                                            <input id="sr-status-change" checked={row.ByRequest} type="checkbox" />
                                                            <span className="slider"></span>
                                                        </div>
                                                        <div style={{ margin: 'auto' }} disabled={row.RemoveDate} onClick={() => this.props.handlePatronChange(row, 'IsIntroducing')} className="toggle-switch">
                                                            <input id="sr-status-change" checked={row.IsIntroducing} type="checkbox" />
                                                            <span className="slider"></span>
                                                        </div>
                                                        <div style={{ margin: 'auto', display: 'flex' }}><button onClick={() => this.props.togglePatron(row)} className={`icon ${row.RemoveDate ? 'add' : 'delete'}`} type="button" /></div>
                                                    </div>
                                                    {showPatronTypeChangeWarning[row.PatronDisplayName] &&
                                                        <div style={{ display: 'flex', gap: '8px' }}>
                                                            <span className="input-feedback-warning" style={{ fontSize: '13px', margin: 'auto 0px' }}>Caution: There can only be a maximum of 4 Chief Co-Patrons, which this legislation already has. Continue anyway?</span>
                                                            <button className="button primary" onClick={() => this.handlePatronTypeChange(row, patronTypes.find(p => p.Name === "Chief Co-Patron"), true)}>Yes</button>
                                                            <button className="button secondary" onClick={() => this.handlePatronTypeChange(row, RESET)}>No</button>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <div className="inline-list align-right" style={{ paddingBottom: '55px' }}>
                    {!this.props.savingPatrons && <button className="button secondary" onClick={this.cancel}>Cancel</button>}
                    <button disabled={this.props.savingPatrons || showConfirmButton || Object.keys(showPatronTypeChangeWarning).find(key => showPatronTypeChangeWarning[key] === true)} className="button primary" onClick={this.props.updatePatrons}>{this.props.savingPatrons ? <div className="small-spinner" /> : 'Update'}</button>
                </div>
            </div>
        )
    }
}