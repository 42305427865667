import React from 'react';

import moment from 'moment-timezone';
import { AgendaActivities } from './lis-calendar-details';

const CalendarDetailsPrintout = props => {
    const { session, calendar, comments } = props;
    const filteredCategories = calendar.CalendarCategories.map(category => {
        const checkForActiveAgendas = category.Agendas.find(agenda => agenda.IsActive && !agenda.IsHidden);
        if (checkForActiveAgendas) {
            return category;
        } else {
            return null;
        }
    });
    const categoryDescription = category => {
        if (category.Agendas.filter(a => a.IsActive).length > 1 && category.PluralDescription) {
            return category.PluralDescription;
        } else {
            return category.Description;
        }
    }
    const sessionHeader = session ? session.SessionYear + " " + session.DisplayName : '';

    return (
        <div>
            <br />
            <div className="flex-row flex-vertical-center multiple-icons">
                <h2 className="no-margin">{sessionHeader}</h2>
            </div>
            <hr />
            <div className="flex-row">
                <div>
                    <h3 className="no-margin">{calendar.ChamberCode === "S" ? "Senate " : "House "} Calendar {calendar.IsProforma && "(Pro Forma)"}</h3>
                    <span>{moment(calendar.CalendarDate).format("MMMM Do, YYYY") + (calendar.CalendarNumber > 1 ? [' (', ')'].join('Supp. ' + (calendar.CalendarNumber - 1)) : '')}</span>
                </div>
                <div>
                </div>
            </div>
            {comments.length > 0 &&
                <div className="public-details">
                    {comments.map((comment, i) => {
                        return (
                            <p key={i}>{"Comment " + comment.Sequence + ": " + comment.Comment}</p>
                        )
                    })}
                </div>
            }
            <br />
            <div className="collapsible-table">
                {filteredCategories.map((category, catIndex) => {
                    if (category) {
                        return (<div key={catIndex}>
                            <div>
                                <div>
                                    <span>
                                        {categoryDescription(category)}
                                        {category.DisplayType && " - " + category.CategoryType}
                                    </span>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className="section-body">
                                {category.Agendas.map((agenda, agendaIndex) =>
                                    <React.Fragment key={agendaIndex}>
                                        {agenda.IsActive && !agenda.IsHidden &&
                                            <React.Fragment>
                                                <div>
                                                    <div>
                                                        <span>{agenda.LegislationNumber}</span>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span>{agenda.LegislationDescription || agenda.Description}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <AgendaActivities
                                                            agenda={agenda}
                                                            chamberCode={calendar.ChamberCode}
                                                            sessionCode={calendar.SessionCode}
                                                            agendaNode={agenda.AgendaNote}
                                                        />
                                                    </div>
                                                    <div>
                                                        {agenda.AgendaItems.map((x) => {
                                                            if (x.Description && x.Description.includes("Amendment")) {
                                                                return <div dangerouslySetInnerHTML={{ __html: x.DraftText }} />
                                                            }
                                                        })}

                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                        </div>)
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>);
}

export default CalendarDetailsPrintout;
