import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import '../../../stylesheets/lis-public-view/public-view.css';
import moment from 'moment-timezone';

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            selectedMember: '',
            selectedSubject: '',
            selectedDate: null
        };
        this.handleMemberDropdownChange = this.handleMemberDropdownChange.bind(this);
        this.handleSubjectDropdownChange = this.handleSubjectDropdownChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleMemberDropdownChange(val) {
        this.setState({
            selectedMember: val
        }, () => {
            //The next step is done after the state change to make sure the user sees what member was selected before going to the advanced search page
            let param = {
                selectedPatron: this.state.selectedMember.MemberID,
                selectedSession: this.props.sessionList.find(s => s.SessionCode === this.props.session),
                chamberCode: this.state.selectedMember.ChamberCode,
                selectedPatronType: [1]
            }

            const link = window.btoa(JSON.stringify(param));
            this.props.history.push('/bill-search?q=' + link);

        });
    }

    handleSubjectDropdownChange(val) {
        this.setState({
            selectedSubject: val
        }, () => {
            //The next step is done after the state change to make sure the user sees what member was selected before going to the advanced search page
            const param = {
                selectedSubject: this.state.selectedSubject.SubjectIndexID,
                selectedSession: this.props.sessionList.find(s => s.SessionCode === this.props.session),
            }
            const link = window.btoa(JSON.stringify(param));
            this.props.history.push('/bill-search?q=' + link);
        });
    }

    handleDateChange(val) {
        this.setState({
            selectedDate: val
        }, () => {
            const introDate = this.state.selectedDate.clone();
            introDate.hour(0);
            const param = {
                introductionDate: introDate
            };
            const link = window.btoa(JSON.stringify(param));
            this.props.history.push('/bill-search?q=' + link);
        });
    }

    render() {
        const customStyles = {
            container: base => ({
                ...base,
                lineHeight: 'normal',
            }),
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: '0px 8px'
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: "none"
            })
        };
        const memberList = [...this.props.memberList]
        memberList.forEach((member) => {
            member.label = "(" + member.ChamberCode + ") " + member.ListDisplayName;
            member.value = member.MemberID;
        });
        let datesList = [];
        this.props.datesList.forEach(date => datesList.push(moment(date.IntroductionDate)));
        datesList.push(moment());
        const openToDate = datesList[datesList.length - 1];
        return (
            <div className="dlas-forms home-search-section">
                <br />
                <div id="search-selectors">
                    <div className="selector">
                        <div className="subject">
                            <label htmlFor="bills-by-subject" className="search-option-label">Bills by Subject</label>
                        </div>
                        <div>
                            <Select
                                id="bills-by-subject"
                                styles={customStyles}
                                isDisabled={!this.props.isLoaded}
                                options={this.props.subjectList}
                                value={this.state.selectedSubject}
                                onChange={this.handleSubjectDropdownChange}
                            />
                        </div>
                    </div>
                    <div className="selector">
                        <div className="member">
                            <label htmlFor="bills-by-member" className="search-option-label">Bills by Member</label>
                        </div>
                        <div>
                            <Select
                                id="bills-by-member"
                                styles={customStyles}
                                isDisabled={!this.props.isLoaded}
                                options={memberList}
                                onChange={this.handleMemberDropdownChange}
                                value={this.state.selectedMember}
                            />
                        </div>
                    </div>
                    <div className="selector">
                        <div className="introduced">
                            <label htmlFor="bills-by-date" className="search-option-label">Bills Introduced by Day</label>
                        </div>
                        <div>
                            <DatePicker
                                id="bills-by-date"
                                disabled={!this.props.isLoaded || !openToDate}
                                selected={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                includeDates={datesList}
                                openToDate={openToDate}
                            />
                        </div>
                    </div>
                </div>
                <hr />
            </div>

        )
    }
}

export default Search