import React from 'react';
import Popout from '../lis-layout/components/lis-popout-component';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default'
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/table/plugin';
import '../../stylesheets/tinymce/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const newAgendaItemText = '<p><strong>&#xFEFF;</strong></p>';

const getItemStyle = (isDragging, draggableStyle, isActive) => ({
    // some basic styles to make the items look a bit nicer
    display: 'flex',
    userSelect: 'none',
    marginBottom: '20px',
    // change background colour if dragging
    background: isDragging ? '#34495e' : !isActive ? '#f3f3f3' : 'white',
    color: isDragging ? 'white' : '#34495e',
    borderRight: '1px solid #e0e0e0',
    borderTop: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    color: isActive ? '' : '#8b8b8b',
    // styles we need to apply on draggables
    ...draggableStyle,
});

class AgendaItemsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agendaItemContent: []
        }

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.togglePortal = this.togglePortal.bind(this);
        this.saveAgendaItems = this.saveAgendaItems.bind(this);
        this.close = this.close.bind(this);
        this.addNote = this.addNote.bind(this);
        this.toggleCustomNote = this.toggleCustomNote.bind(this);
    }

    addNote() {
        let agendaItems = [...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence);
        //insert the new note at the last index excluding amendments (keep amendments last)
        let index = agendaItems.findIndex(a => a.DraftText);
        if (index !== -1) {
            //there is at least one amendment, increase its display sequence because the new note will be in its previous sequence/indexc
            agendaItems.forEach((item, idx) => {
                if (idx >= index) {
                    item.DisplaySequence++;
                }
            })
        }

        const newAgendaItem = {
            Description: newAgendaItemText,
            IsActive: true,
            Sequence: (index + 1) || agendaItems.length + 1,
            DisplaySequence: (index + 1) || agendaItems.length + 1,
            AgendaId: agendaItems && agendaItems[0] ? agendaItems[0].AgendaId : null
        }
        agendaItems.splice(index !== -1 ? index : agendaItems.length + 1, 0, newAgendaItem);
        let agendaItemContent = [...this.state.agendaItemContent];
        agendaItemContent.splice(index !== -1 ? index : agendaItems.length + 1, 0, newAgendaItem.Description);
        this.props.updateAgendaItems(agendaItems, this.props.categoryIndex, this.props.agendaIndex);
        this.setState({
            agendaItemContent: agendaItemContent
        });
    }

    handleEditorChange(content, itemIndex) {
        let agendaItemContent = [...this.state.agendaItemContent];
        agendaItemContent[itemIndex] = content;
        this.setState({
            agendaItemContent: agendaItemContent
        });
    }

    togglePortal(state) {
        this.setState({
            showPortal: !state
        }, () => {
            if (!this.state.showPortal) {
                this.props.toggleAgendaItemEditor(-1)
            }
        });
    }

    saveAgendaItems() {
        let agendaItems = [...this.props.agenda.AgendaItems];
        agendaItems.forEach((item, i) => {
            if (item.DraftText) {
                item.DraftText = this.state.agendaItemContent[i];
            } else if (item.CalendarDescription) {
                item.CalendarDescription = this.state.agendaItemContent[i];
            } else {
                item.Description = this.state.agendaItemContent[i];
            }
            item.Sequence = item.DisplaySequence;
        });
        this.props.updateAgendaItems(agendaItems, this.props.categoryIndex, this.props.agendaIndex);
        this.props.toggleAgendaItemEditor(-1);
    }

    close() {
        let agendaItems = [...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence);
        //filter out newly added items since they are cancelling, and undo re-sequencing
        agendaItems = agendaItems.filter((item) => {
            if (!item.Description || item.Description === newAgendaItemText) {
                return false;
            } else {
                return true;
            }
        })
        agendaItems.forEach(item => {
            item.IsActive = item.OrigIsActive;
            item.DisplaySequence = null;
        });
        const sortedAgendaItems = agendaItems.sort((a, b) => a.Sequence - b.Sequence);
        this.props.updateAgendaItems(sortedAgendaItems, this.props.categoryIndex, this.props.agendaIndex);
        this.props.toggleAgendaItemEditor(-1)
    }

    componentDidMount() {
        let agendaItems = [...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence);
        let agendaItemContent = [];
        this.props.updateAgendaItems(agendaItems, this.props.categoryIndex, this.props.agendaIndex);
        agendaItems.forEach((item, idx) => {
            item.DisplaySequence = idx + 1;
            item.OrigIsActive = item.IsActive;
            agendaItemContent.push(item.DraftText || item.CalendarDescription || item.Description);
        });
        this.setState({
            agendaItemContent: agendaItemContent
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.agenda.AgendaItems !== prevProps.agenda.AgendaItems) {
            let agendaItemContent = [];
            this.props.agenda.AgendaItems.sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence).forEach(item => {
                agendaItemContent.push(item.DraftText || item.CalendarDescription || item.Description);
            });
            this.setState({
                agendaItemContent: agendaItemContent
            });
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let newAgendaItemContent = [];
        let agendaItemContent = [...this.state.agendaItemContent];
        let agendaItems = [...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence);
        if (agendaItems.find(a => a.DraftText) && result.destination.index >= agendaItems.findIndex(a => a.DraftText)) {
            alert("You cannot reposition amendments.");
            return;
        }
        let [removedItemContent] = agendaItemContent.splice(result.source.index, 1);
        let [removedItem] = agendaItems.splice(result.source.index, 1);
        agendaItemContent.splice(result.destination.index, 0, removedItemContent);
        agendaItems.splice(result.destination.index, 0, removedItem);
        //Ensure amendments are still last, and reorder the Sequence value too!
        agendaItems.sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence)
        agendaItems.forEach((element, index) => {
            element.DisplaySequence = index + 1;
            newAgendaItemContent.push(agendaItemContent[index]);
        });
        this.props.updateAgendaItems(agendaItems, this.props.categoryIndex, this.props.agendaIndex);
        this.setState({ agendaItemContent: newAgendaItemContent });
    }

    toggleCustomNote(index) {
        if (index > -1) {
            let agendaItems = [...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence);
            agendaItems[index].IsActive = !agendaItems[index].IsActive;

            this.props.updateAgendaItems(agendaItems, this.props.categoryIndex, this.props.agendaIndex);

            const tinyMceEditor = document.querySelector('iframe#' + agendaItems[index].tinyMceId + '_ifr');
            if (tinyMceEditor) {
                const tinyMceHtmlElem = tinyMceEditor.contentWindow.document.querySelector('html');
                const tinyMceHeaderElem = document.getElementById(agendaItems[index].tinyMceId).parentElement.querySelector('.tox-toolbar__primary');
                if (tinyMceHtmlElem && tinyMceHeaderElem) {
                    tinyMceHtmlElem.style.background = agendaItems[index].IsActive ? 'white' : '#f3f3f3';
                    tinyMceHeaderElem.style.background = agendaItems[index].IsActive ? 'white' : '#f3f3f3';
                }
            }
        }
    }

    setTinyMceIds(tinymceId, index) {
        if (index > -1) {
            let agendaItems = [...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence);
            agendaItems[index].tinyMceId = tinymceId;
            this.props.updateAgendaItems(agendaItems, this.props.categoryIndex, this.props.agendaIndex);
            const tinyMceHeaderElem = document.getElementById(agendaItems[index].tinyMceId).parentElement.querySelector('.tox-toolbar__primary');
            if (tinyMceHeaderElem) {
                tinyMceHeaderElem.style.background = agendaItems[index].IsActive ? 'white' : '#f3f3f3';
            }
        }
    }

    render() {
        const editors =
            <DragDropContext onDragEnd={result => this.onDragEnd(result)}>
                <Droppable droppableId="droppable">
                    {(provided, _snapshot) => (
                        <div ref={provided.innerRef}>
                            {[...this.props.agenda.AgendaItems].sort((a, b) => (a.DraftText ? 1 : b.DraftText ? -1 : 0) || (a.DisplaySequence && b.DisplaySequence && a.DisplaySequence < b.DisplaySequence) || a.Sequence < b.Sequence).map((item, itemIndex) => {
                                if (this.state.agendaItemContent[itemIndex] || this.state.agendaItemContent[itemIndex] === "") {
                                    //Have to defer the render until there's actually conent to check
                                    //this.state.agendaItemContent[itemIndex] was coming through undefined on the first pass and we can't re-render with the new height
                                    let height = this.state.agendaItemContent[itemIndex].length <= 100 ? 80 : 180
                                    return (
                                        <Draggable isDragDisabled={Boolean(item.DraftText)} key={`${item.AgendaItemId}-${itemIndex}`} draggableId={`${item.AgendaItemId}-${itemIndex}`} index={itemIndex}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                        item.IsActive
                                                    )}
                                                >
                                                    {!item.DraftText && <div style={{ margin: 'auto' }}><button className="button draggable">Drag and drop</button></div>}
                                                    <div style={{ width: item.DraftText ? '100%' : '97%' }}>
                                                        <Editor
                                                            value={this.state.agendaItemContent[itemIndex] || ''}
                                                            disabled={!item.IsActive || item.DraftText}
                                                            onInit={(e) => this.setTinyMceIds(e.target.id, itemIndex)}
                                                            init={{
                                                                browser_spellcheck: true,
                                                                height: height,
                                                                menubar: false,
                                                                statusbar: false,
                                                                plugins: 'lists searchreplace',
                                                                toolbar: 'undo redo | bold italic strikethrough | alignleft aligncenter alignright',
                                                                skin: false,
                                                                valid_children: '+body[style]',
                                                                content_css: false,
                                                                content_style: (item.IsActive ? '' : 'html { background: #f3f3f3}') + 'table { border-collapse: collapse; border: 1px solid #FFFFFF }' + 'td { padding: 12px 0px }',
                                                            }}
                                                            onEditorChange={content => this.handleEditorChange(content, itemIndex)}
                                                        />
                                                    </div>
                                                    <div style={{ width: '3%', margin: 'auto', textAlign: 'center' }}>
                                                        <button className={`icon ${item.IsActive ? 'delete' : 'add'}`} onClick={() => this.toggleCustomNote(itemIndex)} />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }
                            })
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>;
        const buttons =
            <React.Fragment>
                <button type="button" onClick={this.addNote} className="button" style={!this.props.agenda.AgendaItems.length ? { marginTop: '20px' } : {}} >Add Note</button>
                <div className="flex-row">
                    <br />
                    <div className="inline-list">
                        <button type="button" onClick={this.close} className="button secondary">Cancel</button>
                        <button type="button" onClick={this.saveAgendaItems} className="button">Finish</button>
                    </div>
                </div>
            </React.Fragment>
        return (<div>
            {this.state.showPortal ?
                <Popout togglePortal={this.togglePortal} windowTitle={`${this.props.agenda.LegislationNumber} ${this.props.agenda.LegislationDescription}`}>
                    {editors}
                    {buttons}
                </Popout>
                :
                <div onClick={() => this.props.toggleAgendaItemEditor(-1)} className="overlay">
                    <div className="full">
                        <div onClick={e => e.stopPropagation()} className="dlas-forms user-forms advanced-search overlay-content animated-fade-in-up">
                            <div className="background-blue inline-list flex-row flex-vertical-center">
                                <span className="icon popout" onClick={() => this.togglePortal(this.state.showPortal)}></span>
                                <span className="txt-white">{this.props.agenda.LegislationNumber} {this.props.agenda.LegislationDescription}</span>
                            </div>
                            {editors}
                            {buttons}
                        </div>
                    </div>
                </div>}
        </div>);
    }
}

export default AgendaItemsComponent;
