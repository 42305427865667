import jwtDecode from 'jwt-decode';

const API_URL = window.env ? window.env.API_SERVER : '';
const idToken = 'LIS_ID_TOKEN';
const refreshToken = 'LIS_REFRESH_TOKEN';
const refreshTokenExpiration = 'LIS_REFRESH_TOKEN_EXPIRATION';
const claims = "LIS_CLAIMS";
const graphToken = 'GRAPH_ID_TOKEN';

const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

let abortController = new window.AbortController();
let signal = abortController ? abortController.signal : "";

export const cancelRequest = () => {
    if (abortController)
        abortController.abort();
}

//The only difference between publicRequest and partnerRequest is that partnerRequest checks to see if the token is valid and sends it with the fetch
export const publicRequest = (url, method, headers, body, params) => {
    let config = {
        method: method,
    }
    if (headers) {
        headers.WebAPIKey = process.env.REACT_APP_API_KEY;
        config.headers = headers;
    } else {
        config.headers = {
            WebAPIKey: process.env.REACT_APP_API_KEY
        }
    }
    if (body) {
        config.body = JSON.stringify(body)
    }
    if (signal && signal != "" && signal.aborted === true) {
        //Refresh the abortController because you can't reset the abort property of a signal
        abortController = new window.AbortController();
        signal = abortController ? abortController.signal : "";
    }
    if (signal && signal != "") {
        config.signal = signal;
    }
    return fetch(url + params, config)
        .then(response => {
            if (response.status === 204) {
                //Some endpoints do not return anything if there is no data to be returned. So NO_CONTENT must be specified as it is not always an error
                throw [NO_CONTENT, 'No Content'];
            } else if (response.status > 399) {
                //There was a request failure. Return the response. Do not try to convert it to a JSON object as there is no guarantee that it is formatted as a json
                return (response.text()
                    .then(text => {
                        console.log(text);
                        return Promise.reject([REQUEST_FAILURE, text])
                    })
                )
            }
            return response

        })
        .then(response =>
            //Our request is successful. Convert it to JSON
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [REQUEST_SUCCESS, data, response.headers]
        })
        .catch(err => err);
}

export const partnerRequest = (url, method, headers, body, params, nonAbortable) => {
    //Check the token first to see if it is valid
    return checkToken()
        .then(() => {
            //Just in case the method is initiated with a blank string as the headers
            if (!headers) {
                headers = {
                    'content-type': "application/json; charset=utf-8"
                }
            }
            headers.Authorization = "Bearer " + localStorage.getItem(idToken);
            headers.WebAPIKey = process.env.REACT_APP_API_KEY;
            let config = {
                method: method,
                headers: headers,
            }
            if (body) {
                if (body instanceof FormData) {
                    config.body = body
                } else {
                    config.body = JSON.stringify(body);
                }
            }
            if (signal && signal != "" && signal.aborted === true) {
                //Refresh the abortController because you can't reset the abort property of a signal
                abortController = new window.AbortController();
                signal = abortController ? abortController.signal : "";
            }
            if (signal && !nonAbortable) {
                config.signal = signal;
            }
            return fetch(url + params, config)
        })
        .then(response => {
            if (response.status === 204) {
                throw [NO_CONTENT, 'No Content'];
            } else if (response.status > 399) {
                return (response.text()
                    .then(text => {
                        return Promise.reject([REQUEST_FAILURE, text])
                    })
                )
            }
            return response

        })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [REQUEST_SUCCESS, data, response.headers]
        })
        .catch(err => err);

}

export const checkToken = () => {
    let token = localStorage.getItem(idToken);

    if (token !== null && token !== 'undefined') {
        const decode = jwtDecode(token);
        const stringToken = JSON.stringify(decode);
        const parsedToken = JSON.parse(stringToken);
        //If true then then expiration hasn't passed yet. So return a promise so partnerRequest can keep on running
        if (parsedToken.exp < new Date().getTime() / 1000) {
            return getNewToken(function (result) {
                return result
            })
        } else {
            return new Promise(function (resolve, reject) { resolve(true) });
        }
    } else {
        return Promise.reject([REQUEST_FAILURE, 'Error: No Token']);
    }
}

const getNewToken = (callback) => {
    let rtoken = localStorage.getItem(refreshToken);
    let config = {
        method: 'POST',
        headers: {
            "authorization": rtoken,
            "content-type": "application/json; charset=utf-8",
            "WebAPIKey": process.env.REACT_APP_API_KEY
        }
    }
    return fetch((API_URL || process.env.REACT_APP_AUTH_API_URL) + '/Authentication/api/RefreshAccessTokenAsync', config)
        .then(response => {
            if (response.status === 204) { return Promise.reject('Error: No Content') }
            else if (response.status === 401) {
                //The user's token is expired. Log them out
                localStorage.removeItem(idToken);
                localStorage.removeItem(refreshToken);
                localStorage.removeItem(refreshTokenExpiration);
                localStorage.removeItem(claims);
                localStorage.removeItem(graphToken);

                return Promise.reject("Error: Refresh Token Expired");
            }
            else if (response.status > 399) { return Promise.reject([REQUEST_FAILURE, response.text()]) }
            else { return response }
        })
        .then(response =>
            response.json().then(user => ({ user, response }))
        ).then(({ user, response }) => {
            // If refresh was successful, set the tokens in local storage
            let roles = [];
            if (user.Claims && user.Claims.Roles) {
                roles = user.Claims.Roles;
            }
            localStorage.setItem(claims, JSON.stringify(roles));
            localStorage.setItem(idToken, user.AccessToken);
            localStorage.setItem(refreshToken, user.RefreshToken);
            //604800000 is 7 days in milliseconds
            localStorage.setItem(refreshTokenExpiration, new Date().getTime() + 604800000)
            callback(0);
        }).catch(err => callback([REQUEST_FAILURE, Promise.reject(err)]))
}