import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';
import ReactTooltip from 'react-tooltip';

const VERSION_AUTHOR = "LegislationText";

function VersionData(props) {
    const [showText, toggleText] = useState(false);
    let impactFileUrls = [];
    let pdfLink = '';

    if (props.ImpactFile && props.ImpactFile.length > 0) {
        for (let i = 0; i < props.ImpactFile.length; i++) {
            impactFileUrls.push({
                url: props.ImpactFile[i].FileURL,
                description: props.ImpactFile[i].Description ? props.ImpactFile[i].Description.substr(0, props.ImpactFile[i].Description.indexOf("(")) : "Impact statement"
            });
        }
    }

    if (props.PDFFile && props.PDFFile.length > 0) {
        pdfLink = <a href={props.PDFFile[0].FileURL} target="_blank" className="link-pdf">PDF</a>
    }
    let descriptionAndDisposition = props.Description + (props.pushWithKeywords ? " " + props.DocumentCode : "");
    let substituteLdAndDocCodeString = "";
    // add (LD#-DocCodeSuffix) to the description (if not already there) if it is a substitute and does not have 'C' in the document code
    if (props.LegislationVersion === "Substitute" && props.LDNumber && !props.Description.includes(props.LDNumber.replace("D", "")) && props.DocumentCode && !props.DocumentCode.includes('C')) {
        const subDocCodeRegex = /[HS](?:.(?![HS]))+$/; //matches the last H/S in the string and everything after (e.g. H13 from HB202H13)
        substituteLdAndDocCodeString = " (" + props.LDNumber + "-" + props.DocumentCode.match(subDocCodeRegex) + ")";
    }
    // Versions that are held should not be linked to except for certain signed in users
    // Also the text disposition is hidden because they don't want public users to see that it is held
    // We do not need to worry about adding the 'keyword-hit-list' class if props.pushWithKeywords is true (like we do for other dispositions) since 'Hold' versions shouldn't be returned in the keyword search results
    if (props.TextDisposition === "Hold") {
        return (<div className="bill-details-versions-grid-container">
            <div className={`bill-details-versions-grid${!props.IsPublic ? ' not-public' : ''}`}>
                {/* Previously date */}
                <div>{props.ChamberCode && props.ChamberCode !== "" ? props.ChamberCode === "H" ? "House" : "Senate" : props.Sponsor}</div>
                <div>{props.userClaims.resources.find(resource => resource === VERSION_AUTHOR) ? <Link to={`/bill-details/${props.SessionCode || props.sessionCode}/${props.LegislationNumber}/text/${props.DocumentCode}`}>{props.Description}{substituteLdAndDocCodeString} (On Hold)</Link> : `${props.Description}`}</div>
            </div>
        </div>);
    } else if (props.TextDisposition !== "Posted") {
        descriptionAndDisposition = props.Description + " " + (props.TextDisposition && (props.TextDisposition !== "Offered" || props.ChamberCode !== "S") && (props.LegislationVersion !== "Substitute" || props.TextDisposition === "Adopted" || props.ChamberCode === props.LegislationNumber.charAt(0)) ? props.TextDisposition : '') + " " + (substituteLdAndDocCodeString != "" ? substituteLdAndDocCodeString : props.pushWithKeywords ? props.DocumentCode : "");
    }
    //If the IsReprint boolean is true and the service didn't append Reprint to the description already
    if (props.IsReprint && !descriptionAndDisposition.includes("(Reprint)")) {
        descriptionAndDisposition = descriptionAndDisposition + " (Reprint)";
    }

    return (
        <React.Fragment>
            <div className={showText && props.LegislationVersion && props.LegislationVersion.includes("Amendment") ? "bill-details-versions-grid-container no-border" : "bill-details-versions-grid-container"}>
                {/* This is a grid instead of a table because displaying the draft text messes up the alignment of tables */}
                <div className={(props.pushWithKeywords
                    ? "bill-details-versions-grid keyword-hit-list"
                    : "bill-details-versions-grid" + (!props.IsPublic ? " not-public" : ""))
                }>
                    {/* Previously date */}
                    <div>{props.ChamberCode && props.ChamberCode !== "" ? props.ChamberCode === "H" ? "House" : "Senate" : props.Sponsor}</div>
                    <div className={props.pushWithKeywords && "double-version-links"}>
                        {props.pushWithKeywords || props.legislationClass === "Budget" ? <span>{descriptionAndDisposition}</span> : <Link to={`/bill-details/${props.SessionCode || props.sessionCode}/${props.LegislationNumber ? props.LegislationNumber : props.legislationNumber}/text/${props.DocumentCode}`}>{descriptionAndDisposition}</Link>}
                        {props.pushWithKeywords && <a style={{ zIndex: 2 }} onClick={() => props.pushWithKeywords(props.SessionCode || props.sessionCode, props.legislationNumber, props.DocumentCode)}>View Keyword Hits {props.countMatches === 'LOADING' ? <div aria-label="Loading" className="small-spinner" /> : props.countMatches > 0 ? "(" + props.countMatches + ")" : ''}</a>}
                        {!props.IsPublic && !props.pushWithKeywords &&
                            <React.Fragment>
                                <i data-tip="This version has not been published and is not visible to the public." className={'icon notification unpublished-version'} />
                                <ReactTooltip />
                            </React.Fragment>}
                    </div>
                    <div>{impactFileUrls[0] != undefined ? <a type="button" href="javascript:void(0)" onClick={() => toggleText(!showText)}>{impactFileUrls.length > 1 ? "Impact Statements" : "Impact Statement"}</a> : null}</div>
                    <div>{props.LinkFile && props.LinkFile.length > 0 ? <a href={props.LinkFile[0].FileURL} target="_blank" className="link-new-tab">Link</a> : null}</div>
                    <div>{pdfLink ? pdfLink : null}</div>
                    <div>{props.userClaims && props.userClaims.roles && props.userClaims.roles.find(x => x === "ImpactStatementAuthor") && props.toggleStatementForm ? <div><a type="button" className="button-link icon add impact-statement" onClick={() => props.toggleStatementForm((props.VersionDate ? props.VersionDate : props.DraftDate), descriptionAndDisposition, props.DocumentCode)}>IS</a></div> : null}</div>
                    <div style={{ textAlign: 'right' }}>{((props.LegislationVersion && props.LegislationVersion.includes("Amendment")) || impactFileUrls.length) ? <button aria-expanded={showText} type="button" className={showText ? "arrow-up" : "arrow-down"} onClick={() => toggleText(!showText)}>Show amendment text and/or impact statement(s)</button> : null}</div>
                </div>
                {showText && impactFileUrls.length ?
                    <div>
                        {impactFileUrls.map((file, idx) => {
                            return (
                                <div key={idx} className="impact-statements-grid">
                                    <div />
                                    <div>
                                        <a href={file.url} target="_blank" className="link-pdf">{file.description}</a>
                                    </div>
                                    <br />
                                </div>
                            )
                        })
                        }
                    </div>
                    :
                    null
                }
            </div>
            {showText && props.LegislationVersion && props.LegislationVersion.includes("Amendment") &&
                <div className="amendment-text" style={{ paddingBottom: "10px", borderBottom: "1px solid #d9dddd" }}>
                    {renderHTML(props.DraftText || '')}
                </div>
            }
        </React.Fragment>
    )
}

class VersionList extends React.Component {
    constructor(props) {
        super(props);

        this.pushWithKeywords = this.pushWithKeywords.bind(this);
        this.toggleStatementForm = this.toggleStatementForm.bind(this);
    }

    pushWithKeywords(sessionCode, legislationNumber, documentCode) {
        const keywordExpression = this.props.selectedKeywords;
        if (keywordExpression) {
            const partsRegex = /(\().*?(\)+)|(").*?("+)|(').*?('+)|['a-zA-Z*]*/g;
            const keywordParts = keywordExpression.match(partsRegex);
            let foundAnOperator = false;
            for (const keywordPart of keywordParts) {
                if (keywordPart && (keywordPart.toLowerCase() === "and" || keywordPart.toLowerCase() === "or")) { foundAnOperator = true; break; }
            }

            let sanitizedKeywords = [];
            var chars = { "(": "", ")": "", '"': "" };
            if (!foundAnOperator) {
                //need to behave like API to keep it consistent:
                //per API, no operators so just clean expression from single quote operators and treat it as single keyword
                let phraseToInsert = keywordExpression.replace(/[()"]/g, m => chars[m]);
                if (phraseToInsert.startsWith("'") && phraseToInsert.endsWith("'")) { phraseToInsert = phraseToInsert.substr(1, phraseToInsert.length - 2) }
                if (phraseToInsert) { sanitizedKeywords.push(phraseToInsert); }
            } else {
                for (const keywordPart of keywordParts) {
                    if (keywordPart && keywordPart.toLowerCase() != "and" && keywordPart.toLowerCase() != "or") {
                        let wordToInsert = keywordPart.replace(/[()"]/g, m => chars[m]);
                        if (wordToInsert.startsWith("'") && wordToInsert.endsWith("'")) { wordToInsert = wordToInsert.substr(1, wordToInsert.length - 2) }
                        if (wordToInsert) { sanitizedKeywords.push(wordToInsert); }
                    }
                }
            }

            this.props.history.push({
                pathname: `/bill-details/${sessionCode}/${legislationNumber}/text/${documentCode}`,
                state: { selectedKeywords: sanitizedKeywords }
            })
        }
    }

    toggleStatementForm(statementDate, statementDescription, documentCode) {
        this.props.toggleStatementForm(statementDate, statementDescription, documentCode)
    }

    render() {
        const { version, userClaims, selectedKeywords } = this.props;
        return (
            version.map((version, i) => {
                return (selectedKeywords
                    ? <VersionData key={i} {...version} userClaims={userClaims} history={this.props.history} pushWithKeywords={this.pushWithKeywords} legislationNumber={this.props.legislationNumber} toggleStatementForm={this.toggleStatementForm} sessionCode={this.props.sessionCode} />
                    : <VersionData key={i} {...version} userClaims={userClaims} history={this.props.history} toggleStatementForm={this.toggleStatementForm} legislationClass={this.props.legislationClass} />
                )
            })
        )
    }
}

export default withRouter(VersionList);