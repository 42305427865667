import React from 'react';
import ReactDOM from 'react-dom'
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LargeListSelect } from '../../../lis-shared/lis-layout/components/lis-forms-component';
import InputTypes from '../../../lis-admin/lis-minutes-management/components/input-types';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { navActionCreators } from '../../../stores/lis-nav-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { bindActionCreators } from 'redux';

const API_URL = window.env ? window.env.API_SERVER : '';
const NEW_EVENT = "NEW_EVENT";

const selectStyle = () => {
    const fontSize = '0.8em';
    return {
        container: styles => ({
            ...styles,
            minWidth: '90%'
        }),
        control: styles => ({
            ...styles,
            minHeight: '0px',
            padding: '0.12em 0.6em !important',
        }),
        valueContainer: styles => ({
            ...styles,
            padding: 0,
        }),
        input: styles => ({
            ...styles,
            fontSize: fontSize,
            lineHeight: 'normal',
        }),
        singleValue: styles => ({
            ...styles,
            fontSize: fontSize,
        }),
        placeholder: styles => ({
            ...styles,
            fontSize: fontSize
        }),
        option: styles => ({
            ...styles,
            fontSize: fontSize
        }),
        dropdownIndicator: styles => ({
            ...styles,
            padding: '0px 8px'
        }),
    };
}

const getItemStyle = (isDragging, draggableStyle, historyItem) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: 'none',
    background: historyItem.isNewlyAdded ? 'rgba(91, 178, 114, 0.8)' : historyItem.isNewlyDeleted ? 'rgba(255, 151, 151, 0.8)' : historyItem.dateChanged || historyItem.sequenceChanged || historyItem.descriptionChanged || historyItem.eventCodeChanged ? 'rgba(252, 250, 97, 0.8)' : 'white',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#8b9fb3' : '#9e9e9e',
    width: '100%',
});

const getDivStyle = (latestEvent, hideAllEvents) => ({
    display: !latestEvent && hideAllEvents ? "none" : "block"
})

class BillHistoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizedEvents: [],
            newEventDate: moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            newEvent: '',
            editingEvents: [],
            currentInput: { eventGroupIndex: 0, itemIndex: 0, inputNumber: '' },
            changeToBill: '',
            templateInputs: [],
            incompleteInputs: []
        };
        this.handleEventInputChange = this.handleEventInputChange.bind(this);
        this.changeEvent = this.changeEvent.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.toggleEventGroup = this.toggleEventGroup.bind(this);
        this.bulkToggleEventGroup = this.bulkToggleEventGroup.bind(this);
        this.toggleEditDescription = this.toggleEditDescription.bind(this);
        this.changeDescription = this.changeDescription.bind(this);
        this.formDescription = this.formDescription.bind(this);
        this.setDescription = this.setDescription.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.handleBillNumberChange = this.handleBillNumberChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleNewEventChange = this.handleNewEventChange.bind(this);
        this.handleNewEventDateChange = this.handleNewEventDateChange.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.saveEvents = this.saveEvents.bind(this);
        this.handleMovingFocus = this.handleMovingFocus.bind(this);
        this.handleMovingFocusFromBillChanger = this.handleMovingFocusFromBillChanger.bind(this);
        this.receiveEventByWebsocket = this.receiveEventByWebsocket.bind(this);
        this.getSessionLegislation = this.getSessionLegislation.bind(this);

        this._inputRefs = [];
        this._containerRefs = [];
        this._billSwitcherRef = React.createRef();
        this._addEventRef = React.createRef();
    }

    static getConnectionId = (conn, functionToRun) => {
        //This connection ID might be used later
        conn.invoke('getConnectionId')
            .then(connectionId => {
                functionToRun(connectionId);
            });
    }

    //When a WebSocket message comes across with this label then the event list needs to be updated with new values
    static handleEvent = (message, functionToRun) => {
        try {
            if (message.statusCode > 399) throw 'Server Error: ' + JSON.stringify(message);
            functionToRun(message)
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        if (this.props.switchedToBill) {
            this.setState({ changeToBill: this.props.legislationNumber })
        }
        let organizedEvents = this.props.organizedEvents;
        if (organizedEvents.length === 0 || this.props.switchedToBill) {
            organizedEvents = BillHistoryForm.organizeEvents([...this.props.historyList]);
            this.props.handleEventChange(organizedEvents);
        }
        //try to focus on the add event dropdown/input
        if (this._addEventRef.current) {
            var addEventNode = ReactDOM.findDOMNode(this._addEventRef.current);
            if (addEventNode && addEventNode.getElementsByTagName('input').length) {
                addEventNode.getElementsByTagName('input')[0].focus();
            }
        }
        const numberOfEvents = organizedEvents.length;
        //Hide all the events except the last day. This is most likely what the user will be editing
        organizedEvents.forEach((evt, i) => {
            if (i !== numberOfEvents - 1) {
                evt.hidden = true;
            } else {
                evt.hidden = false;
                if (this.props.isBillManagement) {
                    //set the display sequence for all the events in this group that is going to be automatically displayed
                    evt.events.forEach(e => {
                        e.DisplaySequence = e.Sequence * 10;
                    });
                }
            }
        })

        //Scroll to the bottom so they can see the events of the last day
        if (!this.props.isBillManagement) {
            window.scrollTo(0, document.body.scrollHeight);
        }

        if (!this.props.isBillManagement) {
            let entryConnection = new HubConnectionBuilder() //in bill mgmt, use one central entryConnection from props rather than a separate connection for each bill
                .withUrl((API_URL || process.env.REACT_APP_LEGISLATION_EVENT_API_URL) + '/LegislationEvent/events')
                .build();
            entryConnection.serverTimeoutInMilliseconds = 60000;

            this.setState({
                entryHubConnection: entryConnection
            }, () => {
                const self = this;
                this.state.entryHubConnection
                    .start({ withCredentials: false })
                    .then(() => {
                        BillHistoryForm.getConnectionId(this.state.entryHubConnection, (connectionId) => {
                            console.log(`entryConnectionId: ${connectionId}`);
                            this.setState({
                                entryConnectionId: connectionId
                            });
                        });
                    }).catch(err => {
                        console.log('Error establishing connection' + err)
                        this.props.actions.makeToast([{ message: "History Connection Failed", type: "failure" }])
                    });
                this.state.entryHubConnection.on("ReceiveEvent", message => BillHistoryForm.handleEvent(message, this.receiveEventByWebsocket));
            });
        }
    }

    receiveEventByWebsocket(message) {
        let historyList = [...this.props.historyList];

        //If the LegislationID for the list of new events doesn't match the open bill then throw it out
        if (this.props.billData.LegislationID !== message[0].LegislationID) {
            return;
        } else {
            for (let i = 0; i < message.length; i++) {
                //If we don't find an event from the websocket message in the already-loaded list of events then add it to the local history list
                if (!historyList.find(x => x.LegislationEventID === message[i].LegislationEventID)) {
                    historyList.push(message[i])
                }
            }

            //Send the updated local history list to be reorganized and displayed
            let organizedEvents = BillHistoryForm.organizeEvents(historyList);
            this.props.handleEventChange(organizedEvents);
        }
    }

    // Events can only be re-organized within the day that the event occured
    // So each day's events needs to be moved into their own group so they can each have their own drag and drop interface
    // Events can be moved to a different group by the user by selecting a different date for the event
    static organizeEvents(events) {
        let organizedEvents = [];
        let previousDate = '';
        let currentDate = '';
        let eventSequence = 1;
        let eventsInADay = [];
        events.sort((a, b) => moment(a.EventDate).startOf('day').valueOf() - moment(b.EventDate).startOf('day').valueOf())
        events.forEach(event => {
            //first off, set originallyDeleted which is used to figure out if events are newly deleted or not and highlight in bill mgmt
            event.originallyDeleted = Boolean(event.DeletionDate);
            currentDate = moment(event.EventDate)
            if (previousDate && !currentDate.isSame(previousDate, 'day')) {
                organizedEvents.push({
                    events: eventsInADay,
                    date: previousDate
                });
                eventsInADay = [];
                eventSequence = 1;
            }
            eventsInADay.push({ ...event, Sequence: eventSequence, origSequence: eventSequence });
            eventSequence++;
            previousDate = currentDate;
        });
        if (eventsInADay.length > 0) {
            organizedEvents.push({
                events: eventsInADay,
                date: previousDate
            });
        }

        return organizedEvents;
    }

    handleEventInputChange(inputValue, legislationEventID) {
        let eventInputValue = { ...this.state.eventInputValue };
        eventInputValue[legislationEventID.toString()] = inputValue;
        this.setState({ eventInputValue });
    }

    changeEvent(val, eventGroupIndex, index) {
        let organizedEvents = [...this.props.organizedEvents];
        organizedEvents[eventGroupIndex].events[index].origEventCode = organizedEvents[eventGroupIndex].events[index].origEventCode ?? organizedEvents[eventGroupIndex].events[index].EventCode;
        organizedEvents[eventGroupIndex].events[index].EventCode = val.EventCode;
        organizedEvents[eventGroupIndex].events[index].IsPassed = val.IsPassed;
        organizedEvents[eventGroupIndex].events[index].Description = val.LegislationDescription;
        //The event type is referenced from the EventCode. Delete this so the correct event is set
        delete organizedEvents[eventGroupIndex].events[index].LegislationEventTypeID;
        organizedEvents[eventGroupIndex].events[index].hasChanged = true;
        if (val.ActionReferences && val.ActionReferences.length > 0) {
            organizedEvents[eventGroupIndex].events[index].EventReferences = [...val.ActionReferences];
        }
        this.props.handleEventChange(organizedEvents, () => {
            this.saveEvents();

            // When selecting a new event the container does not properly focus so it is done here.
            if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${index}-${1}`]) {
                // The timeout is because react-select messes with the focus after changing the event.
                // It guarantees that react-select will finish its opertions before the focus is set.
                setTimeout(() => this._containerRefs[eventGroupIndex][`${index}-${1}`].current.focus(), 10)
            }

        });
        this.handleEventInputChange(null, organizedEvents[eventGroupIndex].events[index].LegislationEventID);
    }

    changeDate(val, eventGroupIndex, index) {
        if (val === null) {
            return;
        }
        let organizedEvents = [...this.props.organizedEvents];
        let lEvent = {
            ...organizedEvents[eventGroupIndex].events[index],
            EventDate: val.format("LLLL"),
            origDate: organizedEvents[eventGroupIndex].events[index].origDate ?? organizedEvents[eventGroupIndex].events[index].EventDate,
            hasChanged: true
        };
        let newEventGroupIndex = organizedEvents.findIndex(eventGroup => eventGroup.date.isSame(val, 'day'));
        organizedEvents[eventGroupIndex].events.splice(index, 1);
        if (newEventGroupIndex > -1) {
            // If the selected date already has events that occured on that date then the event is moved into the existing group
            lEvent.Sequence = organizedEvents[newEventGroupIndex].events.length + 1;
            organizedEvents[newEventGroupIndex].events.push(lEvent)
            //expand this new event day
            organizedEvents[newEventGroupIndex].hidden = false;
        } else {
            // If the selected date does not have any events then a new event group needs to be created for this date
            lEvent.Sequence = 1;
            const newEventGroup = {
                date: val,
                events: [lEvent],
                hidden: false
            };
            // Gotta find where it goes in the list of groups
            organizedEvents.some((eventGroup, i) => {
                if (eventGroup.date.isAfter(val, 'day')) {
                    newEventGroupIndex = i;
                    organizedEvents.splice(i, 0, newEventGroup)
                    return true;
                }
                if (i === organizedEvents.length - 1) {
                    newEventGroupIndex = i + 1;
                    organizedEvents.push(newEventGroup);
                }
            });
        }
        //If the old day is now empty then remove it from the array
        if (organizedEvents[eventGroupIndex].events.length === 0) {
            organizedEvents.splice(eventGroupIndex, 1);
            if (newEventGroupIndex > eventGroupIndex) {
                newEventGroupIndex -= 1
            }
        }

        // After setting the new events make sure that focus is applied to the container of the event's new position
        this.props.handleEventChange(organizedEvents, () => {
            this.saveEvents();
            if (this._containerRefs[newEventGroupIndex] && this._containerRefs[newEventGroupIndex][`${organizedEvents[newEventGroupIndex].events.length - 1}-${0}`]) {
                this._containerRefs[newEventGroupIndex][`${organizedEvents[newEventGroupIndex].events.length - 1}-${0}`].current.focus();
            }
        });

        if (this.props.hideAllEvents) {
            this.props.togglehideAllEvents(this.props.idx);
        }

        this.setState({
            editingEvents: [],
        });
    }

    toggleEventGroup(eventGroupIndex, expand) {
        let organizedEvents = this.props.organizedEvents;
        //if display sequences haven't already been set for this group of events, set it now
        if (this.props.isBillManagement && organizedEvents[eventGroupIndex].events.find(e => !e.DisplaySequence)) {
            organizedEvents[eventGroupIndex].events.forEach(evt => {
                evt.DisplaySequence = evt.Sequence * 10;
            })
        }
        organizedEvents[eventGroupIndex].hidden = expand !== undefined ? !expand : organizedEvents[eventGroupIndex].hidden === undefined ? false : !organizedEvents[eventGroupIndex].hidden;
        this.forceUpdate();
    }

    bulkToggleEventGroup(expand) {
        let organizedEvents = this.props.organizedEvents;
        organizedEvents.forEach((eventGroup, idx) => {
            this.toggleEventGroup(idx, expand)
        })
    }

    toggleEditDescription(eventGroupIndex, index) {
        let editingEvents = [...this.state.editingEvents];
        if (editingEvents[eventGroupIndex] === undefined || editingEvents[eventGroupIndex] === null) {
            editingEvents[eventGroupIndex] = [];
        }
        // Marking it as null will remove the event from 'edit description mode'
        if (editingEvents[eventGroupIndex][index]) {
            editingEvents[eventGroupIndex][index] = null;
        } else {
            const currentEvent = JSON.parse(JSON.stringify(this.props.organizedEvents[eventGroupIndex].events[index]));
            const eventReference = this.props.billEvents.find(evt => evt.EventCode === currentEvent.EventCode && evt.IsPassed === currentEvent.IsPassed);
            if (eventReference) {
                const actionReferences = eventReference.ActionReferences;
                if (actionReferences) {
                    const missingActionReferences = actionReferences.filter(ref => !currentEvent.EventReferences.map(existingEvt => existingEvt.ActionReferenceType).includes(ref.ActionReferenceType));
                    if (missingActionReferences.length) {
                        currentEvent.EventReferences = currentEvent.EventReferences.concat(JSON.parse(JSON.stringify(missingActionReferences)));
                    }
                }
            }
            //If the user has selected an event with at least one ActionReferenceType of LegislationEvent then fetch possible events for reconsideration
            if (currentEvent.EventReferences && currentEvent.EventReferences.find(x => x.ActionReferenceType === "LegislationEvent")) {
                this.props.getReconsiderationEvents(currentEvent.EventCode)
            } else if (currentEvent.EventReferences && currentEvent.EventReferences.find(x => x.ActionReferenceType === "Legislation") && this.props.billData && this.props.billData.SessionID) {
                this.getSessionLegislation(this.props.billData.SessionID);
            }
            editingEvents[eventGroupIndex][index] = { ...currentEvent };
        }
        this.setState({
            editingEvents: editingEvents
        });
    }

    changeDescription(eventGroupIndex, eventIndex, referenceIndex, reference, descEdit) {
        let editingEvents = [...this.state.editingEvents];
        let event = editingEvents[eventGroupIndex][eventIndex];
        event.origDescription = event.origDescription ?? event.Description;
        //If the event has event references then concatinate them to get the event description
        //If it doesn't then this function was ran by a text input so just set the value of 'reference' to the description
        if (event.EventReferences && event.EventReferences.length > 0 && !descEdit) {
            event.EventReferences[referenceIndex] = reference;
            event.Description = this.formDescription(event, event.EventReferences);
        } else {
            event.Description = reference;
        }
        this.setState({
            editingEvents: editingEvents
        });
    }

    setDescription(eventGroupIndex, index) {
        let organizedEvents = [...this.props.organizedEvents];
        organizedEvents[eventGroupIndex].events[index] = this.state.editingEvents[eventGroupIndex][index];
        organizedEvents[eventGroupIndex].events[index].hasChanged = true;
        this.props.handleEventChange(organizedEvents, () => {
            this.saveEvents();
        });
        this.toggleEditDescription(eventGroupIndex, index);
    }

    handleTemplateChange(_a, _b, referenceIndex, reference) {
        let templateInputs = [...this.state.templateInputs];
        templateInputs[referenceIndex] = reference;
        this.setState({
            templateInputs: templateInputs
        });
    }

    handleBillNumberChange(e) {
        this.setState({
            changeToBill: e.target.value
        });
    }

    onDragEnd(result, eventGroupIndex) {
        if (!result.destination) {
            return;
        }
        let organizedEvents = [...this.props.organizedEvents];
        const [removed] = organizedEvents[eventGroupIndex].events.splice(result.source.index, 1);
        organizedEvents[eventGroupIndex].events.splice(result.destination.index, 0, removed);
        organizedEvents[eventGroupIndex].events.forEach((element, index) => {
            element.Sequence = index + 1
            if (this.props.isBillManagement) {
                element.DisplaySequence = element.Sequence * 10;
            }
            element.hasChanged = true;
        });
        // Remove any events out of edit description mode
        let editingEvents = [...this.state.editingEvents];
        editingEvents[eventGroupIndex] = null;
        this.setState({
            editingEvents: editingEvents
        });
        this.props.handleEventChange(organizedEvents, () => {
            this.saveEvents();
        });
    }

    handleNewEventChange(val) {
        let templateInputs = [];
        if (val.ActionReferences && val.ActionReferences.length > 0) {
            //Remove all object references to val
            templateInputs = JSON.parse(JSON.stringify(val.ActionReferences));
        }
        this.setState({
            newEvent: {
                ...val,
                value: `${val.EventCode}-${val.IsPassed}`
            },
            templateInputs: templateInputs
        }, () => {
            this.handleEventInputChange(null, NEW_EVENT);
            //If the user has selected an event with at least one ActionReferenceType of LegislationEvent then fetch possible events for reconsideration
            if (val.ActionReferences.find(x => x.ActionReferenceType === "LegislationEvent")) {
                this.props.getReconsiderationEvents(val.EventCode)
            } else if (val.ActionReferences.find(x => x.ActionReferenceType === "Legislation") && this.props.billData && this.props.billData.SessionID) {
                this.getSessionLegislation(this.props.billData.SessionID);
            }
        });
    }

    handleNewEventDateChange(val) {
        let value = moment(val).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        this.setState({
            newEventDate: value
        });
    }

    addEvent(e) {
        e.preventDefault();

        let incompleteInputs = [];
        for (let i = 0; i < this.state.templateInputs.length; i++) {
            if ((this.state.templateInputs[i].IsMandatory && !this.state.templateInputs[i].ReferenceText)
                || (this.state.templateInputs[i].IsMandatory && this.state.templateInputs[i].ActionReferenceTypeID == 14 && !this.state.templateInputs[i].ReferenceID)) {
                incompleteInputs.push(i);
            }
        }

        this.setState({
            incompleteInputs: incompleteInputs
        })

        if (incompleteInputs.length > 0) {
            return;
        }

        const { newEvent, newEventDate } = this.state;
        let organizedEvents = [...this.props.organizedEvents];
        let actorType = '';
        switch (newEvent.EventCode ? newEvent.EventCode[0].toUpperCase() : newEvent.LegislationChamberCode) {
            case 'H':
                actorType = 'House';
                break;
            case 'S':
                actorType = 'Senate';
                break;
            case 'C':
                actorType = 'Conference';
                break;
            case 'G':
                actorType = 'Governor';
                break;
        }

        let eventObj = {
            ...newEvent,
            ActorType: actorType,
            IsPublic: true,
            Description: newEvent.label,
            LegislationID: this.props.billData.LegislationID,
            EventDate: moment(newEventDate).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
            hasChanged: true,
            hidden: false
        };

        if (newEvent.ActionReferences && newEvent.ActionReferences.length > 0 && this.state.templateInputs.length > 0) {
            eventObj.EventReferences = this.state.templateInputs;
            eventObj.Description = this.formDescription(eventObj);
        }

        //Check to see if there is are events with the same date already
        let newEventGroupIndex = organizedEvents.findIndex(eventGroup => eventGroup.date.isSame(newEventDate, 'day'));
        if (newEventGroupIndex > -1) {
            // If the selected date already has events that occured on that date then the event is moved into the existing group
            organizedEvents[newEventGroupIndex].events.push(eventObj);
        } else {
            // If the selected date does not have any events then a new event group needs to be created for this date
            const newEventGroup = {
                date: newEventDate,
                events: [eventObj]
            };
            // Gotta find where it goes in the list of groups
            if (organizedEvents.length === 0) {
                newEventGroupIndex = 0;
                organizedEvents.splice(0, 0, newEventGroup);
            } else {
                organizedEvents.some((eventGroup, i) => {
                    if (eventGroup.date.isAfter(newEventDate, 'day')) {
                        newEventGroupIndex = i
                        organizedEvents.splice(i, 0, newEventGroup);
                        return true;
                    }
                    if (i === organizedEvents.length - 1) {
                        newEventGroupIndex = i + 1;
                        organizedEvents.push(newEventGroup)
                    }
                });
            }
        }
        this.setState({
            newEvent: '',
            templateInputs: []
        });
        // After adding the new event, auto-save the event
        this.props.handleEventChange(organizedEvents, () => {
            this.saveEvents();
        })
    }

    formDescription(eventObj, eventReferences) {
        const inputs = eventReferences || this.state.templateInputs
        const memberListEvents = ["H6013", "S6013", "H6113", "S6113", "H6213", "S6213"];
        const substitueRejectedEvents = ["S5432", "S5433", "H5432", "H5433"];
        let overrideSet = false;

        const description = inputs.map((tInput, i) => {
            //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
            if (memberListEvents.includes(eventObj.EventCode) && !tInput.ReferenceText) {
                return;
            }

            //Drop in canned language for opposing chamber substitute or substitue with amendment rejected
            if (substitueRejectedEvents.includes(eventObj.EventCode)) {
                if (!overrideSet) {
                    overrideSet = true;
                    return eventObj.LegislationDescription ?? this.props.billEvents?.find(e => e.EventCode === eventObj.EventCode && e.IsPassed === eventObj.IsPassed)?.LegislationDescription;
                } else {
                    return "";
                }
            }

            if (!tInput.ReferenceText && !eventObj.EventReferences[0].ReconsiderationGrammar) {
                return `${tInput.ActionReferenceType}`
            }

            //Set the Description to the canned description if it's a reconsideration, otherwise just drop in the reference text
            return eventObj.EventReferences.find(x => x.ActionReferenceType === "LegislationEvent") ? this.props.billEvents?.find(e => e.EventCode === eventObj.EventReferences?.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === eventObj.EventReferences?.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : (tInput.ReferenceText + (memberListEvents.includes(eventObj.EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && inputs[i + 1] && inputs[i + 1].ActionReferenceType === "Member" && inputs[i + 1].ReferenceText ? ", " : ""))
        }).join('')

        return description;
    }

    deleteEvent(eventGroupIndex, index) {
        let organizedEvents = [...this.props.organizedEvents];
        let event = organizedEvents[eventGroupIndex].events[index];
        event.hasChanged = true;
        if (event.DeletionDate) {
            delete event.DeletionDate
        }
        //if deleting an event that didn't already exist/hasn't been saved at all, just remove it entirely
        else if (!event.LegislationEventID) {
            //if there is only one event in this day, just remove the day
            if (organizedEvents[eventGroupIndex].events.length === 1) {
                const wasLastEventGroup = eventGroupIndex === organizedEvents.length - 1;
                organizedEvents.splice(eventGroupIndex, 1);
                //if it was the last event day that got removed, expand the now-last event day
                if (wasLastEventGroup) {
                    organizedEvents[organizedEvents.length - 1].hidden = false;
                }
            } else {
                //There is more than just this event in the day, only remove this event from the group and move on
                organizedEvents[eventGroupIndex].events.splice(index, 1);
                //recalculate sequences of events
                for (let idx = index; idx < organizedEvents[eventGroupIndex].events.length; idx++) {
                    organizedEvents[eventGroupIndex].events[idx].Sequence--
                }
            }
        } else {
            //this event existed to begin with, we need to set the deletion date and save it
            event.DeletionDate = moment().toISOString();
        }
        // After soft deleting the new event, auto-save the event
        this.props.handleEventChange(organizedEvents, () => {
            this.saveEvents(() => {
                if (event.DeletionDate && ["8500", "8501", "8503"].includes(event.EventCode.match(/\d+/)[0]) && event.ReferenceID && event.ReferenceNumber) {
                    this.props.actions.deleteBlobFileStatement('?ownerID=' + event.ReferenceID + '&referenceNumber=' + event.ReferenceNumber).catch(() => {
                        this.props.actions.makeToast([{ message: "Failed to delete impact statement file", type: "failure" }])
                    })
                }
            });
        })
    }

    saveEvents(callback) {
        let eventsToSave = [];
        let eventGroupsToSave = []
        this.props.organizedEvents.forEach(eventGroup => {
            eventGroup.events.forEach(event => {
                // Do not save the events that have been deleted and lack a event id. These events to do not need to be created
                // Do not  save events that have not been changed at all
                if (!event.LegislationEventID && event.DeletionDate) {
                    return
                } else if (!event.hasChanged) {
                    return
                } else {
                    eventsToSave.push(event);
                    if (!eventGroupsToSave.find(group => group.date === eventGroup.date)) {
                        eventGroupsToSave.push(eventGroup)
                    }
                }
            })

        });
        this.setState({ isSaving: true });
        this.props.saveEvents(eventsToSave, (success) => {
            this.setState({ isSaving: false });
            if (success) {
                let orgEvents = BillHistoryForm.organizeEvents([...this.props.historyList]);
                orgEvents.forEach(group => {
                    if (eventGroupsToSave.find(g => g.date.isSame(group.date, 'day'))) { group.hidden = false; }
                })
                this.props.handleEventChange(orgEvents, () => {
                    // Try to focus on the last input in the list
                    const lastSavedEventGroup = eventGroupsToSave.length && this.props.organizedEvents.findIndex(group => group.date.isSame(eventGroupsToSave[eventGroupsToSave.length - 1].date, 'day')) > -1 ? this.props.organizedEvents.findIndex(group => group.date.isSame(eventGroupsToSave[eventGroupsToSave.length - 1].date, 'day')) : this.props.organizedEvents.length - 1;
                    const savedEventsInLastSavedEventGroup = eventsToSave.filter(evt => moment(evt.EventDate).isSame(this.props.organizedEvents[lastSavedEventGroup].date, 'day'));
                    const lastSavedEvent = savedEventsInLastSavedEventGroup[savedEventsInLastSavedEventGroup.length - 1];
                    const lastSavedEventIndex = lastSavedEvent ? this.props.organizedEvents[lastSavedEventGroup].events.findIndex(evt => evt.LegislationEventID === lastSavedEvent.LegislationEventID || (evt.Sequence === lastSavedEvent.Sequence && evt.EventCode === lastSavedEvent.EventCode)) : this.props.organizedEvents[lastSavedEventGroup].length - 1
                    if (this._containerRefs[lastSavedEventGroup]) {
                        if (this._containerRefs[lastSavedEventGroup][`${lastSavedEventIndex}-${1}`]) {
                            this._containerRefs[lastSavedEventGroup][`${lastSavedEventIndex}-${1}`].current.focus();
                        }
                    }
                });
            } else {
                //refresh events using the list returned from the GET which was called after the save failed
                let orgEvents = BillHistoryForm.organizeEvents([...this.props.historyList]);
                orgEvents.forEach(group => {
                    if (eventGroupsToSave.find(g => g.date.isSame(group.date, 'day'))) { group.hidden = false; }
                })
                this.props.handleEventChange(orgEvents, () => { })
            }
            if (callback) callback();
        });
    }

    getSessionLegislation(sessionID) {
        if (!this.state.sessionLegislation) {
            this.setState({ fetchingSessionLegislation: true }, () => {
                this.props.actions.getSessionBills('?sessionID= ' + sessionID).then(() => {
                    let billList = [...this.props.bills.sessionBills];
                    billList.forEach(bill => {
                        const chiefPatrons = bill.Patrons && bill.Patrons.length && bill.Patrons.filter(p => p.PatronTypeID === 1);
                        bill.label = chiefPatrons && chiefPatrons.length ? ' (' + bill.LegislationNumber + '-' + chiefPatrons[0].PatronDisplayName + ')' : bill.LegislationNumber;
                        bill.value = bill.LegislationID
                    });
                    this.setState({
                        sessionLegislation: billList,
                        fetchingSessionLegislation: false
                    })
                }).catch(err => {
                    if (err === "Aborted") {
                        return;
                    }
                    console.error(err)
                    this.setState({ fetchingSessionLegislation: false })
                });
            })
        }
    }

    focusOnInputContainer(eventGroupIndex, index, inputNumber, e) {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Escape') {
            this._containerRefs[eventGroupIndex][`${index}-${inputNumber}`].current.focus();
        }
    }

    checkForScrollUp = (node) => {
        // Scroll up a bit more if the element is barely on screen
        const conNodeRect = node.getBoundingClientRect();
        if (conNodeRect.top < conNodeRect.height * 2) {
            this.scrollToEvent(node);
        }
    }

    checkForScrollDown = (node) => {
        // Scroll down a bit more if the element is barely on screen
        const conNodeRect = node.getBoundingClientRect();
        if (conNodeRect.bottom > window.innerHeight - conNodeRect.height * 2) {
            this.scrollToEvent(node);
        }
    }

    handleMovingFocus(eventGroupIndex, eventIndex, inputNumber, e) {
        const key = e.key;
        e.preventDefault();
        switch (key) {
            case 'ArrowLeft':
                if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${eventIndex}-${inputNumber - 1}`]) {
                    this._containerRefs[eventGroupIndex][`${eventIndex}-${inputNumber - 1}`].current.focus()
                }
                break;
            case 'ArrowRight':
                if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${eventIndex}-${inputNumber + 1}`]) {
                    this._containerRefs[eventGroupIndex][`${eventIndex}-${inputNumber + 1}`].current.focus()
                }
                break;
            case 'ArrowDown':
                if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${eventIndex + 1}-${inputNumber}`]) {
                    const conNode = this._containerRefs[eventGroupIndex][`${eventIndex + 1}-${inputNumber}`].current;
                    conNode.focus();
                    this.checkForScrollDown(conNode);
                } else if (this._containerRefs[eventGroupIndex + 1] && this._containerRefs[eventGroupIndex + 1][`${0}-${inputNumber}`]) {
                    let organizedEvents = this.props.organizedEvents;
                    organizedEvents[eventGroupIndex + 1].hidden = false;
                    const conNode = this._containerRefs[eventGroupIndex + 1][`${0}-${inputNumber}`].current
                    conNode.focus();
                    this.checkForScrollDown(conNode);
                } else if (this._billSwitcherRef.current) {
                    this._billSwitcherRef.current.focus();
                }
                break;
            case 'ArrowUp':
                if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${eventIndex - 1}-${inputNumber}`]) {
                    const conNode = this._containerRefs[eventGroupIndex][`${eventIndex - 1}-${inputNumber}`].current
                    conNode.focus();
                    this.checkForScrollUp(conNode);
                } else if (this._containerRefs[eventGroupIndex - 1]) {
                    const lastItemIndexInEventGroup = this.props.organizedEvents[eventGroupIndex - 1].events.length - 1
                    if (this._containerRefs[eventGroupIndex - 1][`${lastItemIndexInEventGroup}-${inputNumber}`]) {
                        let organizedEvents = this.props.organizedEvents;
                        organizedEvents[eventGroupIndex - 1].hidden = false;
                        const conNode = this._containerRefs[eventGroupIndex - 1][`${lastItemIndexInEventGroup}-${inputNumber}`].current
                        conNode.focus();
                        this.checkForScrollUp(conNode);
                    }
                }
                break;
            case 'Tab':
                const changer = e.shiftKey ? -1 : 1
                // First check if there is one to the right.
                if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${eventIndex}-${inputNumber + changer}`]) {
                    this._containerRefs[eventGroupIndex][`${eventIndex}-${inputNumber + changer}`].current.focus()
                }
                // If not, check if there is one underneath in the same group
                else if (this._containerRefs[eventGroupIndex] && this._containerRefs[eventGroupIndex][`${eventIndex + changer}-${changer === 1 ? 0 : 1}`]) {
                    const conNode = this._containerRefs[eventGroupIndex][`${eventIndex + changer}-${changer === 1 ? 0 : 1}`].current;
                    conNode.focus();
                    this.checkForScrollDown(conNode);
                }
                // Finally, check if there is one underneath in a different group
                else if (this._containerRefs[eventGroupIndex + changer] && this._containerRefs[eventGroupIndex + changer][`${changer === 1 ? 0 : this.props.organizedEvents[eventGroupIndex + changer].events.length - 1}-${changer === 1 ? 0 : 1}`]) {
                    let organizedEvents = this.props.organizedEvents;
                    organizedEvents[eventGroupIndex + changer].hidden = false;
                    const conNode = this._containerRefs[eventGroupIndex + changer][`${`${changer === 1 ? 0 : this.props.organizedEvents[eventGroupIndex + changer].events.length - 1}-${changer === 1 ? 0 : 1}`}`].current
                    conNode.focus();
                    this.checkForScrollDown(conNode);
                }
                // If none of these are the case then the user is at the very bottom and they need to focus on the bill switcher
                else if (this._billSwitcherRef.current && changer === 1) {
                    this._billSwitcherRef.current.focus();
                }
                break;
            case 'Enter':
                if (this._inputRefs[eventGroupIndex] && this._inputRefs[eventGroupIndex][`${eventIndex}-${inputNumber}`]) {
                    // The ref doesn't have a click function so turn it into a node
                    const inputNodeContainer = ReactDOM.findDOMNode(this._inputRefs[eventGroupIndex][`${eventIndex}-${inputNumber}`].current);
                    const inputNode = inputNodeContainer.querySelector('input');
                    if (inputNode) {
                        inputNode.focus();
                        inputNode.click();
                    }
                }
                break;
        }
    }

    handleMovingFocusFromBillChanger(e) {
        const key = e.key;
        if (key === 'ArrowUp' || e.key === 'Tab' && e.shiftKey) {
            e.preventDefault();
            const lastEventGroup = this.props.organizedEvents.length - 1;
            // Focus on the last input ref
            if (this._containerRefs[lastEventGroup]) {
                const lastEvent = this.props.organizedEvents[lastEventGroup].events.length - 1;
                if (this._containerRefs[lastEventGroup][`${lastEvent}-${1}`]) {
                    this._containerRefs[lastEventGroup][`${lastEvent}-${1}`].current.focus();
                }
            }
        } else if (key == 'Enter') {
            e.preventDefault();
            this.props.changeToNewBillHistoryForm(this.state.changeToBill);
        }
    }

    scrollToEvent(node) {
        const nodeRect = node.getBoundingClientRect();
        const windowY = window.pageYOffset;
        const windowHeight = window.innerHeight;
        window.scrollTo({
            top: nodeRect.top + windowY - windowHeight / 2,
            behavior: 'smooth'
        });
    }

    render() {
        // Create a ref for every datepicker and dropdown in the form. This is so when hitting enter focus can be applied to the input
        // Also create a ref for the parent element of the input. This is so the user can use arrow keys to move focus from container to container
        // React acts out of the ordinary with a multi-dimensional array of refs, so a 1 dimensional array is used containing an object
        let inputRefs = [];
        let containerRefs = [];
        this.props.organizedEvents.forEach((eventGroup, eventGroupIndex) => {
            let inputs = {};
            let containers = {};
            eventGroup.events.forEach((_historyItem, index) => {
                inputs[`${index}-0`] = React.createRef(),
                    inputs[`${index}-1`] = React.createRef();
                containers[`${index}-0`] = React.createRef(),
                    containers[`${index}-1`] = React.createRef();
            });
            inputRefs[eventGroupIndex] = inputs;
            containerRefs[eventGroupIndex] = containers;
        });
        this._inputRefs = inputRefs;
        this._containerRefs = containerRefs;
        return (
            <React.Fragment>
                {!this.props.isBillManagement && <button type="button" className="button-link" style={{ fontSize: '0.8em' }} onClick={this.props.toggleEditContent}>(Close)</button>}
                <div className="dlas-forms history-event-form" style={{ paddingBottom: !this.props.isBillManagement ? '100px' : '15px' }}>
                    {!this.props.isBillManagement &&
                        <div>
                            <div>
                                <label className="label">Switch To Bill</label>
                                <div>
                                    <input
                                        ref={this._billSwitcherRef}
                                        onKeyDown={this.handleMovingFocusFromBillChanger}
                                        type="text"
                                        placeholder="Bill Number"
                                        onChange={this.handleBillNumberChange}
                                        value={this.state.changeToBill}
                                    />
                                </div>
                            </div>
                            <br />
                            <AddEventForm
                                addEventRef={this._addEventRef}
                                newEvent={this.state.newEvent}
                                handleNewEventChange={this.handleNewEventChange}
                                handleEventInputChange={this.handleEventInputChange}
                                eventInputValue={this.state.eventInputValue}
                                newEventDate={this.state.newEventDate}
                                handleNewEventDateChange={this.handleNewEventDateChange}
                                billEvents={this.props.billEvents}
                                addEvent={this.addEvent}
                                isSaving={this.state.isSaving}
                                memberList={this.state.newEvent && this.state.newEvent.EventCode.startsWith("H") ? this.props.memberList.filter(m => m.ChamberCode === "H") : this.state.newEvent && this.state.newEvent.EventCode.startsWith("S") ? this.props.memberList.filter(m => m.ChamberCode === "S") : this.props.memberList}
                                committeeList={this.props.committeeList}
                                legislationList={this.state.sessionLegislation}
                                fetchingSessionLegislation={this.state.fetchingSessionLegislation}
                                actionTypes={this.props.actionTypes}
                                templateInputs={this.state.templateInputs}
                                textList={this.props.versionsList}
                                handleTemplateChange={this.handleTemplateChange}
                                eventList={this.props.reconsiderationEvents}
                                reconsiderationEvents={this.props.reconsiderationEvents}
                                reconsiderationActionReference={this.state.newEvent && this.state.newEvent.ActionReferences.find(x => x.ActionReferenceType === "LegislationEvent") ? true : false}
                                incompleteInputs={this.state.incompleteInputs}
                            />
                        </div>
                    }
                    <div style={{ marginBottom: "10px", display: 'flex', gap: '25px' }}>
                        <div>
                            <button onClick={() => this.props.idx !== undefined ? this.props.togglehideAllEvents(this.props.idx) : this.props.togglehideAllEvents()} type="button" className={this.props.hideAllEvents ? "arrow-up" : "arrow-down"} />
                            <label>{!this.props.hideAllEvents ? "Hide All Event Days" : "Show All Event Days"}</label>
                        </div>
                        {!this.props.hideAllEvents &&
                            <div>
                                <button onClick={() => this.bulkToggleEventGroup(Boolean(this.props.organizedEvents.find(eg => eg.hidden)))} type="button" className={this.props.organizedEvents.find(eg => eg.hidden) ? "arrow-up" : "arrow-down"} />
                                <label>{!this.props.organizedEvents.find(eg => eg.hidden) ? "Collapse All Event Days" : "Expand All Event Days"}</label>
                            </div>
                        }
                    </div>
                    {this.props.organizedEvents.map((eventGroup, eventGroupIndex) =>
                        <div key={eventGroupIndex} id={this.props.idx !== undefined && eventGroupIndex === this.props.organizedEvents.length - 1 ? `last-${this.props.idx}` : null} style={getDivStyle(eventGroupIndex === this.props.organizedEvents.length - 1, this.props.hideAllEvents)} className="event-day" >
                            {eventGroup.events.length > 0 &&
                                <React.Fragment>
                                    <DragDropContext onDragEnd={result => this.onDragEnd(result, eventGroupIndex)}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <table
                                                    className="dnd-grid-table bill-history-grid"
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                                        <div className='inner-grid forty-six-and-one'>
                                                            <div className="bill-history-grid-header-cell"><button onClick={() => this.toggleEventGroup(eventGroupIndex)} type="button" className={`${eventGroup.hidden || (eventGroup.hidden === undefined && eventGroupIndex !== this.props.organizedEvents.length - 1) ? "arrow-down" : "arrow-up"}`}></button></div>
                                                            <div className="bill-history-grid-header-cell">{eventGroup.date.format('L')}</div>
                                                        </div>
                                                    </thead>
                                                    <tbody style={{ display: !this.props.expandAllEvents && (eventGroup.hidden || (eventGroup.hidden === undefined && eventGroupIndex !== this.props.organizedEvents.length - 1)) ? 'none' : 'block' }}>
                                                        {eventGroup.events.map((historyItem, index) => (
                                                            // All of these values in the key are required to make it unique
                                                            <Draggable isDragDisabled={this.state.isSaving} key={`${historyItem.EventCode}-${historyItem.IsPassed}-${historyItem.Sequence}`} draggableId={`${historyItem.EventCode}-${historyItem.IsPassed}-${historyItem.Sequence}`} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <tr
                                                                        className={!historyItem.isNewlyDeleted && historyItem.DeletionDate ? 'pending-delete' : ''}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style,
                                                                            historyItem
                                                                        )}
                                                                    >
                                                                        <td><button className="button draggable">Drag and drop</button></td>
                                                                        <td>{this.props.isBillManagement ? historyItem.DisplaySequence : index + 1}</td>
                                                                        <td onKeyDown={e => this.handleMovingFocus(eventGroupIndex, index, 0, e)} ref={this._containerRefs[eventGroupIndex][`${index}-0`]} tabIndex="-1">
                                                                            <DatePicker
                                                                                selected={moment(historyItem.EventDate)}
                                                                                onChange={val => this.changeDate(val, eventGroupIndex, index)}
                                                                                disabled={Boolean(historyItem.DeletionDate)}
                                                                                ref={this._inputRefs[eventGroupIndex][`${index}-0`]}
                                                                                onKeyDown={e => this.focusOnInputContainer(eventGroupIndex, index, 0, e)}
                                                                            />
                                                                        </td>
                                                                        <td>{historyItem.ActorType}</td>
                                                                        <td onKeyDown={e => this.handleMovingFocus(eventGroupIndex, index, 1, e)} ref={this._containerRefs[eventGroupIndex][`${index}-1`]} tabIndex="-1">
                                                                            {this.state.editingEvents[eventGroupIndex] && this.state.editingEvents[eventGroupIndex][index] !== null && this.state.editingEvents[eventGroupIndex][index] !== undefined ?
                                                                                <React.Fragment>
                                                                                    <div onKeyDown={e => e.stopPropagation()} style={{ minWidth: '90%' }}>
                                                                                        {this.state.editingEvents[eventGroupIndex][index].EventReferences && this.state.editingEvents[eventGroupIndex][index].EventReferences.length > 0 ?
                                                                                            <React.Fragment>
                                                                                                <InputTypes
                                                                                                    memberList={this.state.editingEvents[eventGroupIndex][index].EventCode.startsWith("H") ? this.props.memberList.filter(m => m.ChamberCode === "H") : this.state.editingEvents[eventGroupIndex][index].EventCode.startsWith("S") ? this.props.memberList.filter(m => m.ChamberCode === "S") : this.props.memberList}
                                                                                                    committeeList={this.props.committeeList}
                                                                                                    legislationList={this.state.sessionLegislation}
                                                                                                    fetchingSessionLegislation={this.state.fetchingSessionLegislation}
                                                                                                    actionTypes={this.props.actionTypes}
                                                                                                    handleChange={this.changeDescription}
                                                                                                    textList={this.props.versionsList}
                                                                                                    entryIndex={eventGroupIndex}
                                                                                                    activityIndex={index}
                                                                                                    references={this.state.editingEvents[eventGroupIndex][index].EventReferences}
                                                                                                    eventList={this.props.reconsiderationEvents}
                                                                                                    newEvent={this.state.editingEvents[eventGroupIndex][index]}
                                                                                                    autoselect
                                                                                                    billHistory
                                                                                                />
                                                                                                <input
                                                                                                    type="text"
                                                                                                    style={{ width: '100%' }}
                                                                                                    value={this.state.editingEvents[eventGroupIndex][index].Description}
                                                                                                    onChange={(e) => this.changeDescription(eventGroupIndex, index, 0, e.target.value, true)}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <input
                                                                                                type="text"
                                                                                                style={{ width: '100%' }}
                                                                                                value={this.state.editingEvents[eventGroupIndex][index].Description}
                                                                                                onChange={(e) => this.changeDescription(eventGroupIndex, index, 0, e.target.value)}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        <span style={{ marginLeft: '10px' }} className="icon save" onClick={() => this.state.isSaving ? null : this.setDescription(eventGroupIndex, index)}></span>
                                                                                        <span style={{ marginLeft: '10px' }} className="icon delete" onClick={() => this.toggleEditDescription(eventGroupIndex, index)}></span>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <LargeListSelect
                                                                                        options={this.props.billEvents}
                                                                                        styles={selectStyle()}
                                                                                        onChange={val => this.changeEvent(val, eventGroupIndex, index)}
                                                                                        value={{
                                                                                            ...historyItem,
                                                                                            label: historyItem.Description,
                                                                                            value: `${historyItem.EventCode}-${historyItem.IsPassed}`
                                                                                        }}
                                                                                        getOptionLabel={opt => `(${opt.EventCode}) ${opt.label}`}
                                                                                        isDisabled={historyItem.DeletionDate}
                                                                                        ref={this._inputRefs[eventGroupIndex][`${index}-1`]}
                                                                                        onKeyDown={e => this.focusOnInputContainer(eventGroupIndex, index, 1, e)}
                                                                                        inputValue={this.state.eventInputValue && this.state.eventInputValue[historyItem.LegislationEventID] ? this.state.eventInputValue[historyItem.LegislationEventID].toString() : ''}
                                                                                        onInputChange={inputValue => this.handleEventInputChange(inputValue, historyItem.LegislationEventID)}
                                                                                        onMenuClose={() => this.handleEventInputChange(this.state.eventInputValue ? this.state.eventInputValue[historyItem.LegislationEventID] : null, historyItem.LegislationEventID)} //hacky way to persist the input text after the user clicks away with the dropdown open                                                                    
                                                                                    />
                                                                                    <span style={{ marginLeft: '10px' }} onClick={() => this.state.isSaving ? null : this.toggleEditDescription(eventGroupIndex, index)} className="icon edit" title="Change Description"></span>
                                                                                    {historyItem.MinutesActivityID && <span style={{ marginLeft: '10px', cursor: "default" }} className="icon paper" title="Minutes Activity"></span>}
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td><button type="button" disabled={this.state.isSaving} style={{ backgroundColor: 'transparent' }} onClick={() => this.deleteEvent(eventGroupIndex, index)} className={`button ${historyItem.DeletionDate ? 'add' : 'remove'}`}>{historyItem.DeletionDate ? 'Add' : 'Delete'}</button></td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                </table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </React.Fragment>
                            }
                        </div>
                    )}
                    {!this.props.isBillManagement &&
                        <div>
                            <div className="floating-button-bar invisible-bar" style={{ opacity: ".9", zIndex: "0" }}>
                                <div className="flex-row flex-vertical-center" style={{ width: '100%' }}>
                                    <div />
                                    <div className="align-right inline-list">
                                        <button disabled={this.state.isSaving} type="button" onClick={this.props.toggleEditContent} className="button secondary">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>)
    }
}

function AddEventForm(props) {
    return (
        <React.Fragment>
            <h4>Add History Event</h4>
            <form onSubmit={(e) => props.addEvent(e)} className="inner-grid small-one-small">
                <DatePicker
                    selected={props.newEventDate}
                    onChange={props.handleNewEventDateChange}
                />
                <LargeListSelect
                    ref={props.addEventRef}
                    options={props.billEvents}
                    styles={selectStyle()}
                    value={props.newEvent}
                    inputId={`add-new-event-react-create`}
                    getOptionLabel={opt => `(${opt.EventCode}) ${opt.label}`}
                    onChange={props.handleNewEventChange}
                    inputValue={props.eventInputValue && props.eventInputValue[NEW_EVENT] ? props.eventInputValue[NEW_EVENT] : ''}
                    onInputChange={inputValue => props.handleEventInputChange(inputValue, NEW_EVENT)}
                    onMenuClose={() => props.handleEventInputChange(props.eventInputValue && props.eventInputValue[NEW_EVENT], NEW_EVENT)} //hacky way to persist the input text after the user clicks away with the dropdown open
                />
                <button className="button" type="submit" onClick={props.addEvent} disabled={props.isSaving || !props.newEvent || !props.newEventDate || (props.reconsiderationActionReference && (!props.reconsiderationEvents || props.reconsiderationEvents.length === 0))}>Add</button>
            </form>
            {props.templateInputs.length > 0 &&
                <div >
                    <InputTypes
                        memberList={props.memberList}
                        committeeList={props.committeeList}
                        legislationList={props.legislationList}
                        fetchingSessionLegislation={props.fetchingSessionLegislation}
                        textList={props.textList}
                        actionTypes={props.actionTypes}
                        handleChange={props.handleTemplateChange}
                        entryIndex={0}
                        activityIndex={0}
                        references={props.templateInputs}
                        eventList={props.eventList}
                        newEventDate={props.newEventDate}
                        newEvent={props.newEvent}
                        incompleteInputs={props.incompleteInputs}
                        autoselect
                        isNewEvent
                    />
                </div>
            }
            <br />
        </React.Fragment>
    )
}

const HistoryForm = connect(
    (state) => {
        const { nav, bills } = state;
        return {
            nav,
            bills,
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, billActionCreators), dispatch)
        }
    }
)(BillHistoryForm)

export default HistoryForm;