import React from 'react';
import { bindActionCreators } from 'redux';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { minutesActionCreators } from '../../stores/lis-minutes-store';
import { personnelActionCreators } from '../../stores/lis-personnel-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { cancelRequest } from '../../services/request.service';
import moment from 'moment-timezone';

import MinutesDetails from '../../lis-shared/lis-minutes/lis-minutes-details';
import renderHTML from 'react-render-html';

class PublicMinutesDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    //When component has mounted, make the call to get session by ID
    componentDidMount() {
        const sessionCode = parseInt(this.props.match.params.sessioncode);
        const minutesId = parseInt(this.props.match.params.minutesid)
        const error = (msg) => {
            this.setState({
                message: msg || "Failed to get minutes",
                isLoaded: true
            });
        }

        if (parseInt(sessionCode) > 0 && minutesId > 0) {
            this.props.actions.getSessionByCode(sessionCode)
                .then(() => {
                    this.props.actions.getMinutesBook('?minutesbookID=' + minutesId)
                        .then(() => {
                            const minutesBook = this.props.minutes.minutesBook;
                            if (minutesBook.MinutesCategories.length === 0 && minutesBook.MinutesFiles.find(file => file.FileURL.toUpperCase().includes('.HTM'))) {
                                //If there are no categories  then it is assumed that this minutes book is legacy. Get the file if it exists
                                const htmlFile = minutesBook.MinutesFiles.find(file => file.FileURL.toUpperCase().includes('.HTM'));
                                if (htmlFile) {
                                    this.props.actions.getFile(htmlFile.FileURL)
                                        .then(() => {
                                            this.setState({
                                                minutesFile: this.props.nav.file,
                                                isLoaded: true
                                            });
                                        }).catch(err => {
                                            if (err !== 'Aborted') {
                                                throw err.toString();
                                            }
                                        });
                                }
                            } else {
                                this.props.actions.getRoleTypes()
                                    .then(() => {
                                        // not going to serve the file itself, so we need to get the appropriate names for the header
                                        // wait until both async calls are done before setting isLoaded to true 
                                        const ltGovPromise = new Promise((res, rej) => {
                                            const ltGovRoleType = this.props.personnel.roleTypes.find(x => x.Name.toLowerCase().includes("president"));
                                            const ltGovParams = "isPublic=true&staffRoleTypeID=" + ltGovRoleType.StaffRoleTypeID + "&effectiveBeginDate=" + minutesBook.MinutesDate;
                                            this.props.actions.getPersonnelList(ltGovParams, true)
                                                .then(() => {
                                                    const ltGov = this.props.personnel.personnelOtherList[0];
                                                    if (ltGov) {
                                                        this.setState({
                                                            ltGov
                                                        })
                                                    } else {
                                                        throw 'Failed to get President of the Senate';
                                                    }
                                                    res();
                                                }).catch(err => rej(err));
                                        });
                                        const clerkOfSenatePromise = new Promise((res, rej) => {
                                            const clerkOfSenateRoleType = this.props.personnel.roleTypes.find(x => x.Name.toLowerCase().includes("clerk") && x.Name.toLowerCase().includes("senate"))
                                            const clerkOfSenateParams = "isPublic=true&staffRoleTypeID=" + clerkOfSenateRoleType.StaffRoleTypeID + "&effectiveBeginDate=" + minutesBook.MinutesDate;
                                            this.props.actions.getPersonnelList(clerkOfSenateParams, true)
                                                .then(() => {
                                                    const clerkofTheSenate = this.props.personnel.personnelOtherList[0];
                                                    if (clerkofTheSenate) {
                                                        this.setState({
                                                            clerkofTheSenate
                                                        })
                                                    } else {
                                                        throw 'Failed to get Clerk of the Senate';
                                                    }
                                                    res();
                                                }).catch(err => rej(err));
                                        })
                                        Promise.all([ltGovPromise, clerkOfSenatePromise]).then(() => {
                                            this.setState({ isLoaded: true })
                                        }).catch(err => {
                                            if (err === 'Aborted')
                                                return;
                                            console.log(err)
                                            error('Failed to get header personnel information');
                                        })
                                    }).catch(err => {
                                        if (err === 'Aborted')
                                            return;
                                        error('Failed to get header personnel information');
                                    });
                            }
                        }).catch(err => {
                            if (err === 'Aborted')
                                return;
                            error();
                        });
                });
        } else {
            error();
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { isLoaded, message, ltGov, clerkofTheSenate } = this.state;

        const session = this.props.session.selectedSession;
        const sessionHeader = session ? session.SessionYear + " " + session.DisplayName : '';

        const minutesBook = this.props.minutes.minutesBook;
        const categories = minutesBook.MinutesCategories;
        let categoriesToShow = [];
        if (categories) {
            categoriesToShow = categories.filter(category => category.ReleaseToPreview);
        }

        if (message) {
            return (<p>{message}</p>);
        }
        if (!isLoaded) {
            return (<div className="center-spinner spinner">Loading...</div>);
        }
        return (
            <div className='generic-details-page minutes-details-page'>
                <div className="flex-row">
                    <div>
                        <h2>{sessionHeader}</h2>
                    </div>
                    <div>
                        {minutesBook.MinutesFiles && minutesBook.MinutesFiles.length > 0 &&
                            <a className="download-link" href={minutesBook.MinutesFiles[0].FileURL} target="_blank" style={{ marginRight: "5px" }}><i className="icon file" /> View HTML</a>
                        }
                        <ReactToPrint
                            trigger={() => <button type="button" className="button print"> Print</button>}
                            content={() => this.componentRef}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                </div>
                <div className="details-page-data-container" ref={el => (this.componentRef = el)}>
                    {this.state.minutesFile ?
                        <div>{renderHTML(this.state.minutesFile)}</div>
                        :
                        <React.Fragment>
                            <div className="entry-text">
                                <ul className="first-entry">
                                    <li>Minute Book</li>
                                    <li>{sessionHeader}</li>
                                    <li>Senate of Virginia</li>
                                    <li>{ltGov.FullName.toUpperCase()}, PRESIDENT OF THE SENATE</li>
                                    <li>{clerkofTheSenate.FullName.toUpperCase()}, CLERK</li>
                                    <li>{moment(minutesBook.MinutesDate).format('dddd, MMMM DD, YYYY')}</li>
                                </ul>
                            </div>
                            <MinutesDetails
                                categories={categoriesToShow}
                                publicPage={true}
                            />
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { session, minutes, personnel, nav } = state;
        return {
            session,
            minutes,
            personnel,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, minutesActionCreators, personnelActionCreators, navActionCreators), dispatch)
        }
    }
)(PublicMinutesDetails)
