import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { generateCsv } from '../../services/csv.service';
import { voteActionCreators } from '../../stores/lis-votes-store';
import { patronActionCreators } from '../../stores/lis-patron-store';
import { Link } from 'react-router-dom';
import AgendaItemsComponent from './lis-agenda-items';
import VersionList from '../../lis-shared/lis-bills/lis-bill-version-list';
import CreatableSelect from 'react-select/creatable';
import renderHTML from 'react-render-html';
import ReportMaker from '../../lis-public/components/lis-bill-search/lis-report-maker';

const BILL_COLLECTION_AUTHOR = "LegislationCollections";
const BILL_MANAGEMENT_AUTHOR = "Legislation";
const COMMUNICATION_AUTHOR = "CommunicationFileGeneration";
const CALENDAR_AUTHOR = "Calendar";
const lisDocDomain = window.env?.STORAGE_ACCOUNT_DOMAIN;

const HOUSE_NUMERIC_SORT_CATEGORIES = [2, 3, 4, 26, 27, 5, 41, 6, 7, 28, 29, 30, 31, 32, 57, 33, 35, 45, 9, 10, 8, 11, 13, 37, 38, 44, 46];
const SENATE_NUMERIC_SORT_CATEGORIES = [72, 111, 109, 112, 60, 110, 98, 102, 83, 90, 87, 93, 100, 104, 117, 118, 105, 106, 66, 67, 96, 97, 64, 68, 69, 65];

const sortAgendas = (a, b, calendarChamber) => {
    if (calendarChamber === "H") {
        return !a.IsActive && b.IsActive
            ? 1
            : a.IsActive && !b.IsActive
                ? -1
                : a.Sequence > 0 && b.Sequence > 0 ? a.Sequence - b.Sequence : (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
                    ? -1
                    : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
                        ? 1
                        : a.Ranking > b.Ranking
                            ? 1
                            : a.Ranking < b.Ranking
                                ? -1
                                : moment(a.CalendarDate).isAfter(b.CalendarDate, "day")
                                    ? 1
                                    : moment(b.CalendarDate).isAfter(a.CalendarDate, "day")
                                        ? -1
                                        : moment(a.CandidateDate).isAfter(b.CandidateDate, "day")
                                            ? 1
                                            : moment(b.CandidateDate).isAfter(a.CandidateDate, "day")
                                                ? -1
                                                : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                                    a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                                        ? -1 : 1
                                                    : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                                        ? 1
                                                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                                            ? -1
                                                            : a.LegislationKey > b.LegislationKey
                                                                ? 1
                                                                : -1
    } else {
        return b.IsActive !== a.IsActive
            ? b.IsActive
                ? 1
                : -1
            : a.Sequence > 0 && b.Sequence > 0 && a.Sequence !== b.Sequence
                ? a.Sequence - b.Sequence > 0
                    ? 1
                    : -1
                : a.CalendarDate !== b.CalendarDate
                    ?
                    !a.CalendarDate ?
                        1 :
                        !b.CalendarDate ? -1
                            :
                            a.CalendarDate - b.CalendarDate
                    : a.CandidateDate !== b.CandidateDate
                        ?
                        !a.CandidateDate ?
                            1 :
                            !b.CandidateDate ? -1
                                :
                                a.CandidateDate - b.CandidateDate
                        : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                            a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                ? -1 : 1
                            : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) !== 0
                                ? a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                    ? 1
                                    : -1
                                : a.LegislationKey && parseInt(a.LegislationKey) !== parseInt(b.LegislationKey)
                                    ? parseInt(a.LegislationKey) - parseInt(b.LegislationKey) > 0
                                        ? 1
                                        : -1
                                    : 0
    }
}

const sortAgendasNumerically = (a, b, calendarChamber) => {
    if (calendarChamber === "H") {
        return !a.IsActive && b.IsActive
            ? 1
            : a.IsActive && !b.IsActive
                ? -1
                : (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
                    ? -1
                    : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
                        ? 1
                        : a.Ranking > b.Ranking
                            ? 1
                            : a.Ranking < b.Ranking
                                ? -1
                                : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                    a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                        ? -1 : 1
                                    : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                        ? 1
                                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                            ? -1
                                            : a.LegislationKey > b.LegislationKey
                                                ? 1
                                                : -1
    } else {
        return !a.IsActive && b.IsActive
            ? 1
            : a.IsActive && !b.IsActive
                ? -1
                : a.Sequence > 0 && b.Sequence > 0 && a.Sequence !== b.Sequence
                    ? a.Sequence - b.Sequence > 0
                        ? 1
                        : -1
                    : a.LegislationNumber && b.LegislationNumber && a.LegislationNumber[0] !== b.LegislationNumber[0] ?
                        a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                            ? -1
                            : 1
                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                            ? 1
                            : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                ? -1
                                : a.LegislationKey > b.LegislationKey
                                    ? 1
                                    : -1
    }
}

class CalendarDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hideAll: false,
            hide: [],
            showAgendaItemEditor: -1,
            showAgendaItemEditorCategoryIndex: -1,
            currentItemSelection: ''
        }

        this.toggleAll = this.toggleAll.bind(this);
        this.toggleCategory = this.toggleCategory.bind(this);
        this.fetchCsv = this.fetchCsv.bind(this);
        this.toggleAgendaItemEditor = this.toggleAgendaItemEditor.bind(this);
        this.handleCurrentItemChange = this.handleCurrentItemChange.bind(this);
        this.generateClerksCopy = this.generateClerksCopy.bind(this);
        this.toggleReportMaker = this.toggleReportMaker.bind(this);
    }

    toggleAll() {
        const opposite = !this.state.hideAll;
        let hide = new Array(this.props.calendar.CalendarCategories.length);
        hide.fill(opposite);

        this.setState({
            hideAll: opposite,
            hide: hide
        });
    }

    toggleCategory(catIndex) {
        let hide = [...this.state.hide];
        hide[catIndex] = !hide[catIndex];
        this.setState({
            hide: hide
        })
    }

    fetchCsv() {
        let rows = [];
        //Flatten the data and add the category to each row
        this.props.calendar.CalendarCategories.forEach(category => {
            category.Agendas.forEach(agenda => {
                if (agenda.IsActive) {
                    agenda.CalendarCategory = category.DisplayType ? category.Description + " - " + category.CategoryType : category.Description;
                    //Handle inserting description text for non-legislation agenda items
                    if (!agenda.LegislationDescription)
                        agenda.LegislationDescription = agenda.Description;
                    //Handle inserting patrons
                    if (agenda.Patrons) {
                        agenda.Patron = agenda.Patrons.map(p => p.PatronDisplayName).join(",");
                    }
                    rows.push(agenda);
                }
            });
        })

        let fileName = this.props.calendar.ChamberCode === "S" ? "SenateCalendar" : "HouseCalendar";
        fileName = fileName + moment(this.props.calendar.CalendarDate).format("MMMMDoYYYY") + ".csv";

        saveAs(new Blob([generateCsv(["Calendar Category", "Legislation Number", "Legislation Description", "Patron", "Page Number"], rows)], { type: "data:text/csv;charset=utf-8," }), fileName);
    }

    toggleAgendaItemEditor(agendaIndex, categoryIndex) {
        this.setState({
            showAgendaItemEditor: agendaIndex,
            showAgendaItemEditorCategoryIndex: categoryIndex || -1
        });
    }

    handleCurrentItemChange(val) {
        this.setState({
            currentItemSelection: val
        });
    }

    generateClerksCopy(category, e) {
        e.stopPropagation();
        this.props.generateClerksCopy(category);
    }

    toggleReportMaker(catIndex, e) {
        if (e) {
            e.stopPropagation();
        }

        if (!this.state.showReportMaker && !isNaN(parseInt(catIndex))) {
            this.setCategoryReportBillList(catIndex);
        } else {
            //needs to be separated otherwise this will run before the setCategoryReportBillList method is done and the ReportMaker component will not have the info needed, resulting in NREs
            this.setState(state => ({
                showReportMaker: !state.showReportMaker
            }));
        }
    }

    setCategoryReportBillList(catIndex) {
        const category = this.props.calendar.CalendarCategories[catIndex];
        let categoryReportBillList = [];
        category.Agendas.forEach(agenda => {
            if (agenda.LegislationID && agenda.IsActive) {
                categoryReportBillList.push({ LegislationID: agenda.LegislationID, LegislationNumber: agenda.LegislationNumber, SessionID: this.props.calendar.SessionID });
            }
        });

        this.setState({ showReportMaker: true, categoryReportBillList })
    }

    render() {
        const { session, calendar, comments } = this.props;
        const { hide, hideAll } = this.state;
        const filteredCategories = calendar.CalendarCategories.map(category => {
            const checkForActiveAgendas = category.Agendas.find(agenda => agenda.IsActive && !agenda.IsHidden);
            if (checkForActiveAgendas) {
                if (category.CategoryType != "Order") {
                    let unrankedAgendas = [];
                    let rankedAgendas = [];
                    let rankings = [];

                    if (calendar.ChamberCode === "H") {
                        //house calendar sort
                        category.Agendas.forEach((agenda) => {
                            if (!agenda.Ranking && agenda.IsActive) {
                                unrankedAgendas.push(agenda);
                            } else if (agenda.IsActive) {
                                rankedAgendas.push(agenda);
                                rankings.push(agenda.Ranking);
                            }
                        })

                        rankings.sort((a, b) => a - b);

                        let gaps = [];
                        for (let i = 1; i < rankings.length; i++) {
                            gaps.push(rankings[i] - rankings[i - 1]);
                        }

                        if (HOUSE_NUMERIC_SORT_CATEGORIES.includes(category.CalendarCategoryTypeID)) {
                            rankedAgendas.sort((a, b) => sortAgendasNumerically(a, b, calendar.ChamberCode));
                        } else {
                            rankedAgendas.sort((a, b) => sortAgendas(a, b, calendar.ChamberCode));
                        }
                        if (HOUSE_NUMERIC_SORT_CATEGORIES.includes(category.CalendarCategoryTypeID)) {
                            unrankedAgendas.sort((a, b) => sortAgendasNumerically(a, b, calendar.ChamberCode));
                        } else {
                            unrankedAgendas.sort((a, b) => sortAgendas(a, b, calendar.ChamberCode));
                        }

                        //If there are multiple ranks with a gap larger than 1 place all unranked agendas between them
                        if (new Set(rankings).size > 1 && Math.max(...gaps) > 1) {
                            let insertPoint = gaps.findIndex(x => x === Math.max(...gaps)) + 1

                            for (let i = 0; i < unrankedAgendas.length; i++) {
                                rankedAgendas.splice(insertPoint, 0, unrankedAgendas[i]);
                                insertPoint++;
                            }

                            category.Agendas = rankedAgendas;
                        } else {
                            //If the largest rank is greated than 50 put all unranked agendas above the ranked agendas, otherwise put them all at the bottom
                            if (Math.max(...rankings) >= 50) {
                                category.Agendas = unrankedAgendas.concat(rankedAgendas);
                            } else {
                                category.Agendas = rankedAgendas.concat(unrankedAgendas);
                            }
                        }
                    } else {
                        //senate calendar sort
                        if (SENATE_NUMERIC_SORT_CATEGORIES.includes(category.CalendarCategoryTypeID)) {
                            category.Agendas.sort((a, b) => sortAgendasNumerically(a, b, calendar.ChamberCode))
                        } else {
                            category.Agendas.sort((a, b) => sortAgendas(a, b, calendar.ChamberCode))
                        }
                    }
                }
                return category;
            } else {
                return null;
            }
        });

        let calendarFileUrl = '';
        const pdfFile = calendar.CalendarFiles.find(file => file.FileURL.toUpperCase().includes('PDF'));
        if (pdfFile) {
            const url = new URL(pdfFile.FileURL);
            calendarFileUrl = lisDocDomain + (url.pathname[0] === '/' ? url.pathname : '/' + url.pathname);
        }
        const categoryDescription = category => {
            //if it's been edited, leave it at that
            const associatedReferenceCategory = this.props.calendarCategoryReferences.find(x => x.CalendarCategoryTypeID === category.CalendarCategoryTypeID);
            if (associatedReferenceCategory && associatedReferenceCategory.Description !== category.Description && associatedReferenceCategory.PluralDescription !== category.Description) {
                return category.Description;
            }

            //otherwise, use singular or plural reference description
            if (calendar.ChamberCode === 'H' && category.CategoryCode === "HOUBRC" || category.CategoryCode === "SENBRC") {
                return category.Description;
            } else if (category.Agendas.filter(a => a.IsActive).length > 1 && category.PluralDescription) {
                return category.PluralDescription;
            } else {
                return category.Description;
            }
        }
        const sessionHeader = session ? session.SessionYear + " " + session.DisplayName : '';
        const canSetCurrentItem = Boolean(this.props.userClaims && this.props.userClaims.resources.find(resource => resource === CALENDAR_AUTHOR)) && this.props.userClaims.resources.find(x => x === "LegislationEvent");
        return (
            <div>
                {this.state.showReportMaker &&
                    <ReportMaker
                        toggleReportMaker={this.toggleReportMaker}
                        selectedCollection={{
                            Name: `Report on ${moment(this.props.calendar.CalendarDate).format('MM/DD/YY')}`,
                        }}
                        billList={this.state.categoryReportBillList}
                        reportTitle={`Report on ${moment(this.props.calendar.CalendarDate).format('MM/DD/YY')}`}
                    />
                }
                {this.state.showAgendaItemEditor !== -1 &&
                    <AgendaItemsComponent
                        toggleAgendaItemEditor={this.toggleAgendaItemEditor}
                        agenda={calendar.CalendarCategories[this.state.showAgendaItemEditorCategoryIndex].Agendas[this.state.showAgendaItemEditor]}
                        categoryIndex={this.state.showAgendaItemEditorCategoryIndex}
                        agendaIndex={this.state.showAgendaItemEditor}
                        updateAgendaItems={this.props.updateAgendaItems}
                    />
                }
                <br />
                <div className="flex-row flex-vertical-center multiple-icons">
                    <h2 className="no-margin">{sessionHeader}</h2>
                    {calendarFileUrl
                        && (this.props.userClaims && this.props.userClaims.resources.find(x => x !== "LegislationCollections") || this.props.isCalendarForm)
                        && this.props.sessionList.find(x => x.SessionCode === this.props.calendar.SessionCode)
                        && this.props.sessionList.find(x => x.SessionCode === this.props.calendar.SessionCode).SessionYear >= 2023
                        && calendar.CalendarNumber < 2 ?
                        //Need to prevent the padded calendar from displaying if the session is before go live
                        <a target="_blank" href={calendarFileUrl.replace(".PDF", "-padded.PDF")}>
                            <span title="Padded-PDF" aria-label="Padded-PDF" className="icon file-empty"></span>
                        </a>
                        :
                        <div /> //For grid layout formatting
                    }
                    {calendarFileUrl &&
                        <a target="_blank" href={calendarFileUrl}>
                            <span title="PDF" aria-label="PDF" className="icon pdf"></span>
                        </a>
                    }
                    <a target="_blank" onClick={this.fetchCsv}>
                        <span aria-label="Spreadsheet" title="Spreadsheet" role="button" className="icon csv"></span>
                    </a>
                </div>
                <hr />
                <div className="flex-row details-page-data-container">
                    <div>
                        <h3 className="no-margin">{calendar.ChamberCode === "S" ? "Senate " : "House "} Calendar {calendar.IsProforma && "(Pro Forma)"}</h3>
                        <span>{moment(calendar.CalendarDate).format("LL") + (calendar.CalendarNumber > 1 ? [' (', ')'].join('Supp. ' + (calendar.CalendarNumber - 1)) : '')}</span>
                    </div>
                    <div>
                        <button className="button-link" aria-expanded={!hideAll} onClick={this.toggleAll} type="button">{hideAll ? "Expand" : "Collapse"} All +/-</button>
                    </div>
                </div>
                {comments.length > 0 &&
                    <div className="details-page-data-container public-details">
                        {comments.map((comment, i) => {
                            return (
                                <p key={i}>{"Comment " + comment.Sequence + ": " + comment.Comment}</p>
                            )
                        })}
                    </div>
                }
                <div className="flex-row">
                    <div className='details-page-data-container'>
                        {
                            this.props.currentProceduralMotion ? <span>Current Item: {this.props.currentProceduralMotion}</span>
                                :
                                this.props.currentAgendaItem && filteredCategories.find(cat => cat && cat.Agendas && cat.Agendas.find(agenda => agenda.AgendaID === this.props.currentAgendaItem)) ? <span>Current Item: {filteredCategories.find(cat => cat && cat.Agendas && cat.Agendas.find(agenda => agenda.AgendaID === this.props.currentAgendaItem)).Agendas.find(agenda => agenda.AgendaID === this.props.currentAgendaItem).LegislationNumber}</span>
                                    :
                                    this.props.currentCategoryItem && filteredCategories.find(cat => cat && cat.CalendarCategoryID === this.props.currentCategoryItem) ? <span>Current Item: {categoryDescription(filteredCategories.find(cat => cat && cat.CalendarCategoryID === this.props.currentCategoryItem))}</span>
                                        :
                                        null}
                    </div>
                    {!this.props.isCalendarForm && canSetCurrentItem &&
                        <div className="inner-grid three-and-one">
                            <CreatableSelect
                                options={this.props.commonCurrentItemOptions}
                                onChange={this.handleCurrentItemChange}
                                formatCreateLabel={val => val}
                                value={this.state.currentItemSelection}
                            />
                            <button disabled={!this.state.currentItemSelection || this.props.isSavingCalendarCurrentItem} onClick={e => this.props.setCurrentItem(e, null, null, this.state.currentItemSelection.label)} className="button" type="button">Set current item</button>
                        </div>
                    }
                </div>

                <br />
                <div className="collapsible-table">
                    {filteredCategories.map((category, catIndex) => {
                        if (category) {
                            return (<div key={catIndex}>
                                <div style={{ cursor: 'pointer' }}
                                    className={`${!hide[catIndex] ? 'opened' : ''} flex-row section-header`}
                                    onClick={() => this.toggleCategory(catIndex)}
                                    title={!this.props.currentAgendaItem && this.props.currentCategoryItem === category.CalendarCategoryID ? "Current Item" : ""}>
                                    <div>
                                        {!this.props.currentAgendaItem && this.props.currentCategoryItem === category.CalendarCategoryID &&
                                            <div style={{ display: 'inline-block' }}>
                                                <div className="arrow-right-text-filled">Current Item</div>
                                            </div>
                                        }
                                        {!this.props.isCalendarForm && canSetCurrentItem &&
                                            <button onClick={e => this.props.setCurrentItem(e, category.CalendarCategoryID)} style={{ marginRight: '5px' }} className="txt-dark-blue button-link">(c)</button>
                                        }
                                        <span>
                                            {categoryDescription(category)}
                                            {category.DisplayType && " - " + category.CategoryType}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="inline-list">
                                            {this.props.creatingCollection === catIndex ?
                                                <span className="small-spinner"></span>
                                                :
                                                <React.Fragment>
                                                    {this.props.userClaims && this.props.userClaims.resources.find(x => x === COMMUNICATION_AUTHOR) &&
                                                        <React.Fragment>
                                                            {this.props.calendarCategoryReferences && this.props.calendarCategoryReferences.find(x => x.CalendarCategoryTypeID === category.CalendarCategoryTypeID) && this.props.calendarCategoryReferences.find(x => x.CalendarCategoryTypeID === category.CalendarCategoryTypeID).ClerksCopy &&
                                                                <button type="button" onClick={(e) => this.generateClerksCopy(category, e)} className="button small-button">Clerk's Copy</button>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    {this.props.editHistory && this.props.userClaims.resources.find(resource => resource === BILL_MANAGEMENT_AUTHOR) && category.IsLegislationCategory && this.props.userClaims.resources.find(x => x === "LegislationEvent") &&
                                                        <React.Fragment>
                                                            <button disabled={this.props.creatingCollection !== null} type="button" onClick={(e) => this.props.editHistory(e, catIndex, categoryDescription(category) + (category.DisplayType ? ' - ' + category.CategoryType : ''))} className="button small-button">Edit History</button>
                                                        </React.Fragment>
                                                    }
                                                    {this.props.createWatchlist && this.props.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR) && category.IsLegislationCategory &&
                                                        <React.Fragment>
                                                            <button type="button" onClick={(e) => this.toggleReportMaker(catIndex, e)} className="button small-button">Create Report</button>
                                                            {this.props.createdCollections[catIndex] ?
                                                                <Link
                                                                    style={{ fontSize: '12px' }}
                                                                    to={{ pathname: "bill-search", search: `?collection=${this.props.createdCollections[catIndex].WatchListID}&q=${window.btoa(JSON.stringify({ selectedSession: calendar.SessionID, sessionOnly: true }))}` }}
                                                                    target="_blank">
                                                                    Open Watchlist
                                                                </Link>
                                                                :
                                                                <button disabled={this.props.creatingCollection !== null} type="button" onClick={(e) => this.props.createWatchlist(e, catIndex)} className="button small-button">Create Watchlist</button>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                            <button aria-expanded={!hide[catIndex]} aria-label={`${hide[catIndex] ? "Show" : "Hide"} items`} type="button" className="button-link">+/-</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-body">
                                    {!hide[catIndex] &&
                                        <React.Fragment>
                                            {category.Agendas.map((agenda, agendaIndex) =>
                                                <React.Fragment key={agendaIndex}>
                                                    {agenda.IsActive && !agenda.IsHidden &&
                                                        <React.Fragment>
                                                            <div title={this.props.currentAgendaItem === agenda.AgendaID ? "Current Item" : ""} className="section-row inner-grid med-two-one">
                                                                <div className='align-right'>
                                                                    {this.props.currentAgendaItem === agenda.AgendaID &&
                                                                        <div style={{ display: 'inline-block', float: 'left' }}>
                                                                            <div className="arrow-right-text-filled">Current Item</div>
                                                                        </div>
                                                                    }
                                                                    {!this.props.isCalendarForm && canSetCurrentItem && this.props.userClaims.resources.find(x => x === "LegislationEvent") &&
                                                                        <button onClick={e => this.props.setCurrentItem(e, category.CalendarCategoryID, agenda.AgendaID, agenda.Description)} style={{ marginRight: '5px' }} className="txt-dark-blue button-link">(c)</button>
                                                                    }
                                                                    {agenda.LegislationNumber && <Link target="_blank" to={`/bill-details/${session.SessionCode}/${agenda.LegislationNumber}`}>{agenda.LegislationNumber}</Link>}
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <span>{renderHTML(agenda.LegislationDescription || (agenda.Description === "Call to Order" && this.props.calendarTime ? "Call to Order - " + this.props.calendarTime : agenda.Description))}</span>
                                                                        {this.props.isCalendarForm &&
                                                                            <span className="inline-list">
                                                                                {agenda.AgendaItems && agenda.AgendaItems.length > 0 && !this.props.isCalendarForm &&
                                                                                    <button type="button" onClick={() => this.toggleAgendaItemEditor(agendaIndex, catIndex)} className="button-link">Edit Agenda Items</button>
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <span className='align-right'>
                                                                    {((agenda.AgendaItems && agenda.AgendaItems.length > 0) || agenda.LegislationNumber) &&
                                                                        <button aria-label="Show patrons, agenda descriptions, and versions" aria-expanded={!!agenda.show} type="button" onClick={() => this.props.toggleAgenda(catIndex, agendaIndex)} className={`${agenda.show ? "arrow-up" : "arrow-down"}`}></button>
                                                                    }
                                                                </span>
                                                            </div>
                                                            {agenda.show &&
                                                                <AgendaActivities
                                                                    agenda={agenda}
                                                                    sessionCode={session.SessionCode}
                                                                    chamberCode={agenda.LegislationNumber ? agenda.LegislationNumber[0] : calendar.ChamberCode}
                                                                    agendaNote={agenda.AgendaNote}
                                                                />
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    }
                                </div>
                            </div>)
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div >);
    }
}

export const AgendaActivities = props => {
    const { agenda, chamberCode, sessionCode, agendaNote } = props;
    const sortedPatrons = agenda.Patrons ? agenda.Patrons.sort((a, b) => { return chamberCode === b.ChamberCode ? 1 : -1 }) : [];
    const patrons = sortedPatrons.length > 0
        ? sortedPatrons.map((patron, patronIndex) => {
            let sameAsPrevious = (!sortedPatrons[patronIndex - 1] && patron.ChamberCode === chamberCode && !sortedPatrons.find(p => p.ChamberCode !== chamberCode)) || (sortedPatrons[patronIndex - 1] && patron.ChamberCode === sortedPatrons[patronIndex - 1].ChamberCode);
            let sameAsNext = sortedPatrons[patronIndex + 1] && patron.ChamberCode === sortedPatrons[patronIndex + 1].ChamberCode;
            let patronDisplayName = patron.PatronDisplayName;
            return (
                <React.Fragment key={patronIndex}>
                    {patron.ChamberCode !== chamberCode
                        ? patronIndex !== agenda.Patrons.length - 1
                            ? chamberCode === "S"
                                ? !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Delegates: " + patronDisplayName + ", " : sameAsNext ? patronDisplayName + ", " : patronDisplayName
                                : !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Senators: " + patronDisplayName + ", " : sameAsNext ? patronDisplayName + ", " : patronDisplayName
                            : chamberCode === "S"
                                ? !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Delegate: " + patronDisplayName : patronDisplayName
                                : !sameAsPrevious ? (patronIndex !== 0 ? "; " : "") + "Senator: " + patronDisplayName : patronDisplayName
                        : sameAsNext
                            ? patronDisplayName + ", " : patronDisplayName
                    }
                </React.Fragment>
            )
        })
        : ""

    return (
        <div className="inner-grid med-two-one">
            <br />
            <div>
                <div>
                    {patrons.length > 0 && <span>Patron(s): {patrons}</span>}
                </div>
                <div>
                    {agenda.AgendaItems.filter(a => a.IsActive).map((item, itemIndex) =>
                        <div key={itemIndex}>
                            {renderHTML(item.Description || "")}
                        </div>
                    )}
                </div>
                <p className="bold line-break">
                    {agendaNote}
                </p>
                {agenda.Versions &&
                    <>
                        {agenda.Versions.length > 0 ?
                            <>
                                {agenda.Versions === 'fail' ?
                                    <div className="small-text">Failed to get versions</div>
                                    :
                                    <VersionList userClaims={[]} sessionCode={sessionCode} version={agenda.Versions} />
                                }
                            </>
                            :
                            <div className="small-spinner"></div>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default connect(
    (state) => {
        const { votes, patrons } = state;
        return {
            votes,
            patrons
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, patronActionCreators), dispatch)
        }
    }
)(CalendarDetailsComponent);
