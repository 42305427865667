import React from 'react';
import Select from 'react-select';

const WATCHLIST_LEGISLATION = "WATCHLIST_LEGISLATION";
const ALL_LEGISLATION = "ALL_LEGISLATION";

class WatchlistNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideNotifications: true,
            newKeywords: [],
            showTooltip: false
        };
        this.toggleNotifications = this.toggleNotifications.bind(this);
        this.handleFrequenyChange = this.handleFrequenyChange.bind(this);
        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handleCommitteeChange = this.handleCommitteeChange.bind(this);
        this.toggleEditKeywords = this.toggleEditKeywords.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleKeywordSave = this.handleKeywordSave.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleNotifications() {
        this.setState(state => ({
            hideNotifications: !state.hideNotifications
        }));
    }

    toggleTooltip(state) {
        let showTooltip = { ...this.state.showTooltip };

        //Close all open tooltips
        if (typeof showTooltip === "object") {
            for (const x in showTooltip) {
                if (x !== state)
                    showTooltip[x] = false;
            };
        }

        showTooltip[state] = showTooltip[state] ? false : true;

        this.setState({
            showTooltip: showTooltip
        })
    }

    handleFrequenyChange(e) {
        const value = e.target.value;
        let selectedCollection = { ...this.props.selectedCollection };
        selectedCollection.Frequency = value;
        delete selectedCollection.NotificationFrequencyID
        this.props.handleCollectionChange(selectedCollection);
    }

    handleMemberChange(val) {
        let selectedCollection = { ...this.props.selectedCollection };
        const criteriaTypeObj = this.props.criteriaTypes.find(type => type.CriteriaType === 'Patron');
        let newCriteria = [];
        selectedCollection.NotificationCriterias.forEach(criteria => {
            if (criteria.CriteriaType !== 'Patron') {
                newCriteria.push(criteria);
            } else {
                const indexOfCriteriaInValue = val.findIndex(v => parseInt(criteria.CriteriaValue) === v.MemberID);
                if (indexOfCriteriaInValue !== -1) {
                    // This criteria is in value. Add it to the new criteria array and make sure it is active
                    newCriteria.push({ ...criteria, IsActive: true });
                    // Remove the value from the val array since it is already added
                    val.splice(indexOfCriteriaInValue, 1);
                } else {
                    // This criteria is not part of val so make it inactive
                    newCriteria.push({ ...criteria, IsActive: false });
                }
            }
        });
        // Anything left in val will be new criterias. Add them to the new criteria array
        val.forEach(v => {
            newCriteria.push({
                CriteriaValue: v.MemberID,
                CriteriaType: "Patron",
                CriteriaTypeID: criteriaTypeObj ? criteriaTypeObj.CriteriaTypeID : null,
                IsActive: true
            });
        });
        selectedCollection.NotificationCriterias = newCriteria;
        this.props.handleCollectionChange(selectedCollection);
    }

    handleCommitteeChange(val) {
        let selectedCollection = { ...this.props.selectedCollection };
        const criteriaTypeObj = this.props.criteriaTypes.find(type => type.CriteriaType === 'Committee');
        let newCriteria = [];
        selectedCollection.NotificationCriterias.forEach(criteria => {
            if (criteria.CriteriaType !== 'Committee') {
                newCriteria.push(criteria);
            } else {
                const indexOfCriteriaInValue = val.findIndex(v => parseInt(criteria.CriteriaValue) === v.CommitteeID);
                if (indexOfCriteriaInValue !== -1) {
                    // This criteria is in value. Add it to the new criteria array and make sure it is active
                    newCriteria.push({ ...criteria, IsActive: true });
                    // Remove the value from the val array since it is already added
                    val.splice(indexOfCriteriaInValue, 1);
                } else {
                    // This criteria is not part of val so make it inactive
                    newCriteria.push({ ...criteria, IsActive: false });
                }
            }
        });
        // Anything left in val will be new criterias. Add them to the new criteria array
        val.forEach(v => {
            newCriteria.push({
                CriteriaValue: v.CommitteeID,
                CriteriaType: "Committee",
                CriteriaTypeID: criteriaTypeObj ? criteriaTypeObj.CriteriaTypeID : null,
                IsActive: true
            });
        });
        selectedCollection.NotificationCriterias = newCriteria;
        this.props.handleCollectionChange(selectedCollection);
    }

    toggleEditKeywords() {
        let keywordCriteria = this.props.selectedCollection.NotificationCriterias.filter(criteria => criteria.IsActive && criteria.CriteriaType === 'Keyword');
        // Make sure there are at least one keyword so the input appears
        if (keywordCriteria.length === 0) {
            const criteriaTypeObj = this.props.criteriaTypes.find(type => type.CriteriaType === 'Keyword')
            keywordCriteria.push({
                CriteriaType: 'Keyword',
                CriteriaTypeID: criteriaTypeObj ? criteriaTypeObj.CriteriaTypeID : null,
                CriteriaValue: '',
                IsActive: true
            });
        }
        this.setState(state => ({
            newKeywords: keywordCriteria,
            showKeywordEditor: !state.showKeywordEditor
        }));
    }

    handleKeywordChange(e, index) {
        const val = e.target.value;
        let newKeywords = [...this.state.newKeywords];
        newKeywords[index].CriteriaValue = val
        this.setState({
            keywordCriteria: newKeywords
        })
    }

    handleKeywordSave(isDelete) {
        let selectedCollection = { ...this.props.selectedCollection };
        let newKeywords = [...this.state.newKeywords];
        selectedCollection.NotificationCriterias.forEach(criteria => {
            if (criteria.CriteriaType === "Keyword") {
                const criteriaToUseIndex = newKeywords.findIndex(kc => kc.CriteriaTypeID === criteria.CriteriaTypeID)
                if (criteriaToUseIndex !== -1) {
                    criteria.IsActive = true;
                    criteria.CriteriaValue = newKeywords[criteriaToUseIndex].CriteriaValue;
                    newKeywords.splice(criteriaToUseIndex, 1);
                }
                if (isDelete || !criteria.CriteriaValue || !criteria.CriteriaValue.trim()) {
                    criteria.IsActive = false;
                }
            }
        });
        if (newKeywords.filter(nk => nk.IsActive).length > 0) {
            // If there are some left over then these don't have IDs and need to be created
            selectedCollection.NotificationCriterias = selectedCollection.NotificationCriterias.concat(newKeywords.filter(nk => nk.IsActive));
        }
        this.props.handleCollectionChange(selectedCollection, () => {
            this.setState({
                showKeywordEditor: false
            })
        });

    }

    handleCheckboxChange(criteriaType) {
        let selectedCollection = { ...this.props.selectedCollection };
        let criteriaIndices = [];
        // Since notificaion criteria is an array the entire array needs to be checked to see if any of the criteria type exist
        this.props.selectedCollection.NotificationCriterias.forEach((criteria, criteriaIndex) => {
            if (criteria.CriteriaType === criteriaType) {
                criteriaIndices.push(criteriaIndex);
                // If it exists then it needs to be 'checked' or 'unchecked' by setting isActive
                selectedCollection.NotificationCriterias[criteriaIndex].IsActive = !selectedCollection.NotificationCriterias[criteriaIndex].IsActive;
            }
        });
        //If none were found then add one to make the box checked
        if (criteriaIndices.length === 0) {
            const criteriaTypeObj = this.props.criteriaTypes.find(type => type.CriteriaType === criteriaType)
            selectedCollection.NotificationCriterias.push({
                CriteriaType: criteriaType,
                CriteriaTypeID: criteriaTypeObj ? criteriaTypeObj.CriteriaTypeID : null,
                IsActive: true
            });
        }
        this.props.handleCollectionChange(selectedCollection);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedCollection.WatchListID !== prevProps.selectedCollection.WatchListID) {
            this.setState({
                newKeywords: []
            })
        }
    }

    render() {
        const { selectedCollection, memberList, committeeList, collectionIsSaving } = this.props;
        const { hideNotifications, newKeywords, showKeywordEditor } = this.state;

        if (!selectedCollection) {
            return false;
        }

        const houseMembers = memberList.filter(member => member.ChamberCode === "H");
        const senateMembers = memberList.filter(member => member.ChamberCode === "S");
        const houseCommittees = committeeList.filter(committee => committee.ChamberCode === "H");
        const senateCommittees = committeeList.filter(committee => committee.ChamberCode === "S");

        const selectedHousePatrons = houseMembers.filter(member => selectedCollection.NotificationCriterias.find(criteria => criteria.IsActive && criteria.CriteriaType === "Patron" && parseInt(criteria.CriteriaValue) === member.MemberID))
        const selectedSenatePatrons = senateMembers.filter(member => selectedCollection.NotificationCriterias.find(criteria => criteria.IsActive && criteria.CriteriaType === "Patron" && parseInt(criteria.CriteriaValue) === member.MemberID))
        const selectedHouseCommittees = houseCommittees.flatMap(x => x.options || x).filter(committee => selectedCollection.NotificationCriterias.find(criteria => criteria.IsActive && criteria.CriteriaType === "Committee" && parseInt(criteria.CriteriaValue) === committee.CommitteeID))
        const selectedSenateCommittees = senateCommittees.flatMap(x => x.options || x).filter(committee => selectedCollection.NotificationCriterias.find(criteria => criteria.IsActive && criteria.CriteriaType === "Committee" && parseInt(criteria.CriteriaValue) === committee.CommitteeID))

        const getSelectedCommitteesLabel = (val) => {
            let newCmte = JSON.parse(JSON.stringify(val)); //don't modify existing committee values stored at state level & used in dropdown
            newCmte.forEach(cmte => {
                cmte.label = '(' + cmte.ChamberCode + ') ' + cmte.label;
            });
            return newCmte;
        }

        let statusChangeCheck = false;
        let docketCheck = false;
        let versionCheck = false;
        let impactStatementCheck = false;
        let keywords = [];
        let introducedCheck = false;
        selectedCollection.NotificationCriterias.forEach(criteria => {
            if (!criteria.IsActive) {
                return;
            }
            switch (criteria.CriteriaType) {
                case ("Status Change"):
                    statusChangeCheck = true;
                    break;
                case ("Docket/Agenda"):
                    docketCheck = true;
                    break;
                case ("New Version"):
                    versionCheck = true;
                    break;
                case ("Impact Statement"):
                    impactStatementCheck = true;
                    break;
                case ("Keyword"):
                    keywords.push(criteria);
                    break;
                case ("Introduced"):
                    introducedCheck = true;
                    break;
            }
        });
        return (
            <div className="collection-notification-box dlas-forms user-forms">
                <div className="flex-row notification-first-section">
                    <span className="txt-lighter-blue">Notifications</span>
                    <button aria-expanded={!hideNotifications} onClick={this.toggleNotifications} className={`${hideNotifications ? "arrow-down" : "arrow-up"} light-blue-chevron`}></button>
                </div>
                {!hideNotifications &&
                    <div>
                        <div className="notification-section flex-row flex-start inline-list flex-vertical-center">
                            <div>Notification frequency</div>
                            <div className="flex-vertical-center flex-row">
                                <label htmlFor="sr-frequency-hourly">Hourly</label>
                                <input id="sr-frequency-hourly" disabled={collectionIsSaving} value="Hourly" onChange={this.handleFrequenyChange} checked={selectedCollection.Frequency === "Hourly"} name="notifcation-frequency" type="radio" />
                            </div>
                            <div className="flex-vertical-center flex-row">
                                <label htmlFor="sr-frequency-daily">Daily</label>
                                <input id="sr-frequency-daily" disabled={collectionIsSaving} value="Daily" onChange={this.handleFrequenyChange} checked={selectedCollection.Frequency === "Daily"} name="notifcation-frequency" type="radio" />
                            </div>
                            <div className="flex-vertical-center flex-row">
                                <label htmlFor="sr-frequency-none">None</label>
                                <input id="sr-frequency-none" disabled={collectionIsSaving} value="None" onChange={this.handleFrequenyChange} checked={selectedCollection.Frequency === "None" || !selectedCollection.Frequency} name="notifcation-frequency" type="radio" />
                            </div>
                        </div>
                        <hr className="faded-line full-width" />
                        <div className="no-gap-grid two">
                            <div className="notification-section">
                                <strong className="txt-lighter-blue">My Watchlist Legislation</strong>
                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip(WATCHLIST_LEGISLATION) }} className="button info">Info</a>
                                {this.state.showTooltip[WATCHLIST_LEGISLATION] &&
                                    <div className="info-box notification-tooltip">
                                        <p><b>Status Change</b>{": Legislation receives one or more notable events that trigger a status change. Ex: In Committee, Passed, Failed, etc. -  this DOES NOT include notice of when a bill you are tracking is placed on a docket or agenda, has a new text version or has a new impact statement. - Use those options below to be notified for each."}</p>
                                        <p><b>Placed on Docket/Agenda</b>{": Legislation is placed on a Senate committee docket or House committee agenda."}</p>
                                        <p><b>New text versions</b>{": Legislation receives a new version such as Substitute, Engrossed, Enrolled, etc. *excludes amendments and Governor's text."}</p>
                                        <p><b>Impact Statements</b>{": Legislation receives a new impact statement."}</p>
                                    </div>
                                }
                                <div className="flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleCheckboxChange("Status Change")}>
                                        <input id="sr-status-change" disabled={collectionIsSaving} checked={statusChangeCheck} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="sr-status-change" className="checkbox-label" >Notify me when a bill in this watchlist has any status change.</label>
                                </div>
                                <div className="flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleCheckboxChange("Docket/Agenda")}>
                                        <input id="sr-docket" disabled={collectionIsSaving} checked={docketCheck} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="sr-docket" className="checkbox-label">Notify me when a bill in this watchlist appears on a docket/agenda.</label>
                                </div>
                                <div className="flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleCheckboxChange("New Version")}>
                                        <input id="sr-new-version" disabled={collectionIsSaving} checked={versionCheck} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="sr-new-version" className="checkbox-label">Notify me when a bill in this watchlist has new text versions.</label>
                                </div>

                                <div className="flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleCheckboxChange("Impact Statement")}>
                                        <input id="sr-impact-statement" disabled={collectionIsSaving} checked={impactStatementCheck} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="sr-impact-statement" className="checkbox-label">Notify me when a bill in this watchlist has impact statements.</label>
                                </div>
                            </div>
                            <div className="notification-section">
                                <strong className="txt-lighter-blue">All Legislation</strong>
                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip(ALL_LEGISLATION) }} className="button info">Info</a>
                                {this.state.showTooltip[ALL_LEGISLATION] &&
                                    <div className="info-box notification-tooltip all-legislation">
                                        <p><b>Any new bill introduced</b>{": All bills, joints, and resolutions introduced by House or Senate."}</p>
                                        <p><b>Any bill offered by patron</b>{": Legislation introduced by a particular patron as Chief Patron."}</p>
                                        <p><b>Any bill with keyword phrase</b>{": Legislation contains a particular keyword or keyword phrase in any version text available."}</p>
                                        <p><b>Any bill referred to a committee</b>{": Legislation referred, rereferred, and/or recommitted to a particular committee."}</p>
                                    </div>
                                }
                                <div className="flex-row flex-vertical-center flex-start">
                                    <div className="toggle-switch" onClick={() => this.handleCheckboxChange("Introduced")}>
                                        <input id="sr-introduced" disabled={collectionIsSaving} checked={introducedCheck} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="sr-introduced" className="checkbox-label">Notify me when <strong>any</strong> new bill is introduced.</label>
                                </div>
                                <br />
                                <div>
                                    <label>Notify me when <strong>any</strong> new bill is offered by a patron.</label>
                                    <div className="inner-grid" style={{ gap: '5px' }}>
                                        <Select
                                            aria-label="House Members"
                                            id="sr-patron-house"
                                            isDisabled={collectionIsSaving}
                                            placeholder="House Members"
                                            options={houseMembers}
                                            value={selectedHousePatrons}
                                            getOptionLabel={opt => opt.ListDisplayName}
                                            //The senate patrons needs to be concatenated because the list is split in two to accomadate the design of two seperate dropdowns
                                            onChange={val => this.handleMemberChange((val || []).concat(selectedSenatePatrons))}
                                            styles={selectStyle}
                                            isMulti />
                                        <Select
                                            aria-label="Senate Members"
                                            id="sr-patron-senate"
                                            isDisabled={collectionIsSaving}
                                            placeholder="Senate Members"
                                            options={senateMembers}
                                            value={selectedSenatePatrons}
                                            onChange={val => this.handleMemberChange((val || []).concat(selectedHousePatrons))}
                                            styles={selectStyle}
                                            getOptionLabel={opt => opt.ListDisplayName}
                                            isMulti />
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <label id="sr-keyword-label">Notify me when <strong>any</strong> new introduced, engrossed, enrolled, chaptered, or substitute legislation is offered with a keyword phrase.</label>
                                    {showKeywordEditor ?
                                        <div className="flex-row flex-vertical-center">
                                            {newKeywords.map((keyword, keywordIndex) =>
                                                <input
                                                    aria-label="Enter phrase"
                                                    key={keywordIndex}
                                                    disabled={collectionIsSaving}
                                                    type="text"
                                                    value={keyword.CriteriaValue}
                                                    onChange={e => this.handleKeywordChange(e, keywordIndex)}
                                                    placeholder="Enter phrase" />
                                            )}
                                            <div>
                                                {collectionIsSaving ?
                                                    <div className="small-spinner"></div>
                                                    :
                                                    <div className="inline-list">
                                                        <span role="button" aria-label="save" onClick={() => this.handleKeywordSave(false)} className="icon save"></span>
                                                        <span role="button" aria-label="cancel" onClick={this.toggleEditKeywords} className="icon delete"></span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {keywords.map((keyword, keywordIndex) =>
                                                <div className="bold" key={keywordIndex}>
                                                    <span>{keyword.CriteriaValue}</span>
                                                    <span role="button" aria-label="delete" onClick={() => this.handleKeywordSave(true)} style={{ marginLeft: '5px', verticalAlign: 'text-bottom' }} className="icon delete"></span>
                                                </div>
                                            )}
                                            <button onClick={() => !this.props.collectionIsSaving && this.toggleEditKeywords()} className="button-link" type="button">Edit keywords</button>
                                        </div>
                                    }
                                </div>
                                <br />
                                <div>
                                    <label>Notify me when <strong>any</strong> bill is referred to a committee.</label>
                                    <div className="inner-grid" style={{ gap: '5px' }}>
                                        <Select
                                            aria-label="House Committees"
                                            isDisabled={collectionIsSaving}
                                            placeholder="House Committees"
                                            options={houseCommittees}
                                            value={getSelectedCommitteesLabel(selectedHouseCommittees)}
                                            onChange={val => this.handleCommitteeChange((val || []).concat(selectedSenateCommittees))}
                                            styles={selectStyle}
                                            isMulti />
                                        <Select
                                            aria-label="Senate Committees"
                                            isDisabled={collectionIsSaving}
                                            placeholder="Senate Committees"
                                            options={senateCommittees}
                                            value={getSelectedCommitteesLabel(selectedSenateCommittees)}
                                            onChange={val => this.handleCommitteeChange((val || []).concat(selectedHouseCommittees))}
                                            styles={selectStyle}
                                            isMulti />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>);
    }

}

const selectStyle = {
    menuList: (base, state) => ({
        ...base,
        paddingTop: '0px',
    }),
    group: (base, state) => ({
        ...base,
        paddingTop: '0px',
    }),
    groupHeading: (base, state) => ({
        ...base,
        paddingTop: '7px',
        paddingBottom: '7px',
        marginBottom: '0px',
        position: 'sticky',
        top: '0px',
        backgroundColor: 'hsl(0,0%,100%)'
    }),
    control: styles => ({
        ...styles,
        minHeight: '0px',
        padding: '0.12em 0.6em !important',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: 0,
    }),
    input: styles => ({
        ...styles,
        fontSize: '0.8em',
        lineHeight: 'normal',
    }),
    singleValue: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    option: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: '1px'
    }),
}

export default WatchlistNotifications;